import React from "react";

function Tax() {
  return (
    <div className="container mx-auto py-10 px-4 md:px-8">
      <div className="bg-[#F0F0F0] mb-20 shadow-2xl py-4 md:py-16 rounded-2xl">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-2xl md:text-3xl text-center text-[#3D498A] font-bold mb-4 px-1">
            CORPORATE TAX AND TAXATION IN FREEZONE
          </h1>
        </div>
        <p className="text-base md:text-lg text-justify md:text-center px-4 md:px-4">
          Freezones offer tax exemptions on corporate and personal income for a
          set duration. While corporate tax has been introduced in the UAE,
          freezone companies can remain exempt if they adhere to specific
          guidelines, such as conducting activities solely within the freezone
          or internationally. Additionally, freezone businesses benefit from
          exemptions on port taxes and customs duties.
        </p>
      </div>
    </div>
  );
}

export default Tax;
