import React from 'react';
import { Check } from 'lucide-react';

const GoldenVisaBenefits = () => {
  const benefits = [
    "10-year renewable residence visa",
    "No sponsor or employer required",
    "Sponsor family members",
    "Ability to stay outside the UAE for more than six months without losing residency",
    "Apply for an unlimited number of visas/work permits for domestic employees"
  ];

  return (
    <div className="min-h-[40vh] mt-11 mb-10">
      <div className="max-w-7xl mx-auto pt-12 px-4 md:px-8">
        <div className="flex flex-col md:flex-row items-center justify-between gap-12">
          {/* Left side - Benefits */}
          <div className="w-full md:w-1/2 space-y-8" data-aos="fade-up">
            <h1 className="text-2xl md:text-3xl font-bold text-gray-900">
              Golden Visa UAE Benefits
            </h1>

            <div className="space-y-4">
              {benefits.map((benefit, index) => (
                <div key={index} className="flex items-start gap-3">
                  <div className="mt-1">
                    <Check className="h-5 w-5 text-[#3D498A]" />
                  </div>
                  <span className="text-gray-700">{benefit}</span>
                </div>
              ))}
            </div>
            <a href="/contact">
              <button className="bg-[#3D498A] text-white px-8 py-3 rounded-md hover:bg-[#3D498A] transition-colors">
                Get Your Golden Visa in 3 Days
              </button>
            </a>
          </div>

          {/* Right side - Image */}
          <div className="w-full md:w-1/2 h-full flex justify-center">
            <img
              src='https://rightone.ae/Assets/goldenVisa/Benefits.jpeg'
              alt="Golden Visa Card"
              className="w-full h-auto rounded-2xl"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoldenVisaBenefits;