import React from "react";

const RightOnePage = () => {
  return (
    <div
      className="relative h-[60vh] sm:h-[70vh] md:h-[80vh] lg:h-[90vh] w-full"
      style={{
        backgroundImage: `url(${`https://rightone.ae/Assets/blog/blog1.png`})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="grid place-items-center h-full">
        <h1 className="text-white bg-[#3D498A] px-4 sm:px-8 md:px-12 lg:px-16 py-4 sm:py-6 md:py-7 lg:py-8 rounded-xl font-poppins text-2xl sm:text-3xl md:text-4xl lg:text-6xl font-bold leading-normal sm:leading-relaxed md:leading-relaxed lg:leading-[67px] uppercase text-center">
          our blogs
        </h1>
      </div>
    </div>
  );
};

export default RightOnePage;