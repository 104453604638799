import React, { useState } from "react";

const Faqs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqItems = [
    {
      question: "What is the salary for Golden Visa application?",
      answer:
        "The Golden Visa provides long-term residency for 10 years to individuals meeting specific criteria: those earning a monthly salary exceeding AED 30,000, making investments of AED 2 million or more, or owning off-plan properties valued at AED 2 million and above.",
    },
    {
      question: "How do I check the status of my Golden Visa application?",
      answer:
        "Here’s a simple guide to check the status of your Golden Visa application: Step 1: Go to the website https://u.ae/en/information-and-services/visa-and-emirates-id/track-visa-application-and-validity.Step 2: Click on ‘Track Your Application Status’.Step 3: Enter your ‘Request Number’..",
    },
    {
      question: "Who is eligible for a UAE Golden Visa?",
      answer:
        "People eligible for the UAE Golden Visa include foreign investors, entrepreneurs, and exceptionally talented individuals who meet specific criteria. To qualify, one must invest a minimum of AED 10 million in a business, buy property valued at least AED 5 million, or secure a job contract with a guaranteed monthly salary of at least AED 30,000.",
    },
    {
      question: "How to Get UAE Golden Visa?",
      answer:
        "If you’re seeking Golden Visa services in Dubai, our consultants are here to streamline the process, making it easier for you to obtain the UAE Golden Visa at your convenience. Schedule a consultation with us at +971 566 556645.",
    },
    {
      question: "How Does the UAE Golden Visa Offer ?",
      answer:
        "The UAE Golden Visa targets individuals with high net worth or exceptional talents and skills, aiming to enrich the UAE economy. In exchange, it provides long-term residency and a secure future in one of the world’s most developed nations. This visa is tailored for investors, researchers, highly skilled professionals, and talented students, as the government seeks to retain these valuable contributors in the country. UAE Golden Visa holders enjoy significant benefits and opportunities for permanent residency in the UAE.",
    },
    {
      question: "What is the age limit for Golden Visa in UAE?",
      answer:
        "There are no age limitations for the Golden Visa in the UAE. Applicants can apply for any category of the Golden Visa provided they fulfil the required eligibility criteria.",
    },
    {
      question: "What is the benefit of Golden Visa in UAE?",
      answer:
        "The UAE’s Golden Visa offers notable advantages, granting foreign talents the chance to live, work, or study in the UAE while enjoying exclusive benefits. These perks include a six-month entry visa with multiple entries for residency, along with the option of obtaining a renewable residence visa valid for 5 or 10 years.",
    },
    {
      question: "How much does UAE Golden Visa cost?",
      answer:
        "The price of a UAE Golden Visa varies depending on the category of visa you are applying for. The cost of a new 10-year Golden Visa in 2024 is 2,657 AED, not including fees for a medical check (700 AED) and Emirates ID (1,153 AED). If your application is rejected, 60% (1,680 AED) will be refunded. Also, consider the cost of UAE health insurance, with minimum basic coverage costing about 800 AED, DLD fees of 4,020 AED, and administrative fees of 1,155 AED. Additionally our charges for Golden Visa is based on the type of application. The prices starts from AED 3000 (normal package) up to AED 10,000 (Fast track VIP clients).",
    },
    {
      question: "Can i sponsor my parents with  UAE Golden Visa?",
      answer:
        "Yes, if you have a Golden Visa in the UAE, you can now sponsor your parents to live in the country for up to 10 years. This change was made by the UAE Government to make the Golden Visa program more accessible.",
    },
    {
      question: "What is the UAE's Golden Visa?",
      answer:
        "Yes, if you have a Golden Visa in the UAE, you can now sponsor your parents to live in the country for up to 10 years. This change was made by the UAE Government to make the Golden Visa program more accessible.",
    },
    {
      question: "What is the Golden visa approval time?",
      answer:
        "The approval time for the Golden Visa in the UAE can vary depending on several factors, including the type of visa applied for, the completeness of the application, and the workload of the immigration authorities. Generally, it can take anywhere from a 3 days to several months to receive approval for a Golden Visa. It’s advisable to check with the relevant authorities or consult with Brightlink Golden Visa Team for the most accurate estimation of the approval time based on your specific circumstances.With Brightlink Management Consultancy you can get Golden Visa approval in just 3 days. Schedule a call now.",
    },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="sectionlayout z-10 ">
      <div className=" pb-8 mt-10">
        <h1
          className="font-bold mb-10 text-center"
          style={{ fontSize: "clamp(32px,2.5vw,50px)" }}
          data-aos="fade-up"
        >
          Golden Visa UAE: Frequently Asked Questions
        </h1>

        <div className="space-y-4">
          <div
            className="border rounded-lg bg-white overflow-hidden transition-all duration-300"
            data-aos="fade-up"
          >
            <div
              className={`flex justify-between hover:bg-[#3D498A] hover:text-white transition-all ease-in-out duration-300 items-center p-4 cursor-pointer ${
                activeIndex === 0
                  ? "text-white bg-[#3D498A] font-semibold"
                  : "text-[#3D498A] bg-white"
              }`}
              onClick={() => toggleFAQ(0)}
            >
              <span className="">How to Apply for Golden Visa UAE?</span>
              <span className="text-3xl">{activeIndex === 0 ? "-" : "+"}</span>
            </div>
            <div
              className={`transition-all duration-300 ease-in-out ${
                activeIndex === 0 ? "max-h-fit p-4" : "max-h-0"
              }`}
            >
              <p>To apply for a UAE Golden Visa, follow these simple steps:</p>
              <h2 className="font-bold py-2 text-[20px]">
                Step 1: Check Your Eligibility First, determine if you qualify
                for a Golden Visa.
              </h2>

              <p className="text-gray-600">
                The UAE offers various categories for these long-term visas, so
                find out which one fits your situation best. Abu Dhabi Residents
                Office (ADRO) provides an online tool with 17 questions to help
                assess your eligibility. Even if you plan to apply from another
                Emirate, this tool can give you a good starting point.
              </p>
              <h2 className="font-bold py-2 text-[20px]">
                Step 2: Prepare Your Documents and Recommendations
              </h2>
              <p className="text-gray-600">
                Once you know the Golden Visa category you’re eligible for,
                gather the required documents. Some categories may need
                recommendations from relevant government departments. For
                example, ‘creative professionals’ may require a recommendation
                from the Ministry of Culture and Youth or Dubai Culture if
                applying in Dubai. Contact the Amer service in Dubai at 800 5111
                to get a list of necessary documents for your category. Amer
                centers handle visa applications for the General Directorate of
                Residency and Foreigners Affairs in Dubai.
              </p>
              <h2 className="font-bold py-2 text-[20px]">
                Step 3: Initiate Your Application
              </h2>
              <p className="text-gray-600">
                After confirming your eligibility, start the application
                process. Apply online through the GDRFA website for Dubai’s
                Golden Visa application service. Log in with your GDRFA account
                or UAE Pass, select your Golden Visa category, and fill out the
                application form. Attach required documents, including passport
                and Emirates ID copies, and make the necessary payment.
                Alternatively, visit an Amer center in Dubai or an ICP customer
                happiness center to apply in person.
              </p>
              <h2 className="font-bold py-2 text-[20px]">
                Step 4: Complete a Medical Fitness Test
              </h2>
              <p className="text-gray-600">
                Once approved by the immigration authority, undergo a medical
                fitness test.
              </p>
              <h2 className="font-bold py-2 text-[20px]">
                {" "}
                Step 5: Apply for Your Emirates ID
              </h2>
              <p className="text-gray-600">
                Apply for your Emirates ID by visiting an ICP customer happiness
                center. Use this link to find a center near you: ICP Customer
                Happiness Centers.
              </p>
            </div>
          </div>

          <div
            className="border rounded-lg bg-white overflow-hidden transition-all duration-300"
            data-aos="fade-up"
          >
            <div
              className={`flex justify-between hover:bg-[#3D498A] hover:text-white transition-all ease-in-out duration-300 items-center p-4 cursor-pointer ${
                activeIndex === 1
                  ? "text-white bg-[#3D498A] font-semibold"
                  : "text-[#3D498A] bg-white"
              }`}
              onClick={() => toggleFAQ(1)}
            >
              <span className="">What are the Documents you need to apply for a Golden Visa?</span>
              <span className="text-3xl">{activeIndex === 1 ? "-" : "+"}</span>
            </div>
            <div
              className={`transition-all duration-300 ease-in-out ${
                activeIndex === 1 ? "max-h-fit p-4" : "max-h-0"
              }`}
            >
              <p className="">To apply for a Golden Visa, you’ll need several documents:</p>
              <h2 className="font-bold py-2 text-[20px]">
                1.Personal Identification Documents:
              </h2>

              <p className="text-gray-600">
                <ul className="list-disc
                pl-4">
                  {" "}
                  <li>A copy of your passport, visa, and Emirates ID.</li>
                  <li> A passport-sized photograph. </li>
                </ul>
              </p>
              <h2 className="font-bold py-2 text-[20px]">Residence Proof:</h2>
              <ul className="text-gray-600 list-disc
                pl-4">
                <li>
                  Your tenancy contract, showing your place of residence in the
                  UAE.
                </li>
              </ul>
              <h2 className="font-bold py-2 text-[20px]">Bank Information:</h2>
              <ul className="text-gray-600 list-disc
                pl-4">
                <li>
                  Your IBAN number, which is your bank account information.
                </li>
              </ul>
              <h2 className="font-bold py-2 text-[20px]">
                Health and Medical Documents:
              </h2>
              <ul className="text-gray-600 list-disc
                pl-4">
                <li>
                  Your medical insurance card.
                  <li> A medical fitness certificate. </li>
                </li>
              </ul>
              <h2 className="font-bold py-2 text-[20px]">
                {" "}
                Category-Specific Documents:
              </h2>
              <p className="text-gray-600">
                Depending on the category you’re applying for, you may need
                additional documents like a labor card and contract,
                recommendation letters from relevant government departments, and
                educational qualifications. These requirements vary based on the
                specific Golden Visa category you’re pursuing.
              </p>
            </div>
          </div>

          {faqItems.map((item, index) => (
            <div
              key={index}
              className="border rounded-lg bg-white overflow-hidden transition-all duration-300"
              data-aos="fade-up"
              data-aos-delay={`${index * 10}`}
            >
              <div
                className={`flex justify-between hover:bg-[#3D498A] hover:text-white transition-all ease-in-out duration-300 items-center p-4 cursor-pointer ${
                  activeIndex === index + 2
                    ? "text-white bg-[#3D498A] font-semibold"
                    : "text-[#3D498A] bg-white"
                }`}
                onClick={() => toggleFAQ(index + 2)}
              >
                <span className="">{item.question}</span>
                <span className="text-3xl">
                  {activeIndex === index + 2 ? "-" : "+"}
                </span>
              </div>
              <div
                className={`transition-all duration-300 ease-in-out ${
                  activeIndex === index + 2 ? "max-h-fit p-4" : "max-h-0"
                }`}
              >
                <p className="text-gray-600">{item.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faqs;
