import React from "react";
import { FaCompass, FaAward, FaLightbulb, FaShieldAlt, FaHandshake } from "react-icons/fa";

const QualitiesSection = () => {
  const qualities = [
    {
      icon: FaCompass,
      title: "Integrity",
      description: "Upholding ethical practices in every aspect of our work.",
    },
    {
      icon: FaAward,
      title: "Professionalism",
      description: "Delivering excellence through a skilled and experienced team.",
    },
    {
      icon: FaLightbulb,
      title: "Innovation",
      description: "Offering fresh perspectives and customized solutions.",
    },
    {
      icon: FaShieldAlt,
      title: "Reliability",
      description: "Being a dependable partner for our clients every step of the way.",
    },
    {
      icon: FaHandshake,
      title: "Client Focus",
      description: "Prioritizing client needs to build long-lasting relationships.",
    },
  ];

  return (
    <section className="py-20 px-4 bg-[#3D498A] container mx-auto">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-5xl font-bold text-center mb-12 text-white">
          Our Qualities
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {qualities.slice(0, 3).map((quality, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-xl border border-gray-300 hover:transform hover:scale-105 transition-all duration-300"
            >
              <div className="flex justify-center">
                <quality.icon className="w-14 h-14 text-[#3D498A] mb-4" />
              </div>
              <h3 className="text-xl font-semibold text-center mb-2 text-[#3D498A]">
                {quality.title}
              </h3>
              <p className="text-gray-600 text-center">{quality.description}</p>
            </div>
          ))}
        </div>
        <div className="grid grid-cols-2 gap-6 mt-6">
          {qualities.slice(3).map((quality, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-xl border border-gray-300 hover:transform hover:scale-105 transition-all duration-300"
            >
              <div className="flex justify-center">
                <quality.icon className="w-14 h-14 text-[#3D498A] mb-4" />
              </div>
              <h3 className="text-xl font-semibold text-center mb-2 text-blue-900">
                {quality.title}
              </h3>
              <p className="text-gray-600 text-center">{quality.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default QualitiesSection;