import React from "react";
import { Link } from "react-router-dom";
import "aos/dist/aos.css";
import AOS from "aos";

AOS.init();

const services = [
  {
    title: "VAT Services",
    icon: `${process.env.PUBLIC_URL}/Assets/BookkeepingCorporateService/icon1.png`,
    img: `${process.env.PUBLIC_URL}/Assets/BookkeepingCorporateService/vat.jpeg`,
    items: [
      "VAT registration for businesses",
      "VAT de-registration services",
      "Filing VAT returns accurately and on time",
      "Assisting with VAT compliance and audits",
      "VAT penalty waivers and dispute resolution",
    ],
  },
  {
    title: "Corporate Tax Services",
    icon: `${process.env.PUBLIC_URL}/Assets/BookkeepingCorporateService/icon2.png`,
    img: `${process.env.PUBLIC_URL}/Assets/BookkeepingCorporateService/corporatetaxservice.jpeg`,
    items: [
      "Corporate tax registration for companies",
      "Corporate tax return filing & compliance",
      "Advisory services for corporate tax planning",
      "Assisting with VAT compliance & audits",
      "Advisory services for corporate tax planning",
    ],
  },
  {
    title: "Accounting Services",
    icon: `${process.env.PUBLIC_URL}/Assets/BookkeepingCorporateService/icon3.png`,
    img: `${process.env.PUBLIC_URL}/Assets/BookkeepingCorporateService/accountingservice.jpeg`,
    items: [
      "Preparation and maintenance of financial statements",
      "General ledger accounting",
      "Accounts payable and receivable management",
      "Bank reconciliations",
      "Budget preparation and cash flow management",
    ],
  },
  {
    title: "Bookkeeping Services",
    icon: `${process.env.PUBLIC_URL}/Assets/BookkeepingCorporateService/icon4.png`,
    img: `${process.env.PUBLIC_URL}/Assets/BookkeepingCorporateService/bookkeepingservices.jpeg`,
    items: [
      "Recording daily financial transactions",
      "Maintaining and organizing financial records",
      "Payroll processing and management",
      "Monthly, quarterly, and annual financial reporting",
    ],
  },
  {
    title: "Specialized Services",
    icon: `${process.env.PUBLIC_URL}/Assets/BookkeepingCorporateService/icon5.png`,
    img: `${process.env.PUBLIC_URL}/Assets/BookkeepingCorporateService/specializedservices.jpeg`,
    items: [
      "Audit preparation and coordination",
      "Internal controls and compliance checks",
      "Assistance with financial restructuring",
      "Financial analysis and forecasting",
    ],
  },
  {
    title: "Additional Support",
    icon: `${process.env.PUBLIC_URL}/Assets/BookkeepingCorporateService/icon2.png`,
    img: `${process.env.PUBLIC_URL}/Assets/BookkeepingCorporateService/additionalservices.jpeg`,
    items: [
      "Assistance with economic substance regulations compliance",
      "Coordination with government authorities for financial submissions",
      "Training and support for in-house accounting teams",
    ],
  },
];

const Services = () => {
  return (
    <div id="AccountingAndBookkeeping" className="bg-gray-50 sectionlayout">
      <h2
        className="font-bold text-center text-[#3D498A] py-12"
        data-aos="fade-up"
        style={{ fontSize: "clamp(34px,2.4vw,48px)" }}
      >
        Our Services Include
      </h2>

      <div
        className="grid grid-cols-1 relative md:grid-cols-2 lg:grid-cols-3 gap-8"
        data-aos="fade-up"
      >
        {services.map((service, index) => (
          <div
            key={index}
            className="relative"
            data-aos="fade-up"
            data-aos-delay={`${index * 10}`}
          >
            <img
              src={service.img}
              className="absolute top-0 left-0 w-full h-full object-cover z-[1]"
              style={{ height: "50%" }}
              alt="Account & Bookkeeping"
            />
            <div
              className="bg-white w-[94%] justify-center mx-auto shadow-md p-4 z-[10] border border-gray-200 flex flex-col items-center relative h-auto md:min-h-[410px]"
              style={{ marginTop: "25%" }}
            >
              <img
                src={service.icon}
                alt={`${service.title} Icon`}
                className="h-12 w-12 mb-4"
              />
              <h3
                className="font-semibold text-center text-[#3D498A] mb-3"
                style={{ fontSize: "clamp(24px,1.6vw,32px)" }}
              >
                {service.title}
              </h3>
              <ul className="list-decimal pl-4 space-y-2 mb-12 text-[15px] text-[#1B365D]">
                {service.items.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
             
              <a
                href="https://wa.me/+971526919516"
                target="_blank"
                rel="noopener noreferrer"
                className="mt-auto relative px-6 py-2 bg-[#3D498A] text-white hover:bg-transparent hover:text-[#3D498A] font-medium border-2 border-[#3D498A] transition duration-300"
                style={{ fontSize: "clamp(14px,1.2vw,16px)" }}
              >
                <span>Contact Us Now</span>
                {/* <ArrowRight className="w-4 h-4 md:w-5 md:h-5 group-hover:translate-x-1 transition-transform" /> */}
              </a>
            </div>
          </div>
        ))}
      </div>
      <p className="text-center text-gray-600 py-20">
        At Right One Corporate Services, we are committed to helping your
        business thrive by providing accurate, timely, and reliable accounting
        and bookkeeping solutions.
      </p>
    </div>
  );
};

export default Services;
