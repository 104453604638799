import React, { useState, useRef } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
// import img1 from "../../../../public/Assets/CompanyFormation/TopZones/H";

const TopBusiness = () => {
  const img1 = `${process.env.REACT_APP_DOMAIN}/Assets/CompanyFormation/TopZones/JAFZA.jpg`;
  const img2 = `${process.env.REACT_APP_DOMAIN}/Assets/CompanyFormation/TopZones/DMCC.png`;
  const img3 = `${process.env.REACT_APP_DOMAIN}/Assets/CompanyFormation/TopZones/DIC.jpg`;
  const img4 = `${process.env.REACT_APP_DOMAIN}/Assets/CompanyFormation/TopZones/DMC.jpg`;
  const img5 = `${process.env.REACT_APP_DOMAIN}/Assets/CompanyFormation/TopZones/SaifZone.jpg`;
  const img6 = `${process.env.REACT_APP_DOMAIN}/Assets/CompanyFormation/TopZones/ADGM.png`;
  const img7 = `${process.env.REACT_APP_DOMAIN}/Assets/CompanyFormation/TopZones/DAFZA.jpg`;
  const img8 = `${process.env.REACT_APP_DOMAIN}/Assets/CompanyFormation/TopZones/FCC.jpg`;
  const img9 = `${process.env.REACT_APP_DOMAIN}/Assets/CompanyFormation/TopZones/HFZ.png`;
  const img10 = `${process.env.REACT_APP_DOMAIN}/Assets/CompanyFormation/TopZones/RAKEZ.jpg`;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [startX, setStartX] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [dragDistance, setDragDistance] = useState(0);
  const containerRef = useRef(null);

  const cards = [
    {
      title: "Jebel Ali Free Zone (JAFZA)",
      t1: "Global hub for trade & logistics, world-class infrastructure.",
      image: img1,
      backgroundPosition: "center",
    },
    {
      title: "Dubai Multi Commodities Centre",
      t1: "Ideal for trade in commodities like gold, diamonds, and tea.",
      image: img2,
      backgroundPosition: "center",
    },
    {
      title: "Dubai Internet City (DIC)",
      t1: "Focused on IT and technology companies.",
      image: img3,
      backgroundPosition: "center",
    },
    {
      title: "Dubai Media City (DMC)",
      t1: "A hub for media, advertising, and creative industries.",
      image: img4,
      backgroundPosition: "center",
    },
    {
      title: "Sharjah Airport International Free Zone",
      t1: "Offers quick setup and proximity to the airport.",
      image: img5,
      backgroundPosition: "center",
    },
    {
      title: "Abu Dhabi Global Market (ADGM)",
      t1: "Focused on financial services and fintech.",
      image: img6,
      backgroundPosition: "center",
    },
    {
      title: "Dubai Airport Free Zone (DAFZA)",
      t1: "Ideal for aviation, logistics, and trading companies.",
      image: img7,
      backgroundPosition: "center",
    },
    {
      title: "Fujairah Creative City",
      t1: "Perfect for media, freelancing, and creative businesses.",
      image: img8,
      backgroundPosition: "center",
    },
    {
      title: "Hamriyah Free Zone",
      t1: "Known for industrial and manufacturing ventures.",
      image: img9,
      backgroundPosition: "center",
    },
    {
      title: "RAK Economic Zone (RAKEZ)",
      t1: "A versatile freezone catering to diverse industries.",
      image: img10,
      backgroundPosition: "center",
    },
  ];

  const isMobile = typeof window !== "undefined" && window.innerWidth < 768;
  const cardsToShow = isMobile ? 1 : 3;
  const cardWidth = 100 / cardsToShow;

  const handleDragStart = (e) => {
    setIsDragging(true);
    setStartX(e.type === "mousedown" ? e.pageX : e.touches[0].pageX);
  };

  const handleDragMove = (e) => {
    if (!isDragging) return;

    e.preventDefault();
    const currentX = e.type === "mousemove" ? e.pageX : e.touches[0].pageX;
    const diff = startX - currentX;
    const containerWidth = containerRef.current.offsetWidth;
    const threshold = containerWidth * 0.2;

    setDragDistance(diff);

    if (Math.abs(diff) > threshold) {
      if (diff > 0 && currentIndex < cards.length - cardsToShow) {
        setCurrentIndex((prev) => prev + 1);
      } else if (diff < 0 && currentIndex > 0) {
        setCurrentIndex((prev) => prev - 1);
      }
      setIsDragging(false);
      setDragDistance(0);
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    setDragDistance(0);
  };

  const next = () => {
    if (currentIndex < cards.length - cardsToShow) {
      setCurrentIndex((prev) => prev + 1);
    } else {
      setCurrentIndex(0);
    }
  };

  const prev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1);
    } else {
      setCurrentIndex(Math.max(cards.length - cardsToShow, 0));
    }
  };

  const getTransform = () => {
    const baseTransform = -(currentIndex * cardWidth);
    const dragAdjustment = isDragging
      ? -((dragDistance / containerRef.current?.offsetWidth) * 100)
      : 0;
    return `translateX(${baseTransform + dragAdjustment}%)`;
  };

  return (
    <div className="container mx-auto px-4 py-5">
      <h1 className="text-2xl md:text-4xl font-bold text-[#3D498A] text-center mb-8">
        Top 10 Freezones in UAE
      </h1>
      <div className="md:w-72 w-[120px] h-1 bg-[#3D498A] mx-auto"></div>

      <div className="relative overflow-hidden mt-8 mb-8">
        <button
          onClick={prev}
          className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-4 z-10 bg-white/80 rounded-full p-2 shadow-lg"
          aria-label="Previous slide"
        >
          <ChevronLeft className="w-6 h-6" />
        </button>

        <button
          onClick={next}
          className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-4 z-10 bg-white/80 rounded-full p-2 shadow-lg"
          aria-label="Next slide"
        >
          <ChevronRight className="w-6 h-6" />
        </button>

        <div
          ref={containerRef}
          className="relative overflow-hidden touch-pan-y"
        >
          <div
            className="flex transition-transform duration-300 ease-out"
            style={{
              transform: getTransform(),
              cursor: isDragging ? "grabbing" : "grab",
            }}
            onMouseDown={handleDragStart}
            onMouseMove={handleDragMove}
            onMouseUp={handleDragEnd}
            onMouseLeave={handleDragEnd}
            onTouchStart={handleDragStart}
            onTouchMove={handleDragMove}
            onTouchEnd={handleDragEnd}
          >
            {cards.map((card, index) => (
              <div
                key={index}
                className="flex-none"
                style={{ width: `${cardWidth}%` }}
              >
                <div className="m-2 rounded-lg max-h-[90%] p-4 overflow-hidden shadow-lg bg-white transition-all duration-300 hover:shadow-xl flex flex-col">
                  {/* Image Section */}
                  <div className="h-48 overflow-hidden rounded-xl">
                    <img
                      src={card.image}
                      alt={card.title}
                      className="w-full max-h-full object-cover"
                    />
                  </div>

                  {/* Content Section */}
                  <div className="p-4 flex flex-col flex-1 items-center bg-white">
                    <h3 className="text-[#3D498A] text-center text-lg font-semibold mb-2">
                      {card.title}
                    </h3>
                    <p className="text-gray-600 text-center text-sm font-normal mb-4">
                      {card.t1}
                    </p>

                    {/* Get Quote Button */}
                    <a
                      href="https://wa.me/+971526919516"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="px-6 py-2 mt-auto bg-[#3D498A] text-white rounded hover:bg-[#3D498A]/90 transition-colors"
                    >
                      <span>Get Quote</span>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="flex justify-center gap-2 mt-4 md:hidden">
        {cards.map((_, index) => (
          <div
            key={index}
            className={`w-2 h-2 rounded-full ${
              index === currentIndex ? "bg-yellow-400" : "bg-gray-400"
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default TopBusiness;
