import React from "react";
import { FaTrophy, FaShieldAlt, FaHandsHelping, FaUsers, FaClock, FaBalanceScale } from "react-icons/fa";

const WhatSetsUsApartSection = () => {
  const uniqueFeatures = [
    {
      icon: FaTrophy,
      title: "Proven Expertise",
      description:
        "Over a decade of experience in the UAE’s dynamic business landscape.",
    },
    {
      icon: FaShieldAlt,
      title: "End-to-End Solutions",
      description:
        "Comprehensive services, from business setup to banking and visa assistance.",
    },
    {
      icon: FaHandsHelping,
      title: "Client-Centric Approach",
      description: "Tailored strategies to meet unique business requirements.",
    },
    {
      icon: FaUsers,
      title: "Reputation of Trust",
      description:
        "Proudly serving 150+ corporate clients with integrity and reliability.",
    },
    {
      icon: FaClock,
      title: "Efficiency at Its Best",
      description: "Streamlined processes to save your time and resources.",
    },
    {
      icon: FaBalanceScale,
      title: "Ethical Commitment",
      description:
        "Guided by the principle of doing what is right, not what is easy.",
    },
  ];

  return (
    <section className="py-16 px-6 bg-[#3D498A] container mx-auto">
      <div className="max-w-10xl mx-auto flex flex-col lg:flex-row items-center justify-between gap-28">
        <div className="flex flex-col mx-10">
          <div className="font-extrabold text-white text-center" style={{ fontSize: "clamp(40px,2.7vw,180px)"}}>What Sets <br /> Us Apart</div>
          {/* <div>
            <h2 className="text-5xl font-extrabold text-center text-white mx-9">
              What Sets
            </h2>
          </div>
          <div>
            <h2 className="text-5xl font-extrabold text-white text-center text- mx-9  ">
               Us Apart
            </h2>
          </div> */}
        </div>

        <div className="flex-1">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 mr-[20%] w-[100%]">
            {uniqueFeatures.map((feature, index) => (
              <div
                key={index}
                className="relative bg-white p-6 rounded-xl shadow-lg hover:transform hover:scale-105 transition-all duration-300"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-xl"></div>
                <div className="relative z-10 flex flex-col items-center text-center space-y-4">
                  <feature.icon className="w-16 h-16 text-blue-950" />
                  <h3 className="text-2xl font-semibold text-gray-900">
                    {feature.title}
                  </h3>
                  <p className="text-lg text-gray-600">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatSetsUsApartSection;
