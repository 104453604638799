import React from 'react'

import Hero from './Hero'
import Introduction from './Introduction'
import Benefits from './Benefits'
import Requirements from './Requirments'
import Eligibility from './Eligibility'
import Form from '../../Components/Form/Form23then135'
import FAQ from './Faqs'

function GoldenVisaHome() {
  return (
    <>
      <Hero/>
      <Introduction/>
      <Benefits/>
      <Requirements/>
      <Eligibility/>
      <Form/>
      <FAQ/>    
    </>
  )
}

export default GoldenVisaHome
