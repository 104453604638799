import React from "react";

const Services = () => {
  const img1 = `${process.env.REACT_APP_DOMAIN}/Assets/CorporateServices/BankAccountOpen/BankSelection.png`;

  const banks = [
    "EMIRATES NBD",
    "ABU DHABI COMMERCIAL BANK (ADCB)",
    "MASHREQ BANK",
    "DUBAI ISLAMIC BANK (DIB)",
    "ABU DHABI ISLAMIC BANK (ADIB)",
    "FIRST ABU DHABI BANK (FAB)",
    "RAKBANK",
    "COMMERCIAL BANK OF DUBAI (CBD)",
  ];

  

  return (
    <div id = "bank" className="max-w-7xl container mx-auto py-12 px-4">
      <div className="mb-8">
        <h2 className="text-[#4A4D9C] text-2xl md:text-3xl text-center lg:text-left font-bold">
          ASSISTANCE WITH BANK SELECTION
        </h2>
        <p className="text-gray-600 mt-4 text-justify lg:text-left text-lg max-w-2xl">
          We help you choose the most suitable bank based on your business
          needs, activity, and structure. Our team has experience with
          leading <br /> banks in the UAE, including:
        </p>
      </div>

      {/* Ensure proper responsive layout */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Bank List */}
        <div className="flex flex-col gap-4">
          {banks.map((bank, index) => (
            <div
              key={index}
              className="flex items-center bg-[#3D498A] text-white py-6 px-6 rounded-md space-x-4"
            >
              <svg
                className="w-8 h-8 flex-shrink-0 text-[#4A4D9C] bg-white border-white rounded-full"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <span className="font-semibold text-lg text-shadow-lg">
                {bank}
              </span>
            </div>
          ))}
        </div>

        {/* Image Section */}
        <div className="relative h-[400px] md:h-[500px] lg:h-[750px] mt-8 lg:mt-0">
          <img
            src={img1}
            alt="Burj Khalifa"
            className="w-full h-full object-cover rounded-lg shadow-lg"
          />
        </div>
      </div>
    </div>
  );
};

export default Services;
