import React, { useState, useRef } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const BusinessLicenseCarousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [startX, setStartX] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [dragDistance, setDragDistance] = useState(0);
    const containerRef = useRef(null);

    const cards = [
        {
            title: 'Technical License',
            t1: "AED 11,900",
            image: 'https://rightone.ae/Assets/BusinessLicense/TechnicalLicense.jpg',
            backgroundPosition: 'center',
        },
        {
            title: 'Real Estate License',
            t1: "AED 16,999",
            image: 'https://rightone.ae/Assets/BusinessLicense/RealEstateLicense.jpg',
            backgroundPosition: 'center',
        },
        {
            title: 'Salon License',
            t1: "AED 14,999",
            image: 'https://rightone.ae/Assets/BusinessLicense/SalonLicense.jpg',
            backgroundPosition: 'center',
        },
        {
            title: 'Restaurant License',
            t1: "AED 17,999",
            image: 'https://rightone.ae/Assets/BusinessLicense/RestaurantLicense.jpg',
            backgroundPosition: 'center',
        },
        {
            title: 'Digital Marketing License',
            t1: "AED 10,900",
            image: 'https://rightone.ae/Assets/BusinessLicense/DigitalMarketingLicense.jpg',
            backgroundPosition: 'center',
        },
        {
            title: 'General Trading License',
            t1: "AED 10,999",
            image: 'https://rightone.ae/Assets/BusinessLicense/GeneralTradingLicense.jpg',
            backgroundPosition: 'center',
        },
        {
            title: 'e-Commerce License',
            t1: "AED 9,999",
            image: 'https://rightone.ae/Assets/BusinessLicense/eCommerceLicense.jpg',
            backgroundPosition: 'center',
        },
        {
            title: 'Corporate License',
            t1: "AED 13,999",
            image: 'https://rightone.ae/Assets/BusinessLicense/CorporateLicense.jpg',
            backgroundPosition: 'center',
        },
    ];


    const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;
    const cardsToShow = isMobile ? 1 : 4;
    const cardWidth = 100 / cardsToShow;

    const handleDragStart = (e) => {
        setIsDragging(true);
        setStartX(e.type === 'mousedown' ? e.pageX : e.touches[0].pageX);
    };

    const handleDragMove = (e) => {
        if (!isDragging) return;

        e.preventDefault();
        const currentX = e.type === 'mousemove' ? e.pageX : e.touches[0].pageX;
        const diff = startX - currentX;
        const containerWidth = containerRef.current.offsetWidth;
        const threshold = containerWidth * 0.2;

        setDragDistance(diff);

        if (Math.abs(diff) > threshold) {
            if (diff > 0 && currentIndex < cards.length - cardsToShow) {
                setCurrentIndex(prev => prev + 1);
            } else if (diff < 0 && currentIndex > 0) {
                setCurrentIndex(prev => prev - 1);
            }
            setIsDragging(false);
            setDragDistance(0);
        }
    };

    const handleDragEnd = () => {
        setIsDragging(false);
        setDragDistance(0);
    };

    const next = () => {
        if (currentIndex < cards.length - cardsToShow) {
            setCurrentIndex(prev => prev + 1);
        } else {
            setCurrentIndex(0);
        }
    };

    const prev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(prev => prev - 1);
        } else {
            setCurrentIndex(Math.max(cards.length - cardsToShow, 0));
        }
    };

    const getTransform = () => {
        const baseTransform = -(currentIndex * cardWidth);
        const dragAdjustment = isDragging ? -(dragDistance / containerRef.current?.offsetWidth * 100) : 0;
        return `translateX(${baseTransform + dragAdjustment}%)`;
    };

    return (
        <div className="container mx-auto px-4 py-12" data-aos="fade-up">
            <h2 className="text-4xl font-bold text-[#3D498A] text-center mb-8" style={{ fontSize: "clamp(25px,3vw,200px)" }}>
                Business Licenses

            </h2>
            <div className="md:w-72 w-[120px] h-1 bg-[#3D498A] mx-auto "></div>

            <div className="relative overflow-hidden mt-8">
                <button
                    onClick={prev}
                    className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-4 z-10 bg-white/80 rounded-full p-2 shadow-lg"
                    aria-label="Previous slide"
                >
                    <ChevronLeft className="w-6 h-6" />
                </button>

                <button
                    onClick={next}
                    className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-4 z-10 bg-white/80 rounded-full p-2 shadow-lg"
                    aria-label="Next slide"
                >
                    <ChevronRight className="w-6 h-6" />
                </button>

                <div
                    ref={containerRef}
                    className="relative overflow-hidden touch-pan-y"
                >
                    <div
                        className="flex transition-transform duration-300 ease-out"
                        style={{
                            transform: getTransform(),
                            cursor: isDragging ? 'grabbing' : 'grab'
                        }}
                        onMouseDown={handleDragStart}
                        onMouseMove={handleDragMove}
                        onMouseUp={handleDragEnd}
                        onMouseLeave={handleDragEnd}
                        onTouchStart={handleDragStart}
                        onTouchMove={handleDragMove}
                        onTouchEnd={handleDragEnd}
                    >
                        {cards.map((card, index) => (
                            <div
                                key={index}
                                className="flex-none"
                                style={{ width: `${cardWidth}%` }}
                            >
                                <div className="relative overflow-hidden rounded-lg h-[400px] m-2 group transition-all duration-300">
                                    <div
                                        className="absolute inset-0 bg-cover bg-center"
                                        style={{
                                            backgroundImage: `url(${card.image})`,
                                            backgroundPosition: card.backgroundPosition,
                                        }}
                                    >
                                        <div className="absolute inset-0 bg-black/50"></div>
                                    </div>

                                    <div className="relative h-full flex flex-col items-center justify-center p-4">
                                        <div className="w-16 h-16 bg-white/20 rounded-full flex items-center justify-center mb-4">
                                            <div className="w-8 h-8 bg-white rounded-full"></div>
                                        </div>

                                        <h3 className="text-white text-center text-lg font-semibold mb-4">
                                            {card.title}
                                        </h3>
                                        <p className="text-white text-center text-small font-normal mb-2 mt-[-15px]">
                                            {card.t1}
                                        </p>
                                        <a
                                            href="https://wa.me/+971526919516"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="px-6 py-2 bg-[#3D498A] text-white rounded hover:bg-[#3D498A] transition-colors"
                                        >
                                            <span>Get Quote</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="flex justify-center gap-2 mt-4 md:hidden">
                {cards.map((_, index) => (
                    <div
                        key={index}
                        className={`w-2 h-2 rounded-full ${index === currentIndex ? 'bg-yellow-400' : 'bg-gray-400'}`}
                    />
                ))}
            </div>
        </div>
    );
};

export default BusinessLicenseCarousel;