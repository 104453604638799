import React, { useState } from 'react';
import {
  FileText, Users, Globe, Shield, Briefcase,
  Home, Clipboard, HelpCircle
} from 'lucide-react';

const ServiceCard = ({ icon: Icon, title, description, details }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      className="relative group bg-white rounded-xl shadow-lg overflow-hidden 
      transform transition-all duration-300 hover:scale-105 hover:shadow-2xl"
    >
      <div className="absolute inset-0 bg-[#3D498A] opacity-10 group-hover:opacity-20 transition-opacity"></div>

      <div className="relative z-10 p-6 flex flex-col h-full">
        <div className="flex items-center mb-4">
          <div className="bg-[#3D498A] text-white p-3 rounded-full mr-4">
            <Icon className="w-6 h-6" />
          </div>
          <h3 className="text-xl font-bold text-[#3D498A]">{title}</h3>
        </div>
        <p className="text-gray-600 mb-4">{description}</p>

        <div className="flex-grow">
          {isExpanded ? (
            <ul className="text-sm text-gray-700 space-y-2 list-disc pl-4">
              {details.map((detail, index) => (
                <li key={index}>{detail}</li>
              ))}
            </ul>
          ) : (
            <p className="text-sm text-gray-500 line-clamp-3">{details.slice(0, 3).join(', ')}...</p>
          )}
        </div>

        <div className="mt-4 border-t border-gray-200 pt-4 flex justify-between items-center">
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="text-sm text-[#3D498A] font-semibold hover:underline"
          >
            {isExpanded ? 'Collapse' : 'View Details'}
          </button>
          <a
            href="https://wa.me/+971526919516"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#3D498A] hover:text-[#232952] transition-colors"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              className="h-5 w-5 transition-transform group-hover:scale-110 fill-current"
            >
              <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

const ProServicesDashboard = () => {
  const services = [
    {
      icon: FileText,
      title: "General PRO Services",
      description: "Comprehensive visa and administrative support",
      details: [
        "Visa renewal",
        "Processing of investor visas",
        "Visa cancellation",
        "Entry permits",
        "Medical health tests",
        "Emirates ID application and renewal",
        "Change of visa status and visa stamping",
        "Assistance with labor and immigration formalities",
        "Document attestation and translation services",
        "Assistance with company establishment cards and updates",
        "Notarization and attestation of corporate documents",
        "Coordination with government departments for approvals and permits",
      ],
    },
    {
      icon: Users,
      title: "Investor Visa Services",
      description: "End-to-end assistance for investor visa requirements",
      details: [
        "Processing new investor visas",
        "Change of visa status",
        "Visa stamping",
        "Medical tests and Emirates ID processing",
        "Visa renewal and cancellation",
        "Updating visa details with relevant authorities",
      ],
    },
    {
      icon: Globe,
      title: "Family Visa Services",
      description: "Seamless residence visa solutions for families",
      details: [
        "Family visa application and renewal",
        "Change of visa status",
        "Emirates ID application and renewal",
        "Medical tests",
        "Visa cancellation and re-application",
        "Assistance with dependent sponsorship requirements",
      ],
    },
    {
      icon: Shield,
      title: "Employment Visa Services",
      description: "End-to-end management of workforce visa needs",
      details: [
        "Employment visa application and renewal",
        "Entry permits",
        "Visa stamping",
        "Labor contract processing",
        "Change of status and cancellation",
        "Coordination with MOHRE and GDRFA for approvals",
      ],
    },
    {
      icon: Briefcase,
      title: "Green Visa Services",
      description: "Sponsorship-free residency pathways",
      details: [
        "Green Visa application processing",
        "Visa renewal and status updates",
        "Medical health tests and Emirates ID services",
        "Change of visa status and visa cancellation",
      ],
    },
    {
      icon: Home,
      title: "Maid Visa Services (Tadbeer)",
      description: "Tadbeer-compliant domestic worker visa processing",
      details: [
        "Application for new maid visas",
        "Visa renewal and cancellation",
        "Processing Emirates ID and medical health tests",
        "Change of status and visa stamping",
        "Assistance with labor contract preparation and submission",
        "Coordination with Tadbeer-approved centers for onboarding",
      ],
    },
    {
      icon: Clipboard,
      title: "MOHRE Services",
      description: "Comprehensive HR and labor regulation support",
      details: [
        "Labor card issuance and renewal",
        "Work permit applications and renewals",
        "Processing and cancellation of labor contracts",
        "Updating employee information in MOHRE records",
        "Assistance with wage protection system (WPS) compliance",
        "Handling complaints and disputes with MOHRE",
        "Guidance on labor law compliance and updates",
        "Assistance with quota approvals and updates",
        "Support for establishing MOHRE-registered entities",
      ],
    },
    {
      icon: HelpCircle,
      title: "Additional PRO Services in Dubai",
      description: "Specialized government and business consultations",
      details: [
        "Assistance with trade license renewals and amendments",
        "Approvals from government and semi-government entities",
        "Municipality approvals and inspections",
        "Assistance with legal translations and document preparation",
        "Support with free zone-specific processes and compliance",
        "Liaising with Dubai Chamber of Commerce for certifications and attestations",
        "Corporate bank account opening coordination",
        "Processing tenancy contract attestations (Ejari)",
        "Obtaining certifications such as Good Conduct Certificates",
      ],
    },
  ];

  return (
    <div id='services' className="">
    <div  className="bg-gray-50 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="relative overflow-hidden mb-16" data-aos="fade-up">
          <div className="relative container mx-auto px-6 pb-1.5 flex flex-col items-center justify-center text-center">
            <div  className="inline-block px-4 py-2 bg-gradient-to-r from-[#3D498A] to-blue-100 rounded-full mb-6 animate-bounce-horizontal">
              <span  className="text-white font-medium">PRO Services</span>
            </div>

            <h2  className="text-4xl sm:text-5xl lg:text-6xl font-bold text-gray-900 mb-6">
              Professional{" "}
              <span className="relative text-[#3D498A]">Services</span>
            </h2>

            <div>
              <p className="text-xl">
                Our Public Relations Officer (PRO) services are designed to
                handle all your documentation, visa, and regulatory requirements
                efficiently. We assist with a wide range of PRO services,
                ensuring your business operations remain smooth and compliant
                with UAE regulations.
              </p>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>
      </div>
      <style jsx>{`
        @keyframes bounce-horizontal {
          0%,
          100% {
            transform: translateX(0);
          }
          50% {
            transform: translateX(30px);
          }
          25%,
          75% {
            transform: translateX(-60px);
          }
        }
        .animate-bounce-horizontal {
          animation: bounce-horizontal 5s infinite ease-in-out;
        }
      `}</style>
    </div>
      </div>
  );
};

export default ProServicesDashboard;
