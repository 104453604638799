import React from "react";
import RightonePage from "./Hero";
import Carousal from "./Cards";

const MainPage = () => {
  return (
    <div>
      <RightonePage />
      <Carousal />
    </div>
  );
};

export default MainPage;
