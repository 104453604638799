import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import AOS from "aos";
import "aos/dist/aos.css";

const steps = [
  {
    title: "Investors",
    description: [
      "Minimum AED 2 million investment in trade license or UAE real estate",
      "Property ownership of at least AED 2 million",
      "Bank investments of at least AED 2 million for 2 years",
    ],
  },
  {
    title: "Directors/CEOs/Executives",
    description: [
      "Recipients of minimum monthly salary of AED 30,000 (must show last 6 months' pay slip)",
    ],
  },
  {
    title: "Entrepreneurs",
    description: [
      "Own a business in Dubai that generates a minimum annual profit margin of AED 2 million",
    ],
  },
  {
    title: "Gifted Students",
    description: [
      "Are under the age of 25 years",
      "Maintain a minimum GPA of 3.8",
      "Hold a bachelor's degree from a university",
    ],
  },
  {
    title: "Doctors/Researchers/Specialists",
    para: "To meet eligibility requirements for this category, applicants must fulfil at least two of the following conditions:",
    description: [
      "Have at lease 10 years of experience in their respective fields",
      "Recipients of note-worthy awards in their respective fields",
      "Publication of research in any recognised journal",
      "Hold patent for an invention",
    ],
  },
  {
    title: "Highly Qualified Individuals",
    para: "To meet eligibility requirements for this category, applicants must fulfil at least one of the following conditions:",
    description: [
      "Recipients of note-worthy awards in their field",
      "Recognition from the UAE or home country ministries in the form of awards or certificates",
      " Appreciation letter from home country government or certification/accreditation from respective UAE ministry",
      "Hold patent for invention under your name",
    ],
  },
];

const Requirements = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const totalHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const progressPercentage = (scrollTop / totalHeight) * 210;

      setProgress(Math.min(Math.max(progressPercentage, 0), 100));
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="relative flex mx-auto min-h-[90vh] mt-[-40px]  bg-white py-16">
      <div className=" mx-auto sectionlayout">
        <h1
          className="text-3xl lg:text-5xl drop-shadow-lg font-bold text-center text-[#3D498A] mb-12"
          data-aos="fade-up"
        >
          Golden Visa Requirements
        </h1>
        <div className="relative mt-20">
          {/* Vertical Line */}
          <div className="absolute left-10 top-0 hidden md:flex  bottom-0 w-[4px] bg-[#f7f7f7]">
            <div
              className="absolute left-0   top-0 w-full bg-[#3D498A] transition-all duration-300"
              style={{ height: `${progress}%` }}
            ></div>
          </div>

          {/* Steps */}
          <ul className="space-y-12">
            {steps.map((step, index) => (
              <StepItem
                key={index}
                para={step.para}
                title={step.title}
                description={step.description}
                index={index}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const StepItem = ({ title, para, description, index }) => {
  const { ref, inView } = useInView({ threshold: 0.8 });

  return (
    <li ref={ref} className="flex items-start relative">
      <div
        className={`w-6 h-6 hidden md:flex rounded-full flex-shrink-0 ml-[30px] border-4 transition-colors  duration-300 ${
          inView
            ? "bg-[#3D498A] border-[#3D498A]"
            : "bg-[#eaeaea] border-[#f7f7f7]"
        }`}
      ></div>

      {/* Content */}
      <div
        className="md:ml-10 ml-0 px-3 py-6 md:px-6  bg-[#3D498A] text-white rounded-lg  shadow-lg w-full lg:w-[90%]"
        data-aos="fade-up"
        data-aos-delay={`${index * 10}`}
      >
        <h2 className="font-bold sm:text-xl mb-3 text-white">{title}</h2>
        <p className="text-sm mb-3">{para}</p>
        <ul className="text-sm text-white space-y-2">
          {description.map((desc, idx) => (
            <li key={idx} className="flex items-start">
              <span className="text-white mr-2">&#10003;</span>
              {desc}
            </li>
          ))}
        </ul>
      </div>
    </li>
  );
};

export default Requirements;
