import React from "react";
import { ChevronRight } from "lucide-react";

const Hero = () => {
  const img1 = `${process.env.REACT_APP_DOMAIN}/Assets/Services/ProServices/pro_services.jpeg`;
  
  // Function to handle smooth scrolling to the services section with offset compensation
  const scrollToServices = () => {
    const servicesSection = document.getElementById('services');
    if (servicesSection) {
      // Get the navbar height or add a fixed offset (adjust this value as needed)
      const navbarHeight =  100;
      
      const elementPosition = servicesSection.getBoundingClientRect().top + window.pageYOffset;
      
      // Scroll to the element position minus the navbar height
      window.scrollTo({
        top: elementPosition - navbarHeight,
        behavior: 'smooth'
      });
    }
  };

  return (
    <section className="relative h-[80vh] w-full overflow-hidden">
      {/* Background Image Container */}
      <div
        className="absolute inset-0 w-full h-[80vh] bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${img1})`,
        }}
      >
        {/* Sophisticated overlay with multiple gradients */}
        <div className="absolute inset-0 bg-gradient-to-r from-black/50 via-black/50 to-transparent" />
        <div className="absolute inset-0 bg-gradient-to-t from-black/30 via-black/10 to-transparent" />
      </div>

      {/* Content Container */}
      <div className="relative h-[80vh] flex items-center justify-center text-center">
        <div className="container mx-auto px-4 md:px-8">
          <div className="max-w-4xl mx-auto" data-aos="fade-up">
            {/* Decorative element */}
            <div className="w-24 h-1 bg-[#3D498A] mb-8 transform -skew-x-12 mx-auto" />

            {/* Main heading with gradient text */}
            <h1 className="text-3xl md:text-6xl font-bold mb-6 text-white">
              Professional PRO Services
            </h1>

            {/* Subtitle */}
            <div className="inline-block bg-[#3D498A]/10 backdrop-blur-sm px-4 py-2 rounded-lg mb-8">
              <span className="text-white font-semibold text-lg md:text-xl">
                Comprehensive Visa & Documentation Solutions
              </span>
            </div>

            {/* Description with improved typography */}
            <p className="md:text-lg text-white leading-relaxed mb-6 max-w-2xl mx-auto">
              Right One Corporate Services offers end-to-end PRO solutions including visa services,
              document processing, and government liaison. We handle investor visas, family visas,
              employment visas, and all MOHRE-related services with expertise and efficiency.
            </p>

            {/* CTA Section */}
            <div className="flex flex-col sm:flex-row gap-1 justify-center">
              <button 
                onClick={scrollToServices}
                className="group flex mb-4 z-10 items-center justify-center gap-2 px-8 py-4 bg-[#3D498A] text-white font-semibold rounded-lg hover:bg-[#3d498a8f] transition-all duration-300 transform hover:-translate-y-1"
              >
                Explore Services
                <ChevronRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
              </button>

              <a href="/contact">
                <button className="flex items-center z-10 mb-4 justify-center gap-2 px-8 py-4 bg-[#3d498a3d] border-solid border-2 border-[#3d498a] text-white font-semibold rounded-lg hover:bg-[#3d498a] transition-all duration-300 transform hover:-translate-y-1">
                  Get Started
                  <ChevronRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Decorative elements */}
      <div className="absolute bottom-0 left-0 w-full h-32 bg-gradient-to-t from-black/80 to-transparent" />
      <div className="absolute top-0 right-0 w-96 h-96 bg-[#3D498A]/10 rounded-full filter blur-3xl" />
    </section>
  );
};

export default Hero;