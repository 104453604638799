import React, { useState } from "react";

const BestBusiness = () => {
  const [showAll, setShowAll] = useState(false);

  const businesses = [
    {
      name: "Trading and Export",
      benefits: "Access to global markets and trade opportunities",
      icon: "fas fa-globe",
    },
    {
      name: "IT and Technology",
      benefits: "Growing tech hub with strong digital infrastructure",
      icon: "fas fa-laptop-code",
    },
    {
      name: "Media and Advertising",
      benefits: "Creative freedom and media city benefits",
      icon: "fas fa-bullhorn",
    },
    {
      name: "Logistics and Freight",
      benefits: "Strategic location for global distribution",
      icon: "fas fa-truck",
    },
    {
      name: "Consultancy Services",
      benefits: "High demand for professional expertise",
      icon: "fas fa-users",
    },
    {
      name: "E-commerce",
      benefits: "Growing digital marketplace opportunities",
      icon: "fas fa-shopping-cart",
    },
    {
      name: "Education and Training",
      benefits: "Strong focus on knowledge development",
      icon: "fas fa-graduation-cap",
    },
    {
      name: "Financial Services",
      benefits: "Access to international financial markets",
      icon: "fas fa-chart-line",
    },
    {
      name: "Healthcare",
      benefits: "Rising demand for medical services",
      icon: "fas fa-heartbeat",
    },
    {
      name: "Renewable Energy Solutions",
      benefits: "Support for sustainable initiatives",
      icon: "fas fa-solar-panel",
    },
  ];

  const visibleBusinesses = showAll ? businesses : businesses.slice(0, 3);

  return (
    <div className="bg-white py-10 px-8 container mx-auto rounded-xl">
      <div className="max-w-7xl mx-auto text-center">
        <h2 className="text-4xl font-bold mb-16 text-[#3D498A]">
          Best Business Activities to Setup in Freezone
        </h2>
        <div className="grid md:grid-cols-3 gap-12">
          {visibleBusinesses.map((business, index) => (
            <div
              key={index}
              className="relative border border-[#3D498A] rounded-xl"
            >
              <div className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-16 h-16 bg-white border border-[#3D498A] rounded-full flex items-center justify-center shadow-lg z-10">
                <i className={`${business.icon} text-3xl text-[#3D498A]`}></i>
              </div>
              <div className="bg-white rounded-xl shadow-lg p-8 text-center h-full border border-gray-100 hover:shadow-xl transition-all duration-300 ease-in-out pt-12">
                <h3 className="text-xl text-[#3D498A] font-semibold mb-4">
                  {business.name}
                </h3>
                {/* <p className="text-[#3D498A]">{business.benefits}</p> */}
              </div>
            </div>
          ))}
        </div>
        <button
          onClick={() => setShowAll(!showAll)}
          className="mt-12 px-8 py-3 bg-[#3D498A] text-white rounded-lg hover:bg-[#2A345F] transition-colors duration-300 font-semibold"
        >
          {showAll ? "Show Less" : "Show More"}
        </button>
      </div>
    </div>
  );
};

export default BestBusiness;
