import React from "react";

const Introduction = () => {
  return (
    <div id="freezone" className="flex flex-col max-w-6xl mx-auto mt-20 gap-24">
      {/* Text Content */}
      <div className="w-full flex flex-col justify-center items-center">
        <h1 className="text-2xl md:text-3xl text-center font-bold mb-4">
          What is
          <span className="text-[#3D498A]"> Freezone Company Formation</span>?
        </h1>

        <p className="mb-2 lg:text-center text-justify lg:text-lg mx-6">
          Freezones are designated areas in the UAE that offer businesses
          specialized benefits like tax exemptions, full ownership, and
          simplified regulations. These zones cater to specific industries,
          making them ideal for companies looking for a cost-effective and
          business-friendly environment.
        </p>
      </div>
    </div>
  );
};

export default Introduction;
