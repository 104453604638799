import React from "react";
const Img1 = `${process.env.PUBLIC_URL}/Assets/offshore/trading.jpg`;
const Hero = () => {

  const offshore = () => {
    setTimeout(() => {
      const otherServicesSection = document.getElementById('offshore')
      if (otherServicesSection) {
        window.scrollTo({
          top: otherServicesSection.offsetTop - 120,
          behavior: 'smooth'
        })
      }
    })
  }

  return (
    <div className="relative h-[80vh]">
      {/* Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(${Img1})`, // Correctly reference the imported image
        }}
      >
        <div className="absolute inset-0 bg-black/50" /> {/* Overlay */}
      </div>

      {/* Content */}
      <div className="relative z-10 h-full flex flex-col items-center justify-center text-center text-white px-4">
        {/* Main Heading */}
        <h1 className="text-4xl font-extrabold mb-12">
          Right One Corporate Services <br /> UAE Offshore Solutions
        </h1>

        {/* Subheading */}
        <h2 className="text-2xl font-semibold tracking-wider mb-6">
          OFFSHORE COMPANY FORMATION
        </h2>

        {/* Description */}
        <p className="max-w-3xl text-lg mb-10">
          Offshore company formation in the UAE is a
          strategic solution for businesses looking to operate internationally while enjoying tax benefits
          and confidentiality. Right One Corporate Services ensures a smooth and compliant process
          for setting up your offshore entity.
        </p>

        {/* Buttons */}
        <div className="flex gap-6">
          <button onClick={offshore} className="bg-[#3D498A] text-white px-8 py-3 rounded-lg hover:bg-blue-700 transition">
            Learn More
          </button>
          <a href="/contact">
            <button className="border-2 border-white text-white px-8 py-3 rounded-lg hover:bg-white/10 transition">
              Contact Us
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Hero;
