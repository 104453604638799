import React from "react";
import {
  ArrowRight,
  ClipboardCheck,
  ShoppingBag,
  Building,
} from "lucide-react";
// import img1 from "../../../../public/Assets/CompanyFormation/MainlandCard.webp"

const IntroductionCards = () => {
  const img1 = `${process.env.REACT_APP_DOMAIN}/Assets/CompanyFormation/MainlandCard.webp`;
  const img2 = `${process.env.REACT_APP_DOMAIN}/Assets/CompanyFormation/CommercialCard.webp`;
  const img3 = `${process.env.REACT_APP_DOMAIN}/Assets/CompanyFormation/OfficeCard.webp`;

  const cards = [
    {
      title: "Dubai Mainland Professional License | For Consultants & Service Providers",
      description:
        "A professional license in Dubai Mainland is ideal for businesses offering specialized services like consulting, legal services, and IT solutions. This license allows sole proprietors or professional partnerships to operate within Dubai Mainland with full control over their business.",
      link: "https://wa.me/+971526919516",
      image: img1,
      icon: <ClipboardCheck className="w-10 h-10 text-gray-800" />,
    },
    {
      title: "Dubai Mainland Commercial License | For Trading Businesses",
      description:
        "A commercial license in Dubai Mainland is essential for businesses involved in trading goods, including general trading, retail, and wholesale. This license enables entrepreneurs to establish a strong presence in Dubai's dynamic market.",
      link: "https://wa.me/+971526919516",
      image: img2,
      icon: <ShoppingBag className="w-10 h-10 text-gray-800" />,
    },
    {
      title: "Dubai Mainland Branch Office | Expand Your International Business",
      description:
        "A branch office in Dubai Mainland allows foreign companies to expand their operations without creating a new legal entity. This structure is ideal for businesses looking to leverage Dubai's strategic location and economic advantages.",
      link: "https://wa.me/+971526919516",
      image: img3,
      icon: <Building className="w-10 h-10 text-gray-800" />,
    },
  ];

  return (
    <div className="container mx-auto px-4 py-20">
      <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {cards.map((card, index) => (
          <li
            key={index}
            className="bg-white rounded-xl shadow-xl hover:shadow-2xl transition-all duration-300"
          >
            <div className="relative">
              <img
                src={card.image}
                alt={card.title}
                className="w-full h-auto rounded-t-xl object-cover"
              />
            </div>
            <div className="p-6 border border-gray-100 rounded-b-xl">
              <div className="flex items-center justify-center bg-[#3d498a25] rounded rounded-8 p-1 space-x-3 mb-4">
                <h1 className="font-bold text-gray-800 hover:text-[#3D498A] transition-all cursor-pointer text-center text-lg">
                  {card.title}
                </h1>
              </div>
              <p
                className={`text-gray-700 text-justify ${
                  index === 1 ? "mb-12 mt-4" : "mb-6"
                }`}
              >
                {card.description}
              </p>
              <a
                href={card.link}
                target="_blank"
                rel="noopener noreferrer"
                className="w-full bg-[#3D498A] hover:bg-[#3d498aa9] text-white py-4 px-6 rounded-lg font-semibold flex items-center justify-center space-x-2 transition-all duration-300 group"
              >
                <span>Consult Now</span>
                <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
              </a>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default IntroductionCards;
