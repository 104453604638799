import React from "react";
import { BsChevronRight } from "react-icons/bs";
const Hero = () => {

  const AccountingAndBookkeeping = () => {
    setTimeout(()=>{
      const AccountingAndBookkeepingSection = document.getElementById('AccountingAndBookkeeping')
      if(AccountingAndBookkeepingSection){
        const yPosition = AccountingAndBookkeepingSection.offsetTop;
        window.scrollTo({
          top: yPosition - 100,
          behavior: 'smooth'
        })
      }
    })
  }
  return (
    <div className="montserrat relative min-h-[70vh] flex justify-center items-center py-8">
      <div className="sectionlayout">
        <div
          className="absolute top-0 left-0 w-full h-full bg-cover bg-center"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/Assets/corporate-heros/AccountingandBookkeepingServices.jpeg)`,
          }}
        ></div>
        <div className="absolute inset-0 bg-black opacity-40 z-10"></div>
        <div
          className="relative drop-shadow-lg mx-auto z-20 text-center"
          data-aos="fade-up"
        >
          <div className="space-y-7 mx-auto py-5 md:py-10">
            <h1
              className="font-bold drop-shadow-lg text-white uppercase"
              style={{ fontSize: "clamp(40px,3.2vw,64px)" }}
            >
              Accounting and Bookkeeping Services
            </h1>
            <p
              style={{ fontSize: "clamp(18px,1vw,20px)" }}
              className="text-white pt-3 text-center md:w-[70%] mx-auto"
            >
              Our comprehensive accounting and bookkeeping services are tailored to meet the financial needs of businesses of all sizes. We ensure compliance with UAE financial regulations and help maintain accurate financial records.
            </p>
            {/* CTA Section */}
            <div className="flex flex-col sm:flex-row gap-1 justify-center">
              <button onClick={AccountingAndBookkeeping} className="group flex mb-4 z-10 items-center justify-center gap-2 px-8 py-4 bg-[#3D498A] text-white font-semibold rounded-lg hover:bg-[#3d498a8f] transition-all duration-300 transform hover:-translate-y-1">
                Explore Services
                <BsChevronRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
              </button>

              <a href="/contact">
                <button className="flex items-center z-10 mb-4 justify-center gap-2 px-8 py-4 bg-[#3d498a3d] border-solid border-2 border-white hover:border-[#3d498a3d] text-white font-semibold rounded-lg hover:bg-[#3d498a] transition-all duration-300 transform hover:-translate-y-1">
                  Get Started
                  <BsChevronRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
                </button>
              </a>
            </div>

            {/* <div className="flex space-x-5 justify-center items-center">
              <button className="bg-[#3D498A] border-2 rounded-[8px] border-[#3D498A] text-white font-medium px-4 py-3 flex items-center space-x-2">
                <span>Explore Services</span> <BsChevronRight />
              </button>
              <button className="bg-transparent border-2 rounded-[8px] border-[#3D498A] text-white font-medium px-4 py-3 flex items-center space-x-2">
                <span>Get Started</span> <BsChevronRight />
              </button>
            </div> */}

          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
