import React from 'react'
import Hero from './Hero'
import Mainland from './Mainland'
import Freezone from './Freezone'
import Offshore from './Offshore'
import Contact from './Contact'

function CompanyFormation() {
  return (
  <>
    <Hero/>
    <Mainland/>
    <Freezone/>
    <Offshore/>
    <Contact/>
  </>
  )
}

export default CompanyFormation