import React from 'react';

const CorporateTaxCard = () => {
  return (
    <div className="flex justify-center items-center h-50vh bg-gray-100 p-4">
      <div className="bg-white rounded-xl shadow-lg w-full max-w-6xl overflow-hidden transform transition-all hover:shadow-xl">
        <div className="p-8">
          <div className="space-y-6">
            {/* Title Section */}
            <h2 className="text-center text-2xl font-bold text-[#3D498A] tracking-wide">
              CORPORATE TAX, TAX IN OFFSHORE, AND PORT TAX
            </h2>
            
            {/* Content Section */}
            <p className="text-black text-lg leading-relaxed text-center">
              UAE are exempt from corporate taxes, provided they conduct activities outside the UAE. 
              Additionally, offshore entities enjoy exemptions from port taxes and customs duties, 
              further enhancing their cost efficiency.
            </p>
          </div>
          
          {/* Optional Decorative Line */}
          <div className="mt-6 border-t border-gray-100"></div>
        </div>
      </div>
    </div>
  );
};

export default CorporateTaxCard;