import React from 'react'
import Hero from './Hero';
import Services from './Services';
import Document from './Document';
import Form from '../../../Components/Form/Form23then135';

function corporatebankaccountopening() {
  return (
    <div>
      <Hero />
      <Services />
      <Document />
      <Form />
    </div>

  )
}

export default corporatebankaccountopening;