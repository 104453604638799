import React, { useEffect, useRef,useState } from 'react';
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import '../../css/style.css';

const HeaderWithVideoBackground = () => {
  const videoRef = useRef(null);
  const [showOverlay, setShowOverlay] = useState(false);
  useEffect(() => {
    AOS.init({ duration: 1000 });
    const video = videoRef.current;
    if (video) {
      video.load();
      const playPromise = video.play();

      if (playPromise !== undefined) {
        playPromise.catch(error => {
          console.log("Playback prevented:", error);
        });
      }
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => setShowOverlay(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const handleNav = () => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  };

  return (
    <div
      className="montserrat relative min-h-[85vh] min-w-full flex justify-center items-center container mx-auto "
    
    >
      <div className="mx-auto max-w-[1640px] p-[1rem] lg:p-[2rem] ">
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          autoPlay
          playsInline
          muted
          loop
          preload="auto"
          ref={videoRef}
          disablePictureInPicture
          style={{
            WebkitUserSelect: 'none',
            WebkitTouchCallout: 'none',
            WebkitTapHighlightColor: 'transparent',
            outline: 'none',
          }}
        >
          <source src='https://rightone.ae//Assets/video/video.mp4' type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <img src="https://rightone.ae/Assets/Hero/homebg1.jpg" alt="background" loading='lazy' className='absolute z-[-10] top-0 left-0 w-full h-[85vh] object-fill'/>
        {/* Overlay for darkening the background */}
        {/* src="https://rightone.ae/Assets/Hero/homebg.jpg" */}
        {showOverlay &&  <div className="absolute inset-0 bg-black bg-opacity-50"></div> }

        <div
          className="relative md:w-[90%] lg:w-[75%]  mx-auto z-10 text-center text-[#F8F5F0]"
          data-aos="fade-up"
        >
          <h1
            className="font-bold"
            style={{ fontSize: "clamp(28px,3vw,50px)" }}
          >
            Effortless{" "}
            <span className="border-b-[5px] border-yellow-500">
              {" "}
              Business
            </span>{" "}
              Setup in the UAE
          </h1>
          <h2
            className="mt-4 font-bold"
            style={{ fontSize: "clamp(18px,1.5vw,30px)" }}
          >
            100% Ownership Guaranteed
          </h2>
          <p
            className="mt-4 font-medium"
            style={{ fontSize: "clamp(18px,1.5vw,30px)" }}
          >
            Seamless company formation with lifetime visa and bank account assistance.
          </p>
          <Link to="/CostCalculator" onClick={handleNav}>
            <button
              onClick={handleNav}
              className="mt-6 px-8 py-4 text-3xl border-spacing-2 rounded-lg text-white font-bold shadow-lg 
                 transition-all duration-300 ease-in-out
                 hover:scale-110
                 relative
                 group"
              style={{
                background: "linear-gradient(90deg, #3D498A 0%, #5C6C8A 100%)",
              }}
            >
              <span
                className="absolute inset-0 rounded-md 
                       opacity-0 group-hover:opacity-100
                       transition-opacity duration-300
                       pointer-events-none
                       shadow-[0_0_10px_3px_rgba(255,255,255,0.3)]"
                style={{
                  background:
                    "linear-gradient(90deg, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0.1) 100%)",
                  border: "2px solid transparent",
                  borderImage:
                    "linear-gradient(90deg, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0.5) 100%) 1",
                }}
              />
              <span
                className="absolute inset-0 rounded-md 
                       opacity-0 group-hover:opacity-100
                       transition-opacity duration-600
                       pointer-events-none
                       blur-md"
                style={{
                  background:
                    "linear-gradient(90deg, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0.1) 100%)",
                  boxShadow: "0 0 15px 5px rgba(255,255,255,0.4)",
                }}
              />
              Cost Calculator
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HeaderWithVideoBackground;
