import React from "react";
import {
  ArrowRight,
  ClipboardCheck,
  ShoppingBag,
  Building,
} from "lucide-react";
// import img4 from "../../../../public/Assets/CompanyFormation/DesignatedFreezones.jpeg";

const IntroductionCards = () => {
  const img1 = `${process.env.REACT_APP_DOMAIN}/Assets/CompanyFormation/CommercialLicenseCardFreezone.webp`;
  const img2 = `${process.env.REACT_APP_DOMAIN}/Assets/CompanyFormation/ServiceLicenseFreezone.webp`;
  const img3 = `${process.env.REACT_APP_DOMAIN}/Assets/CompanyFormation/MediaLicenseFreezone.webp`;
  const img4 = `${process.env.REACT_APP_DOMAIN}/Assets/CompanyFormation/DesignatedFreezones.jpeg`;
  const img5 = `${process.env.REACT_APP_DOMAIN}/Assets/CompanyFormation/DesignatedFreezones.jpeg`;

  const cards = [
    {
      title: "Commerial License in UAE Freezone",
      description:
        "A commercial license in a freezone is perfect for businesses engaged in trading, importing, and exporting goods. It enables companies to operate in a tax-efficient environment while accessing international markets seamlessly.",
      link: "https://wa.me/+971526919516",
      image: img1,
      icon: <ClipboardCheck className="w-10 h-10 text-gray-800" />,
    },
    {
      title: "Service License in UAE Freezone",
      description:
        "The service license is designed for businesses providing consultancy, IT services, or other professional services. This license is ideal for companies looking to establish a presence in the UAE's competitive service industry.",
      link: "https://wa.me/+971526919516",
      image: img2,
      icon: <ShoppingBag className="w-10 h-10 text-gray-800" />,
    },
    {
      title: "Media License in UAE Freezone",
      description:
        "A media license is tailored for businesses involved in advertising, publishing, " +
        "digital media, and creative industries. Freezones like Dubai Media City provide state-of-the-art " +
        "infrastructure for media-focused enterprises.",
      link: "https://wa.me/+971526919516",
      image: img3,
      icon: <Building className="w-10 h-10 text-gray-800" />,
    },
    {
      title: "Designated Freezones",
      description: `Designated freezones in the UAE are strategically located to
promote specific industries. They offer unique benefits such as tax exemptions and access to
key trade and logistics hubs.`,
      link: "https://wa.me/+971526919516",
      image: img4,
      icon: <Building className="w-10 h-10 text-gray-800" />,
    },
  ];

  return (
    <div className="container mx-auto px-4 py-16">
      <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {cards.map((card, index) => (
          <li
            key={index}
            className="bg-white rounded-lg shadow-lg hover:shadow-xl transition-all duration-300"
          >
            <div className="relative">
              <img
                src={card.image}
                alt={card.title}
                className="w-full h-48 object-cover rounded-t-lg"
              />
            </div>
            <div className="p-4 border border-gray-100 bg-[#F0F0F0] rounded-b-lg">
              <div className="flex items-center justify-center rounded p-1 space-x-2 mb-3">
                <h1 className="font-bold text-gray-800 hover:text-[#3D498A] transition-all cursor-pointer text-center text-base">
                  {card.title}
                </h1>
              </div>
              <p className="text-gray-700 text-sm text-justify mb-6">
                {card.description}
              </p>
              <a
                href={card.link}
                target="_blank"
                rel="noopener noreferrer"
                className="w-full bg-[#3D498A] hover:bg-[#3d498aa9] text-white py-3 px-4 rounded-lg font-semibold flex items-center justify-center space-x-2 transition-all duration-300 group"
              >
                <span>Consult Now</span>
                <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
              </a>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default IntroductionCards;
