import React, { useState } from 'react';
import { ChevronDown, ArrowRight } from 'lucide-react';

const Offshore = () => {
    const [openSection, setOpenSection] = useState(null);

    const services = [
        {
            title: 'Product Registration',
            description: 'We assist businesses in registering their products with relevant UAE authorities, ensuring compliance with local standards and regulations. Our services include preparing and submitting the required documentation, liaising with regulatory bodies for approvals, ensuring compliance with health, safety, and labeling standards, and providing support for product testing and certification.',
            icon: '📦'
        },
        {
            title: 'Company Liquidation',
            description: 'Closing a business in the UAE requires a structured approach. We provide complete support for company liquidation, including preparing necessary documents and approvals, settling outstanding liabilities and dues, canceling trade licenses and visas, and ensuring compliance with regulatory authorities.',
            icon: '🏢'
        },
        {
            title: 'Attestation Services',
            description: 'Ensure your documents are valid and recognized in the UAE with our attestation services. We assist with attesting educational certificates, attesting marriage and birth certificates, legalizing business documents, and coordination with the Ministry of Foreign Affairs and embassies.',
            icon: '📄'
        },
        {
            title: 'Dubai Custom Registration Services',
            description: 'We simplify the process of registering your business with Dubai Customs. Our services include preparing and submitting registration documents, securing customs codes and approvals, and assisting with duty exemptions and compliance requirements.',
            icon: '🛃'
        },
        {
            title: 'Government Approvals',
            description: 'Many businesses require specific approvals from government entities. We assist with securing permits and licenses for specialized activities, liaising with Dubai Municipality, RTA, and other authorities, and ensuring compliance with sector-specific regulations.',
            icon: '⚖️'
        },
        {
            title: 'Digital Marketing & Branding',
            description: 'Enhance your online presence with our expert marketing and branding services. We provide comprehensive social media marketing and search engine optimization (SEO) solutions to help your business grow its digital footprint.',
            icon: '💻'
        }
    ];

    const toggleSection = (index) => {
        setOpenSection(openSection === index ? null : index);
    };

    return (
        <div className="container mx-auto">
            <div className="w-full p-6 pt-14 space-y-6 bg-gray-50 rounded-xl">
                <div className="space-y-4">
                    {services.map((service, index) => (
                        <div
                            key={index}
                            className="transition-all duration-300"
                        >
                            <button
                                onClick={() => toggleSection(index)}
                                className="w-full rounded-xl bg-white shadow-sm hover:shadow-md transition-all duration-300 ease-in-out"
                            >
                                <div className="p-6 flex items-center justify-between">
                                    <div className="flex items-center gap-4">
                                        <span className="text-2xl">{service.icon}</span>
                                        <h3 className="font-semibold text-lg text-gray-900">{service.title}</h3>
                                    </div>
                                    <ChevronDown
                                        className={`w-5 h-5 text-gray-500 transform transition-transform duration-300
                                            ${openSection === index ? 'rotate-180' : ''}`}
                                    />
                                </div>

                                <div
                                    className={`overflow-hidden transition-all duration-300 ease-in-out
                                        ${openSection === index ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'}`}
                                >
                                    <div className="p-6 pt-0 text-gray-600 border-t">
                                        <p className="mb-4 text-left mt-4">{service.description}</p>
                                        <a
                                            href="https://wa.me/+971526919516"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="flex items-start gap-10 text-left align-left pr-4 py-2 text-black rounded-lg font-semibold transition-all duration-300 group"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                        >
                                            View Details
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 448 512"
                                                className="h-5 w-5 transition-transform group-hover:scale-110 fill-current"
                                            >
                                                <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Offshore;