import React from 'react';
import { ChevronRight } from 'lucide-react';


const Hero = () => {
  const img1 = `${process.env.REACT_APP_DOMAIN}/Assets/goldenVisa/goldenVisaImage1.jpg`

  const GoldenVisa = () => {
    setTimeout(() => {
      const GoldenVisaSection = document.getElementById('GoldenVisa')
      if (GoldenVisaSection){
        window.scrollTo({
          top: GoldenVisaSection.offsetTop - 100,
          behavior: 'smooth'
        })
      }
    })
  }

  return (
    <section className="relative min-h-screen w-full overflow-hidden">
      {/* Background Image Container */}
      <div 
        className="absolute inset-0 w-full h-full bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${img1})`,
        }}
      >
        {/* Sophisticated overlay with multiple gradients */}
        <div className="absolute inset-0 bg-gradient-to-r from-black/80 via-black/50 to-transparent" />
        <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/30 to-transparent" />
      </div>

      {/* Content Container */}
      <div className="relative h-screen flex items-center">
        <div className="container mx-auto px-4 md:px-8" data-aos="fade-up">
          <div className="max-w-4xl">
            {/* Decorative element */}
            <div className="w-24 h-1 bg-yellow-400 mb-8 transform -skew-x-12" />
            
            {/* Main heading with gradient text */}
            <h1 className="text-5xl md:text-7xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-yellow-200">
              Golden Visa
            </h1>
            
            {/* Subtitle */}
            <div className="inline-block bg-yellow-400/10 backdrop-blur-sm px-4 py-2 rounded-lg mb-8">
              <span className="text-yellow-400 font-semibold text-xl md:text-2xl">
                10 Years Residency Program
              </span>
            </div>

            {/* Description with improved typography */}
            <p className="text-lg md:text-xl text-gray-200 leading-relaxed mb-12 max-w-2xl">
              UAE offers long term residency to expats through the UAE Golden Visa program. 
              Individuals in the UAE who have the Dubai Golden Visa can avail long term 
              residency in the Emirate for up to 10 years.
            </p>

            {/* CTA Section */}
            <div className="flex flex-col sm:flex-row gap-6">
              <button onClick={GoldenVisa} className="group flex items-center justify-center gap-2 px-8 py-4 bg-yellow-400 text-black font-semibold rounded-lg hover:bg-yellow-300 transition-all duration-300 transform hover:-translate-y-1">
                Learn More
                <ChevronRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
              </button>
              
              <a href="/contact">
              <button className="flex items-center justify-center gap-2 px-8 py-4 border-2 border-yellow-400/30 text-yellow-400 font-semibold rounded-lg hover:bg-yellow-400/10 transition-all duration-300">
                Contact Us
              </button>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Decorative elements */}
      <div className="absolute bottom-0 left-0 w-full h-32 bg-gradient-to-t from-black/80 to-transparent" />
      <div className="absolute top-0 right-0 w-96 h-96 bg-yellow-400/10 rounded-full filter blur-3xl" />
    </section>
  );
};

export default Hero;