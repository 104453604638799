import React, { useState } from "react";
import { ArrowRight } from "lucide-react";

const CompanyFormation = [
  {
    name: "Freezone Advisory",
    path: "/company-formation/freezone-licensing-services",
    image: `${process.env.PUBLIC_URL}/Assets/CorporateServices/proservicecard.jpeg`,
    subServices: [
      {
        name: "Freezone Advisory",
        image: `${process.env.PUBLIC_URL}/Assets/CorporateServices/proservicecard.jpeg`,
        description: "Advising on the most suitable Freezone based on your business activity.",
      },
      {
        name: "Company Registration",
        image: `${process.env.PUBLIC_URL}/Assets/CorporateServices/proservicecard.jpeg`,
        description: "Company registration and license issuance.",
      },
      {
        name: "Trade Name",
        image: `${process.env.PUBLIC_URL}/Assets/CorporateServices/proservicecard.jpeg`,
        description: "Trade name reservation and approval.",
      },
      {
        name: "Legal Documents",
        image: `${process.env.PUBLIC_URL}/Assets/CorporateServices/proservicecard.jpeg`,
        description: "Drafting and attesting legal documents.",
      },
      {
        name: "Office Setup",
        image: `${process.env.PUBLIC_URL}/Assets/CorporateServices/proservicecard.jpeg`,
        description: "Assistance with physical office space or virtual office setup.",
      },
      {
        name: "Visa Processing",
        image: `${process.env.PUBLIC_URL}/Assets/CorporateServices/proservicecard.jpeg`,
        description: "Visa processing and establishment card issuance.",
      },
      {
        name: "Compliance Renewal",
        image: `${process.env.PUBLIC_URL}/Assets/CorporateServices/proservicecard.jpeg`,
        description: "Compliance and annual renewals.",
      },
      {
        name: "Warehouse Support",
        image: `${process.env.PUBLIC_URL}/Assets/CorporateServices/proservicecard.jpeg`,
        description: "Support for obtaining warehouse and storage facilities.",
      },
      {
        name: "Conversion Advice",
        image: `${process.env.PUBLIC_URL}/Assets/CorporateServices/proservicecard.jpeg`,
        description: "Advice on Freezone-to-Mainland company conversions.",
      },
      {
        name: "Networking Access",
        image: `${process.env.PUBLIC_URL}/Assets/CorporateServices/proservicecard.jpeg`,
        description: "Access to business networking opportunities within Freezone communities.",
      },
    ],
  },
];

const Freezone = () => {
  const [selectedSubService, setSelectedSubService] = useState(
    CompanyFormation[0].subServices[0]
  );

  return (
    <div className="container mx-auto">
      <div className="">
        {/* Below section */}
        <div className="bg-gradient-to-b from-white to-gray-50">
          {/* Title Section */}
          <div className="py-8 md:py-12 px-4">
            <div className="max-w-5xl mx-auto">
              <div className="relative">
                <div className="absolute inset-0 blur-lg bg-gradient-to-r from-blue-100/50 to-purple-100/50"></div>
                <h1 className="relative text-2xl md:text-4xl lg:text-5xl text-center text-[#3D498A] font-bold">
                  Top Rated {CompanyFormation[0].name} Services
                </h1>
                <p className="mb-2 mt-3 mx-auto lg:text-center text-center lg:text-lg">
                  Freezone Licensing Services provides expert guidance on Freezone selection, company registration, trade name approval, legal documentation, office setup, visa processing, renewals, compliance, and support for business growth within Freezone communities.
                </p>
              </div>
              <div className="w-20 h-1 bg-[#3D498A] mx-auto mt-4 rounded-full"></div>
            </div>
          </div>

          {/* Sub Services and Content Section */}
          <div className="max-w-7xl mx-auto px-4 pb-16">
            {/* Mobile View: Stacked Cards */}
            <div className="md:hidden grid gap-6">
              {CompanyFormation[0].subServices.map((subService, index) => (
                <div
                  key={index}
                  className="group relative rounded-xl overflow-hidden h-[320px] shadow-lg transform transition-all duration-300 hover:scale-102 hover:shadow-xl"
                >
                  <div
                    className="absolute inset-0 bg-cover bg-center transition-transform duration-700 group-hover:scale-110"
                    style={{ backgroundImage: `url(${subService.image})` }}
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-[#3D498A]/90 to-black/50" />
                  <div className="relative h-full p-6 flex flex-col justify-end">
                    <div className="transform transition-all duration-300 group-hover:-translate-y-2">
                      <h3 className="text-xl md:text-2xl font-bold text-white mb-3">
                        {subService.name}
                      </h3>
                      <p className="text-white/90 text-sm line-clamp-3 leading-relaxed mb-4">
                        {subService.description}
                      </p>
                      <a
                        href="https://wa.me/+971526919516"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center justify-center space-x-2 bg-white text-[#3D498A] px-6 py-2 rounded-lg font-medium hover:bg-opacity-90 transition-all duration-300 group"
                      >
                        <span>Consult Now</span>
                        <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Desktop View */}
            <div className="hidden md:flex gap-8 bg-white rounded-2xl shadow-xl p-6">
              {/* Sidebar Navigation */}
              <div className="w-1/3 max-w-xs">
                <div className="border-l-2 border-gray-100">
                  <ul className="space-y-2 max-h-[500px] overflow-y-auto pr-4 scrollbar-hide">
                    {CompanyFormation[0].subServices.map((subService, index) => (
                      <li
                        key={index}
                        onClick={() => setSelectedSubService(subService)}
                        className={`
                          group cursor-pointer rounded-lg p-4 transition-all duration-300
                          ${selectedSubService.name === subService.name
                            ? 'bg-[#3D498A]/10 text-[#3D498A]'
                            : 'hover:bg-gray-50'
                          }
                        `}
                      >
                        <div className="flex items-center gap-3">
                          <span
                            className={`font-medium ${selectedSubService.name === subService.name
                              ? 'font-bold'
                              : ''
                              }`}
                          >
                            {subService.name}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              {/* Main Content */}
              <div className="w-2/3">
                <div className="relative rounded-xl overflow-hidden h-[500px] shadow-lg transform transition-all duration-500">
                  <div
                    className="absolute inset-0 bg-cover bg-center transition-transform duration-700"
                    style={{ backgroundImage: `url(${selectedSubService.image})` }}
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-[#3D498A]/90 via-black/50 to-transparent" />
                  <div className="relative h-full p-8 flex flex-col justify-end">
                    <div className="max-w-xl">
                      <h3 className="text-3xl font-bold text-white mb-4">
                        {selectedSubService.name}
                      </h3>
                      <p className="text-white/90 text-lg leading-relaxed mb-6">
                        {selectedSubService.description}
                      </p>
                      <a
                        href="https://wa.me/+971526919516"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center justify-center space-x-2 bg-white text-[#3D498A] px-8 py-3 rounded-lg font-medium hover:bg-opacity-90 transition-all duration-300 group"
                      >
                        <span>Consult Now</span>
                        <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Freezone;