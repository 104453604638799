import React, { useState } from 'react';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

const CallbackForm = () => {
  const [formData, setFormData] = useState({
    topic: 'Free Zone Company',
    name: '',
    email: '',
    phone: '',
    country: 'AE'
  });

  const [status, setStatus] = useState({
    submitting: false,
    msg: '',
    type: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset previous status
    setStatus({
      submitting: true,
      msg: 'Sending...',
      type: ''
    });

    try {
      const formDataToSend = new FormData();
      Object.keys(formData).forEach(key => {
        formDataToSend.append(key, formData[key]);
      });

      const response = await fetch("/send_email_one_dropdown.php", {
        method: 'POST',
        body: formDataToSend
      });

      if (!response.ok) {
        throw new Error("Submission failed");
      }

      const result = await response.json();

      // Update status based on server response
      setStatus({
        submitting: false,
        msg: result.message || "Request sent successfully!",
        type: result.status
      });

      // Clear form if submission was successful
      if (result.status === 'success') {
        setFormData({
          topic: 'Free Zone Company',
          name: '',
          email: '',
          phone: '',
          country: 'AE'
        });
      }
    } catch (error) {
      setStatus({
        submitting: false,
        msg: "Failed to send request. Please try again.",
        type: 'error'
      });
    }
  };

  return (
    <>
      <div className='container mx-auto'>
        <div className=" bg-white flex items-center justify-center mb-16 " data-aos="fade-up">
          <div className="max-w-4xl w-full">
            <div className="mb-8">
              <h2 className="text-2xl md:text-3xl lg:text-6xl font-bold text-center text-[#3D498A] mb-4" style={{ fontSize: "clamp(20px,2.5vw,180px)" }}>
                Request a Callback for Business Setup Consultation
              </h2>
              <div className=" md:w-72 w-[120px] h-1 bg-[#3D498A] mx-auto"></div>
              <p className="text-gray-600 mx-5 text-base mt-10 text-center">
                Would you like to speak with one of our expert Business Setup Consultants in the UAE? Submit your details, and we'll reach out to you shortly. Alternatively, you can email us if that's more convenient
              </p>
            </div>

            {status.msg && (
              <div className={`mb-4 mx-4 p-3 rounded text-center ${status.type === 'success'
                  ? 'bg-green-100 text-green-800'
                  : 'bg-red-100 text-red-800'
                }`}>
                {status.msg}
              </div>
            )}

            <form onSubmit={handleSubmit}>
              <div className="text-gray-700 mb-2 mx-8 text-justify">I would like to discuss about:</div>
              <div className="bg-[#3D498A] p-6 rounded-lg space-y-4 text-[16px] mx-4 ">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <select
                    name="topic"
                    value={formData.topic}
                    onChange={handleChange}
                    className="w-full h-12 px-3 rounded bg-transparent text-white border border-white/30 focus:outline-none focus:ring-2 focus:ring-white/50 appearance-none cursor-pointer"
                    required
                  >
                    <option value="Free Zone Company">Free Zone Company</option>
                    <option value="Mainland Company">Mainland Company</option>
                    <option value="LLC Company">LLC Company</option>
                    <option value="Product Registration">Product Registration</option>
                    <option value="Offshore Company">Offshore Company</option>
                    <option value="PRO Services">PRO Services</option>
                    <option value="Bank Services">Bank Services</option>
                    <option value="Visa Services">Visa Services</option>
                    <option value="Others">Others</option>
                  </select>
                  <input
                    type="text"
                    name="name"
                    placeholder="Your Name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full h-12 px-3 rounded bg-transparent text-white placeholder-gray-300 border border-white/30 focus:outline-none focus:ring-2 focus:ring-white/50"
                    required
                  />

                  <div className="h-12">
                    <PhoneInput
                      defaultCountry="ae"
                      value={formData.phone}
                      onChange={phone => setFormData(prevData => ({ ...prevData, phone }))}
                      inputClassName="w-full h-12 px-3 rounded bg-transparent text-white placeholder-gray-300 border border-white/30 focus:outline-none focus:ring-2 focus:ring-white/50"
                      containerClassName="w-full h-12"
                      buttonClassName="h-12 bg-transparent border border-white/30 text-white"
                      dropdownClassName="bg-[#3D498A] text-white"
                    />
                  </div>
                </div>
                <input
                  type="email"
                  name="email"
                  placeholder="Your email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full h-12 px-3 rounded bg-transparent text-white placeholder-gray-300 border border-white/30 focus:outline-none focus:ring-2 focus:ring-white/50"
                  required
                />
                <button
                  type="submit"
                  disabled={status.submitting}
                  className="w-full md:w-auto bg-white text-[#3D498A] px-8 h-12 rounded hover:bg-gray-100 transition-colors duration-200 font-semibold disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {status.submitting ? 'SUBMITTING...' : 'SUBMIT'}
                </button>
              </div>
              <style jsx global>{`
                .react-international-phone-input-container .react-international-phone-country-selector-button {
                  background-color: transparent !important;
                  border-color: rgba(255, 255, 255, 0.3) !important;
                  height: 48px !important;
                }
                .react-international-phone-input {
                  background-color: transparent !important;
                  color: white !important;
                  height: 48px !important;
                }
                .react-international-phone-country-selector-dropdown {
                  background-color: white !important;
                  color: black !important;
                }
                .react-international-phone-country-selector-dropdown-item:hover {
                  background-color: rgba(255, 255, 255, 0.1) !important;
                }
                ::placeholder {
                  color: #B8B9BE !important;
                }
                select {
                  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
                  background-position: right 0.5rem center;
                  background-repeat: no-repeat;
                  background-size: 1.5em 1.5em;
                }
                select option {
                  background-color: #3D498A;
                  color: white;
                }
              `}</style>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CallbackForm;