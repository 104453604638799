import React from 'react';

const ClientLogos = () => {
  const logos = [
    "https://rightone.ae/Assets/Logos/Logo1.png",
    "https://rightone.ae/Assets/Logos/Logo2.jpg",
    "https://rightone.ae/Assets/Logos/Logo3.png",
    "https://rightone.ae/Assets/Logos/Logo4.png",
    "https://rightone.ae/Assets/Logos/Logo5.png",
    "https://rightone.ae/Assets/Logos/Logo6.png",
    "https://rightone.ae/Assets/Logos/Logo7.jpg",
    "https://rightone.ae/Assets/Logos/Logo8.png",
    "https://rightone.ae/Assets/Logos/Logo9.png",
    "https://rightone.ae/Assets/Logos/Logo10.png",
    "https://rightone.ae/Assets/Logos/Logo11.png",
    "https://rightone.ae/Assets/Logos/Logo12.png",
    "https://rightone.ae/Assets/Logos/Logo13.png",
    "https://rightone.ae/Assets/Logos/Logo22.jpg",
    "https://rightone.ae/Assets/Logos/Logo23.jpg",
    "https://rightone.ae/Assets/Logos/Logo24.webp",
    "https://rightone.ae/Assets/Logos/Logo25.jpg",
    "https://rightone.ae/Assets/Logos/Logo26.png",
    "https://rightone.ae/Assets/Logos/Logo27.jpg",
    "https://rightone.ae/Assets/Logos/Logo28.jpeg",
    "https://rightone.ae/Assets/Logos/Logo29.jpg",
    "https://rightone.ae/Assets/Logos/Logo30.png",
    "https://rightone.ae/Assets/Logos/Logo31.png",
    "https://rightone.ae/Assets/Logos/Logo33.jpeg"
  ];
  

  const firstHalf = logos.slice(0, logos.length / 2);
  const secondHalf = logos.slice(logos.length / 2);

  return (
    <div className="bg-white py-16 container mx-auto">
      <div className="text-center mb-12" data-aos="fade-up">
        <h2
          className="text-4xl font-bold text-[#3D498A] mb-10 leading-tight" style={{ fontSize: "clamp(20px,2.5vw,180px)" }}
        >
          Authorized by UAE’s Leading Government Authorities for Business Setup"
        </h2>

        <div className="md:w-72 w-[120px] h-1 bg-[#3D498A] mx-auto"></div>
        <p className="text-blue-900 text-lg mt-10">
        Right One Corporate Services empowers your business with seamless government collaborations, ensuring compliance and success in the UAE market.
        </p>
      </div>
      <div className="relative overflow-hidden pt-6">
        <div className="flex gap-8 animate-scroll">
          {[...firstHalf, ...firstHalf].map((logo, index) => (
            <div key={index} className="w-40 flex-shrink-0 flex items-center justify-center">
              <img
                src={logo}
                alt={`Client Logo ${index + 1}`}
                className="w-full h-auto object-contain"
              />
            </div>
          ))}
        </div>
      </div>
      <div className="relative overflow-hidden pt-6 mb-8">
        <div className="flex gap-8 animate-scroll-reverse">
          {[...secondHalf, ...secondHalf].map((logo, index) => (
            <div key={index} className="w-40 flex-shrink-0 flex items-center justify-center">
              <img
                src={logo}
                alt={`Client Logo ${index + 13}`}
                className="w-full h-auto object-contain"
              />
            </div>
          ))}
        </div>
      </div>
      <style jsx="true">{`
        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-100%);
          }
        }

        @keyframes scroll-reverse {
          0% {
            transform: translateX(-100%);
          }
          100% {
            transform: translateX(0);
          }
        }

        .animate-scroll {
          display: flex;
          animation: scroll 25s linear infinite;
        }

        .animate-scroll-reverse {
          display: flex;
          animation: scroll-reverse 25s linear infinite;
        }
      `}</style>
    </div>
  );
};

export default ClientLogos;