import React from 'react'
import AboutSection from './AboutSection'
import QualitiesSection from './QualitiesSection'
import VisionMission from './VisionMissionSection'
import WhatSetsUsApartSection from './WhatSetsUsApartSection'

function About() {
  return (
  <>
  <AboutSection/>
  <WhatSetsUsApartSection/>
  <VisionMission/>
  <QualitiesSection/>
  </>
  )
}

export default About
