import React, { useState } from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

const CallbackForm = () => {
  const [formData, setFormData] = useState({
    topic: "Free Zone Company",
    name: "",
    email: "",
    phone: "",
    jurisdiction: "",
    premises: "",
    shareholders: "",
    visas: "",
    country: "AE",
  });

  const [status, setStatus] = useState({ 
    submitting: false, 
    msg: '',
    type: '' 
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Reset previous status
    setStatus({ 
      submitting: true, 
      msg: 'Sending...', 
      type: '' 
    });

    try {
      const formDataToSend = new FormData();
      Object.keys(formData).forEach(key => {
        // Only append non-empty values
        if (formData[key]) {
          formDataToSend.append(key, formData[key]);
        }
      });

      const response = await fetch("/send_email_homecostcalculator.php", {
        method: 'POST',
        body: formDataToSend
      });

      if (!response.ok) {
        throw new Error("Submission failed");
      }

      const result = await response.json();

      // Update status based on server response
      setStatus({
        submitting: false,
        msg: result.message || "Request sent successfully!",
        type: result.status
      });

      // Clear form if submission was successful
      if (result.status === 'success') {
        setFormData({
          topic: "Free Zone Company",
          name: "",
          email: "",
          phone: "",
          jurisdiction: "",
          premises: "",
          shareholders: "",
          visas: "",
          country: "AE",
        });
      }
    } catch (error) {
      setStatus({ 
        submitting: false, 
        msg: "Failed to send request. Please try again.", 
        type: 'error' 
      });
    }
  };

  return (
    <>
      <div className="container mx-auto">
        <div className="bg-white flex items-center justify-center mb-16" data-aos="fade-up">
          <div className="max-w-4xl w-full">
            <div className="mb-8">
              <h2 className="text-4xl font-bold text-[#3D498A] text-center mb-8" style={{ fontSize: "clamp(25px,3vw,200px)" }}>
                Cost Calculator
              </h2>
              <div className="md:w-72 w-[120px] h-1 bg-[#3D498A] mx-auto"></div>

              <p className="text-gray-600 mx-5 text-base mt-10 text-center">
                Ready to Calculate Your UAE Business Setup Cost? <br />
                Enter your details below to receive an instant estimate for your business setup, covering company formation, licensing, visa, and more.
              </p>
            </div>

            {status.msg && (
              <div className={`mb-4 mx-4 p-3 rounded text-center ${
                status.type === 'success' 
                  ? 'bg-green-100 text-green-800' 
                  : 'bg-red-100 text-red-800'
              }`}>
                {status.msg}
              </div>
            )}

            <form onSubmit={handleSubmit}>
              <div className="text-gray-700 mb-2 mx-8 text-justify">
                I would like to discuss about:
              </div>
              <div className="bg-[#3D498A] p-6 rounded-lg space-y-4 text-[16px] mx-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <select
                    name="topic"
                    value={formData.topic}
                    onChange={handleChange}
                    className="w-full h-12 px-3 rounded bg-transparent text-white border border-white/30 focus:outline-none focus:ring-2 focus:ring-white/50 appearance-none cursor-pointer"
                    required
                  >
                    <option value="Free Zone Company">Choose Business Activity</option>
                    <option value="Mainland Company">Mainland Company</option>
                    <option value="LLC Company">LLC Company</option>
                    <option value="Free Zone Company">Free Zone Company</option>
                    <option value="Product Registration">Product Registration</option>
                    <option value="Offshore Company">Offshore Company</option>
                    <option value="PRO Services">PRO Services</option>
                    <option value="Bank Services">Bank Services</option>
                    <option value="Visa Services">Visa Services</option>
                    <option value="Others">Others</option>
                  </select>

                  <div className="h-12">
                    <PhoneInput
                      defaultCountry="ae"
                      value={formData.phone}
                      onChange={(phone) => setFormData((prevData) => ({ ...prevData, phone }))}
                      inputClassName="w-full h-12 px-3 rounded bg-transparent text-white placeholder-gray-300 border border-white/30 focus:outline-none focus:ring-2 focus:ring-white/50"
                      containerClassName="w-full h-12"
                      buttonClassName="h-12 bg-transparent border border-white/30 text-white"
                      dropdownClassName="bg-[#3D498A] text-white"
                    />
                  </div>
                </div>

                <input
                  type="text"
                  name="name"
                  placeholder="Your good name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full h-12 px-3 rounded bg-transparent text-white placeholder-gray-300 border border-white/30 focus:outline-none focus:ring-2 focus:ring-white/50"
                  required
                />

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <select
                    name="jurisdiction"
                    value={formData.jurisdiction}
                    onChange={handleChange}
                    className="w-full h-12 px-3 rounded bg-transparent text-white border border-white/30 focus:outline-none focus:ring-2 focus:ring-white/50 appearance-none cursor-pointer"
                  >
                    <option value="">Jurisdiction</option>
                    <option value="Mainland">Mainland</option>
                    <option value="Free Zone">Free Zone</option>
                    <option value="Offshore">Offshore</option>
                  </select>

                  <select
                    name="premises"
                    value={formData.premises}
                    onChange={handleChange}
                    className="w-full h-12 px-3 rounded bg-transparent text-white border border-white/30 focus:outline-none focus:ring-2 focus:ring-white/50 appearance-none cursor-pointer"
                  >
                    <option value="">Premises</option>
                    <option value="Office">Office</option>
                    <option value="Virtual Office">Virtual Office</option>
                    <option value="Warehouse">Warehouse</option>
                  </select>
                </div>

                <input
                  type="email"
                  name="email"
                  placeholder="Your email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full h-12 px-3 rounded bg-transparent text-white placeholder-gray-300 border border-white/30 focus:outline-none focus:ring-2 focus:ring-white/50"
                  required
                />

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <select
                    name="shareholders"
                    value={formData.shareholders}
                    onChange={handleChange}
                    className="w-full h-12 px-3 rounded bg-transparent text-white border border-white/30 focus:outline-none focus:ring-2 focus:ring-white/50 appearance-none cursor-pointer"
                  >
                    <option value="">Number of shareholders</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3+">3 or more</option>
                  </select>

                  <select
                    name="visas"
                    value={formData.visas}
                    onChange={handleChange}
                    className="w-full h-12 px-3 rounded bg-transparent text-white border border-white/30 focus:outline-none focus:ring-2 focus:ring-white/50 appearance-none cursor-pointer"
                  >
                    <option value="">Number of Visas</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3+">3 or more</option>
                  </select>
                </div>

                <button
                  type="submit"
                  disabled={status.submitting}
                  className="w-full md:w-auto bg-white text-[#3D498A] px-8 h-12 rounded hover:bg-gray-100 transition-colors duration-200 font-semibold disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {status.submitting ? 'SUBMITTING...' : 'SUBMIT'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CallbackForm;