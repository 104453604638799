import React from "react";
import { FaSquareWhatsapp } from "react-icons/fa6";
import { ArrowRight } from "lucide-react";
import b1 from '../../Assests/img/blog-pdf/b1.pdf'
import b2 from '../../Assests/img/blog-pdf/b2.pdf'
import b3 from '../../Assests/img/blog-pdf/b3.pdf'
import b4 from '../../Assests/img/blog-pdf/b4.pdf'
import b5 from '../../Assests/img/blog-pdf/b5.pdf'
import b6 from '../../Assests/img/blog-pdf/b6.pdf'
import b7 from '../../Assests/img/blog-pdf/b7.pdf'

const Carousal = () => {
  // Sample data for mapping
  const carouselData = [
    {
      id: 1,
      image: 'https://rightone.ae/Assets/goldenVisa/GoldenVisa.jpg',
      heading: "How to Get a UAE Golden Visa in 2025: Eligibility & Process Explained",
      description: "The UAE Golden Visa is a long-term residence visa designed to attract investors, entrepreneurs, skilled professionals, and exceptional talents to the UAE. In 2025, obtaining a Golden Visa has become more streamlined, making it easier for eligible individuals to secure their future in the UAE. If you're considering applying, this guide will help you understand the eligibility requirements and step-by-step application process.",
      pdf: b1
    },
    {
      id: 2,
      image: "https://rightone.ae/Assets/CompanyFormation/MainlandCard.webp",
      heading: "The Ultimate Guide to Company Formation in Dubai Mainland",
      description: "Dubai is one of the most sought-after business hubs in the world, offering excellent opportunities for entrepreneurs and investors. Setting up a business in Dubai Mainland comes with numerous benefits, such as access to a thriving economy, no currency restrictions, and the ability to trade freely across the UAE. At Right One Corporate Services, we specialize in making the company formation process seamless and hassle-free for our clients.",
      pdf: b2
    },
    {
      id: 3,
      image: "https://rightone.ae/Assets/CorporateServices/CorporateServices3.jpg",
      heading: "Unlock Business Success in Dubai with Right One Corporate Services",
      description: "Dubai has emerged as a global business hub, attracting entrepreneurs and investors from around the world. With its strategic location, business-friendly policies, and a thriving economy, setting up a company in Dubai is a lucrative opportunity. However, navigating the legal formalities and documentation can be challenging. This is where Right One Corporate Services comes in – your trusted partner in PRO services, company formation, free zone attestation, and Golden Visa assistance.",
      pdf: b3
    },
    {
      id: 4,
      image: "https://rightone.ae/Assets/goldenVisa/Benefits.jpeg",
      heading: "How to Get a UAE Golden Visa in 2025: Eligibility & Process Explained",
      description: "The UAE Golden Visa is a long-term residence visa designed to attract investors, entrepreneurs, skilled professionals, and exceptional talents to the UAE. In 2025, obtaining a Golden Visa has become more streamlined, making it easier for eligible individuals to secure their future in the UAE. If you're considering applying, this guide will help you understand the eligibility requirements and step-by-step application process.",
      pdf: b4
    },
    {
      id: 5,
      image: "https://rightone.ae/Assets/CompanyFormation/CommercialCard.webp",
      heading: "Top 10 Business Activities to Start in Dubai Mainland",
      description: "Dubai offers a thriving business environment with numerous opportunities for entrepreneurs. Setting up a business in Dubai Mainland provides access to a larger market, fewer trade restrictions, and the ability to work with government entities. Here are the top ten business activities to consider when starting your venture.",
      pdf: b5
    },
    {
      id: 6,
      image: "https://rightone.ae/Assets/FlipCard/Freezonelicense2.jpg",
      heading: "Best Free Zones in the UAE for 100% Foreign Ownership",
      description: "The UAE is one of the world’s leading business hubs, offering entrepreneurs and investors a wide range of opportunities. One of the most attractive aspects of setting up a business in the UAE is the availability of free zones, which allow 100% foreign ownership, full repatriation of profits, and tax exemptions. With over 40 free zones across the country, choosing the right one for your business can be challenging. In this blog, we will highlight some of the best free zones in the UAE for 100% foreign ownership.",
      pdf: b6
    },
    {
      id: 7,
      image: "https://rightone.ae/Assets/CorporateServices/CorporateServices9.jpg",
      heading: "How to Liquidate a Company in Dubai: Legal Process and Fees",
      description: "Liquidating a company in Dubai involves a structured legal process to ensure compliance with government regulations. Whether your business is in a free zone or mainland, following the correct procedures is essential to avoid legal complications. Here’s a step-by-step guide to company liquidation in Dubai.",
      pdf: b7
    }
  ];

  // Function to handle PDF preview
  const handlePreview = (pdfPath) => {
    const link = document.createElement('a');
    link.href = pdfPath;
    link.target = '_blank';
    link.click();
  };

  return (
    <div className="container mx-auto">
      <div className="grid md:grid-cols-3 gap-10 p-3 mt-8">
        {carouselData.map((item) => (
          <div key={item.id} className="shadow-xl p-2 rounded-[20px] transition-all hover:scale-95 flex flex-col h-full">
            {/* Image Section */}
            <div>
              <img
                className="max-h-[250px] w-full object-fit"
                src={item.image}
                alt=""
              />
            </div>

            {/* Content Section */}
            <div className="mt-4">
              <hr />
              <p className="text-[#3D498A] m-3">{item.date}</p>
              <h2 className="m-3 text-[#3D498A] font-montserrat text-[16px] font-extrabold leading-[26px] uppercase">
                {item.heading}
              </h2>
              <p className="ml-3 mb-3 text-[#666]">
                {item.description}
              </p>
            </div>

            {/* Button Section - Always at bottom */}
            <div className="mt-auto p-3">
              <div className="flex flex-row justify-between">
                {/* Read More Button */}
                <button
                  onClick={() => handlePreview(item.pdf)}
                  className="px-6 py-3 rounded-full bg-white text-[#3D498A] font-semibold text-lg shadow-lg hover:scale-105 transition-transform flex items-center gap-2"
                >
                  Read More
                  <ArrowRight className="w-5 h-5" />
                </button>

                {/* WhatsApp Button */}
                <a
                  href="https://wa.me/+971526919516"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="px-6 py-3 rounded-full bg-white text-[#3D498A] font-semibold text-lg shadow-lg hover:scale-105 transition-transform flex items-center gap-2"
                >
                  WhatsApp
                  <FaSquareWhatsapp className="w-5 h-5" />
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousal;