import React from "react";
import Flippy, { FrontSide, BackSide } from "react-flippy";
import { TiTick } from "react-icons/ti";
import { ArrowRight } from "lucide-react";

const FlipCards = () => {
  const cards = [
    {
      image: "https://rightone.ae/Assets/FlipCard/Mainlandlicense.jpg",
      title: "MAINLAND LICENSE",
      flipTitle: "Dubai Mainland Business Setup",
      subTitle: "Starting from just",
      price: "AED 11,000*",
      description1: "100% Business Ownership in Dubai",
      description2: "Investor Visa Available for UAE Residents",
      description3: "All-Inclusive Company Formation Services",
      description4: "Corporate Banking Assistance in UAE",
      description5: "Free VAT Registration Consultation",
      description6: "License Approval in 24 Hours",
      buttonLabel: "Start Your Business Now"
    },

    {
      image: "https://rightone.ae/Assets/FlipCard/Freezonelicense2.jpg",
      title: "FREEZONE LICENSE",
      flipTitle: "UAE Free Zone Company Setup",
      subTitle: "Starting from just",
      price: "AED 14,900*",
      description1: "IFZA & RAKEZ Freezone Options",
      description2: "Visa Assistance for Employees & Investors",
      description3: "Seamless Business Setup in UAE Free Zones",
      description4: "Corporate Banking Support for Free Zone Companies",
      description5: "Free VAT Consultation & Registration",
      description6: "Dedicated Account Manager Assistance",
      buttonLabel: "Start Free Zone Business"
    },
    {
      image: "https://rightone.ae/Assets/FlipCard/Offshorelicense3.jpg",
      title: "OFFSHORE LICENSE",
      flipTitle: "Offshore Company Formation UAE",
      subTitle: "Starting from just",
      price: "AED 18,000*",
      description1: "UAE Offshore Company Registration",
      description2: "Affordable Offshore Formation @ AED 18,000*",
      description3: "Flexible Banking Solutions for Offshore Companies",
      description4: "Registered Agent & Legal Compliance Services",
      description5: "100% Ownership for Foreign Investors",
      description6: "Remote Company Licensing Available",
      buttonLabel: "Start Offshore Business"
    }
    ,
  ];

  return (
    <>
      <div className="flex flex-col items-center text-center sectionlayout" data-aos="fade-up">
        <h2 className="text-[40px] font-bold text-center mt-12 text-[#3D498A]" style={{ fontSize: "clamp(20px,2.5vw,180px)" }}>
          Expert Company Formation Services in Dubai, UAE
        </h2>
        <div className="w-72 h-1 bg-[#3D498A] mx-auto"></div>
        <p className="text-blue-900 text-lg my-12">
          Unlock 100% ownership and lifetime visa benefits with our efficient business setup solutions in Dubai.<br /> Trust us for hassle-free corporate services and seamless bank account assistance.
        </p>
      </div>

      <div className="sectionlayout sm:min-h-[300px]  md:min-h-[400px] relative grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {cards.map((card, index) => (
          <Flippy
            key={index}
            flipOnHover={true}
            flipOnClick={false}
            className='md:h-[610px] h-[650px] min-w-[300px]'
          // style={{ minHeight: "700px", minWidth: "300px" }}
          >
            <FrontSide
              className="text-center flex flex-col items-center justify-center"
              style={{
                backgroundImage: `url(${card.image})`,
                backgroundSize: "cover",
                color: "white",
              }}

            >
              <h2 className="font-bold drop-shadow-lg"
                style={{ fontSize: "clamp(28px,1.9vw,36px)" }}
              >{card.title}</h2>
            </FrontSide>
            <BackSide className="p-6 flex flex-col justify-between" style={{ backgroundColor: "#3D498A" }}>
              <div className="text-center pt-8">
                <h1 className="text-3xl font-bold text-white mb-2">{card.flipTitle}</h1>
                <p className="text-lg text-white opacity-80 mb-4">{card.subTitle}</p>
                <h1 className="text-3xl font-extrabold text-white">{card.price}</h1>
              </div>
              <ul className="mt-6 space-y-4 text-left">
                {[card.description1, card.description2, card.description3, card.description4, card.description5, card.description6].map((desc, i) => (
                  <li key={i} className="flex items-center text-white opacity-90">
                    <TiTick className="text-white bg-[#2D3A6A] rounded-full p-1 mr-3 w-6 h-6" /> {desc}
                  </li>
                ))}
              </ul>
              <div className="flex justify-center my-6">
                <a href="https://wa.me/+971526919516" target="_blank" rel="noopener noreferrer">
                  <button className="px-8 py-3 rounded-full bg-white text-[#3D498A] font-semibold text-lg shadow-lg hover:scale-105 transition-transform flex items-center gap-2">
                    {card.buttonLabel}
                    <ArrowRight className="w-5 h-5" />
                  </button>
                </a>
              </div>
            </BackSide>

          </Flippy>
        ))}
      </div>
    </>
  );
};

export default FlipCards;
