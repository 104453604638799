import React from "react";

const TopBusinesses = () => {
  const businesses = [
    {
      name: "Real Estate Brokerage",
      benefits: "High ROI and robust demand.",
      icon: "fas fa-building",
    },
    {
      name: "Tourism and Hospitality",
      benefits: "Access to a booming tourist market.",
      icon: "fas fa-hotel",
    },
    {
      name: "Retail Business",
      benefits: "Strong consumer base and diverse demographics.",
      icon: "fas fa-shopping-cart",
    },
    {
      name: "E-commerce",
      benefits: "Thriving online marketplace and logistics support.",
      icon: "fas fa-globe",
    },
    {
      name: "Healthcare Services",
      benefits: "High demand for quality healthcare providers.",
      icon: "fas fa-heartbeat",
    },
    {
      name: "IT Solutions and Services",
      benefits: "Growing need for digital transformation.",
      icon: "fas fa-laptop-code",
    },
    {
      name: "Education and Training",
      benefits: "Supportive government policies.",
      icon: "fas fa-graduation-cap",
    },
    {
      name: "Construction",
      benefits: "Access to large-scale infrastructure projects.",
      icon: "fas fa-hard-hat",
    },
    {
      name: "Automotive Services",
      benefits: "Demand for vehicles and related services.",
      icon: "fas fa-car",
    },
    {
      name: "Food and Beverage",
      benefits: "Vibrant dining culture and diverse tastes.",
      icon: "fas fa-utensils",
    },
    {
      name: "Logistics and Freight",
      benefits: "Strategic location for global trade.",
      icon: "fas fa-truck",
    },
    {
      name: "Financial Services",
      benefits: "Opportunities in fintech and consultancy.",
      icon: "fas fa-chart-line",
    },
    {
      name: "Media and Advertising",
      benefits: "Expanding media presence in the UAE.",
      icon: "fas fa-bullhorn",
    },
    {
      name: "Renewable Energy",
      benefits: "Support for sustainable initiatives.",
      icon: "fas fa-solar-panel",
    },
    {
      name: "Fitness and Wellness",
      benefits: "Increasing focus on health-conscious lifestyles.",
      icon: "fas fa-dumbbell",
    },
  ];

  return (
    <div className="bg-white py-16 px-8 container mx-auto rounded-2xl">
      <div className="max-w-7xl mx-auto text-center">
        <h2 className="text-4xl font-bold mb-12 text-[#3D498A]">
        15 Profitable Businesses to Start in Dubai Mainland | Company Setup Guide
        </h2>
        <div className="grid md:grid-cols-3 gap-8">
          {businesses.map((business, index) => (
            <div
              key={index}
              className="bg-gradient-to-r from-[#3D498A] to-[#6A7D9F] rounded-xl shadow-lg p-8 text-center hover:scale-105 transition-all duration-300 ease-in-out"
            >
              <div className="text-5xl mb-4 text-white">
                <i className={business.icon}></i>
              </div>
              <h3 className="text-2xl text-white font-semibold mb-4">
                {business.name}
              </h3>
              <p className="text-white">{business.benefits}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopBusinesses;
