import React from "react";

const Benefits = () => {
  return (
    <div className="bg-gradient-to-r from-[#3D498A] to-[#6A7D9F] text-white py-16 px-8 container mx-auto rounded-2xl">
      <div className="max-w-7xl mx-auto text-center">
        <h2 className="text-4xl font-bold mb-12">
        Benefits of Dubai Mainland Company Setup | Why Choose Mainland Dubai?
        </h2>
        <div className="grid md:grid-cols-3 gap-12">
          <div className="bg-white rounded-xl shadow-lg p-8 text-center hover:scale-105 transition-all duration-300 ease-in-out">
            <div className="text-5xl mb-2 text-[#3D498A]">
              <i className="fas fa-users"></i>
            </div>
            <h3 className="text-2xl text-[#3D498A] font-semibold mb-4">
            100% Foreign Ownership in Dubai Mainland
            </h3>
            <p className="text-gray-600">
            Recent reforms now allow expatriates to own 100% of their mainland businesses in many sectors, eliminating the need for a local sponsor and granting complete control over your company.
            </p>
          </div>

          <div className="bg-white rounded-xl shadow-lg p-8 text-center hover:scale-105 transition-all duration-300 ease-in-out">
            <div className="text-5xl mb-2 text-[#3D498A]">
              <i className="fas fa-university"></i>
            </div>
            <h3 className="text-2xl text-[#3D498A] font-semibold mb-4">
            Dubai Business Bank Account Setup
            </h3>
            <p className="text-gray-600">
            Easily open corporate bank accounts with leading UAE banks, enhancing your business's financial flexibility in Dubai.
            </p>
          </div>

          <div className="bg-white rounded-xl shadow-lg p-8 text-center hover:scale-105 transition-all duration-300 ease-in-out">
            <div className="text-5xl mb-2 text-[#3D498A]">
              <i className="fas fa-passport"></i>
            </div>
            <h3 className="text-2xl text-[#3D498A] font-semibold mb-4">
            Dubai Mainland Visa Sponsorship
            </h3>
            <p className="text-gray-600">
            Mainland companies can sponsor employee and investor visas, providing a pathway for talent and stakeholders to live and work in Dubai.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
