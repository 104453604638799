import React, { useState } from 'react';
import { Phone, Mail, MapPin, Globe } from 'lucide-react';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { FaFacebook, FaTiktok, FaLinkedin, FaInstagram } from "react-icons/fa";
import '../../css/style.css'

const ContactForm = () => {
  const [formData, setFormData] = useState({
    serviceType: '',
    name: '',
    phone: '',
    email: '',
    query: ''
  });

  const [status, setStatus] = useState({
    submitting: false,
    msg: '',
    type: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handlePhoneChange = (phone) => {
    setFormData(prevData => ({
      ...prevData,
      phone
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset previous status
    setStatus({
      submitting: true,
      msg: 'Sending...',
      type: ''
    });

    try {
      const formDataToSend = new FormData();
      Object.keys(formData).forEach(key => {
        // Only append non-empty values
        if (formData[key]) {
          formDataToSend.append(key, formData[key]);
        }
      });

      const response = await fetch("/send_email_contact.php", {
        method: 'POST',
        body: formDataToSend
      });

      if (!response.ok) {
        throw new Error("Submission failed");
      }

      const result = await response.json();

      // Update status based on server response
      setStatus({
        submitting: false,
        msg: result.message || "Message sent successfully!",
        type: result.status
      });

      // Clear form if submission was successful
      if (result.status === 'success') {
        setFormData({
          serviceType: '',
          name: '',
          phone: '',
          email: '',
          query: ''
        });
      }
    } catch (error) {
      setStatus({
        submitting: false,
        msg: "Failed to send request. Please try again.",
        type: 'error'
      });
    }
  };

  return (
    <>
      <div className="flex flex-col md:flex-row max-w-custom mx-auto">
        <div className="w-full md:w-1/2 p-8 flex items-center justify-center bg-white">
          <div className="w-full max-w-md" data-aos="fade-up">
            <div className="text-center mb-8">
              <h2 className="text-2xl font-bold text-[#3D498A] mb-2">Let's discuss your project!</h2>
              <div className="w-[50%] h-1 bg-[#3D498A] mx-auto mt-2"></div>
              <div className="flex justify-center">
              </div>
            </div>

            {status.msg && (
              <div className={`mb-4 p-3 rounded text-center ${status.type === 'success'
                  ? 'bg-green-100 text-green-800'
                  : 'bg-red-100 text-red-800'
                }`}>
                {status.msg}
              </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-4">
              <select
                name="serviceType"
                value={formData.serviceType}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-[#3D498A]"
                required
              >
                <option value="">Choose Service Type</option>
                <option value="Mainland Company">Mainland company</option>
                <option value="Free Zone Company">Free Zone Company</option>
                <option value="Offshore Company">Offshore Company</option>
                <option value="LLC Company">LLC Company</option>
                <option value="Product Registration">Product Registration</option>
                <option value="PRO Services">PRO Services</option>
                <option value="Bank Account">Bank Account</option>
                <option value="Visa Services">Visa Services</option>
                <option value="Other">Other</option>
              </select>

              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Your Name"
                className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-[#3D498A]"
                required
              />

              <div className="w-full phone-input-wrapper">
                <PhoneInput
                  defaultCountry="ae"
                  value={formData.phone}
                  onChange={handlePhoneChange}
                  inputClassName="w-full h-12 px-3 rounded bg-transparent text-white placeholder-gray-300 border border-white/30 focus:outline-none focus:ring-2 focus:ring-white/50"
                  style={{
                    '--react-international-phone-border-color': '#e5e7eb',
                    '--react-international-phone-border-radius': '0.375rem',
                    '--react-international-phone-height': '2.75rem',
                    '--react-international-phone-width': '100%',
                    '--react-international-phone-background-color': 'white',
                    '--react-international-phone-text-color': '#374151',
                    '--react-international-phone-selected-country-background': '#f3f4f6',
                    '--react-international-phone-country-selector-background-color': 'white',
                    '--react-international-phone-country-selector-border-color': '#e5e7eb',
                  }}
                  className="w-full focus-within:border-[#3D498A]"
                />
              </div>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Your email"
                className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-[#3D498A]"
                required
              />
              <textarea
                name="query"
                value={formData.query}
                onChange={handleChange}
                placeholder="Your Query"
                rows="4"
                className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-[#3D498A]"
                required
              ></textarea>
              <button
                type="submit"
                disabled={status.submitting}
                className="w-32 bg-[#3D498A] text-white py-3 px-6 rounded hover:bg-opacity-90 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {status.submitting ? 'SENDING...' : 'SUBMIT'}
              </button>
            </form>
          </div>
        </div>
        <div className="relative w-full md:w-1/2 min-h-screen bg-blue-900 p-8 flex items-center justify-center text-white">
          <video
            autoPlay
            playsInline
            muted
            loop
            disablePictureInPicture
            preload="auto"
            className="absolute top-0 left-0 w-full h-full object-cover opacity-50"
            style={{
              WebkitUserSelect: 'none',
              WebkitTouchCallout: 'none',
              WebkitTapHighlightColor: 'transparent',
              outline: 'none'
            }}
          >
            <source src='https://rightone.ae//Assets/video/contactVideo.mp4' type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="relative z-10 text-center max-w-lg w-full space-y-8">
            <div className="flex items-center space-x-4 hover:translate-x-2 transition-transform duration-300">
              <Phone className="w-6 h-6 text-white" />
              <div className="text-left">
                <p className="text-gray-200 text-sm">Call us</p>
                <p className="font-semibold">Landline: +971 42 224 797</p>
                <p className="font-semibold">Mobile: +971 52 691 9516</p>
              </div>
            </div>

            {/* Updated "Mail us" section with link */}
            <a
              href="mailto:info@rightone.ae"
              className="flex items-center space-x-4 hover:translate-x-2 transition-transform duration-300"
            >
              <Mail className="w-6 h-6 text-white" />
              <div className="text-left">
                <p className="text-gray-200 text-sm">Mail us</p>
                <p className="font-semibold">info@rightone.ae</p>
              </div>
            </a>

            {/* Updated "Website" section with link */}
            <a
              href="https://www.rightone.ae"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center space-x-4 hover:translate-x-2 transition-transform duration-300"
            >
              <Globe className="w-6 h-6 text-white" />
              <div className="text-left">
                <p className="text-gray-200 text-sm">Website</p>
                <p className="font-semibold">www.rightone.ae</p>
              </div>
            </a>

            <div className="flex items-center space-x-4 hover:translate-x-2 transition-transform duration-300">
              <MapPin className="w-6 h-6 text-white" />
              <div className="text-left">
                <p className="text-gray-200 text-sm">Our office</p>
                <p className="font-semibold">Right One Corporate Services LLC - <br /> Ground Floor, Office # 55, Al Gurg Building, <br /> Umm Hurair Rd, Dubai - U.A.E</p>
              </div>
            </div>
            <div className="flex justify-center space-x-4 pt-8">
              <a
                href="https://www.facebook.com/rightonecorporateservices"
                target="_blank"
                rel="noopener noreferrer"
                className="w-10 h-10 rounded-full border border-white flex items-center justify-center transition-all duration-300 hover:bg-white/20 hover:scale-110"
              >
                <FaFacebook className="text-2xl text-white hover:text-blue-500" />
              </a>
              <a
                href="https://www.tiktok.com/@rightonecorporate?_t=ZS-8urlZSpkczg&_r=1"
                target="_blank"
                rel="noopener noreferrer"
                className="w-10 h-10 rounded-full border border-white flex items-center justify-center transition-all duration-300 hover:bg-white/20 hover:scale-110"
              >
                <FaTiktok className="text-2xl text-white hover:text-blue-400" />
              </a>
              <a
                href="https://www.linkedin.com/company/right-one-corporate-services/"
                target="_blank"
                rel="noopener noreferrer"
                className="w-10 h-10 rounded-full border border-white flex items-center justify-center transition-all duration-300 hover:bg-white/20 hover:scale-110"
              >
                <FaLinkedin className="text-2xl text-white hover:text-blue-600" />
              </a>
              <a
                href="https://www.instagram.com/rightonecorporateservices?igsh=c214N3Fmem5mNzh3"
                target="_blank"
                rel="noopener noreferrer"
                className="w-10 h-10 rounded-full border border-white flex items-center justify-center transition-all duration-300 hover:bg-white/20 hover:scale-110"
              >
                <FaInstagram className="text-2xl text-white hover:text-pink-500" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="google-map-container max-w-custom mx-auto">
        <iframe
          title="UAE Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d905.0175280504168!2d55.3281665!3d25.2858912!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5da0f5d392d1%3A0xcd7559f0ed70532f!2sRight%20One%20Corporate%20Services!5e0!3m2!1sen!2sae!4v1717501200743!5m2!1sen!2sae"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </>
  );
};

export default ContactForm; 