import React from "react";
import { ArrowRight } from "lucide-react";

const DocumentationServices = () => {
  const img1 = `${process.env.REACT_APP_DOMAIN}/Assets/CorporateServices/BankAccountOpen/document.jpg`;

  const documentationItems = [
    "Preparing and verifying required documents",
    "Assisting with shareholder and signatory documentation",
    "Guidance on fulfilling Know Your Customer (KYC) requirements",
  ];

  const serviceCards = [
    {
      icon: (
        <div className="w-16 h-16 bg-[#003366] rounded-full flex  items-center justify-center">
          <div className="text-3xl text-white">
            <i className="fas fa-university"></i>
          </div>
        </div>
      ),
      title: "Bank Account Opening Process",
      items: [
        "1. Scheduling appointments with agents",
        "2. Coordinating bank interviews with accounts",
        "3. Submitting and tracking your application",
        "4. Resolving any queries or compliance issues raised",
      ],
    },
    {
      icon: (
        <div className="w-16 h-16 bg-[#003366] rounded-full flex items-center justify-center">
          <div className="text-3xl text-white">
            <i className="fas fa-handshake"></i>
          </div>
        </div>
      ),
      title: "Specialized Account Opening Services",
      items: [
        "1. Multi-Currency corporate accounts",
        "2. Offshore corporate bank accounts",
        "3. Regulated company bank accounts",
        "4. Business credit card applications",
      ],
    },
    {
      icon: (
        <div className="w-16 h-16 bg-[#003366] rounded-full flex items-center justify-center">
          <div className="text-3xl text-white">
            <i className="fas fa-headset"></i>
          </div>
        </div>
      ),
      title: "Additional Support",
      items: [
        "1. Application with bank for cross-border setup",
        "2. Guidance on forms, fees, and other bank",
        "3. Advice on maintaining insurance and",
        "4. Support pre/post-account updates",
      ],
    },
  ];

  return (
    <div className="bg-[#003366] text-white py-16 px-4 container mx-auto">
      <div className="max-w-7xl mx-auto">
        {/* Documentation Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-14 items-center mb-16">
          <div className="space-y-6">
            <h2 className="text-2xl md:text-3xl font-bold">
              DOCUMENTATION AND COMPLIANCE
            </h2>
            <p className="text-lg">
              We guide you through the documentation requirements to ensure your
              application is complete and compliant.
            </p>
            <div className="space-y-10">
              {documentationItems.map((item, index) => (
                <div
                  key={index}
                  className="flex items-center bg-[#4A4D9C] px-7 py-7 rounded-md space-x-3"
                >
                  <svg
                    className="w-7 h-7 flex-shrink-0 text-[#4A4D9C] bg-white border-white rounded-full"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <span className="font-medium">{item}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="h-[400px]">
            <img
              src={img1}
              alt="Documentation"
              className="w-full h-full object-cover rounded-lg shadow-lg mt-10 me-9 lg:me-0"
            />
          </div>
        </div>

        {/* Services Section */}
        <div className="text-center mb-12">
          <h2 className="lg:text-3xl text-3xl font-bold flex flex-col lg:flex-row items-center justify-center gap-2 pt-12">
            <div className="w-10 h-10  rounded-full flex flex-col lg:flex-row items-center justify-center">
              <div className="text-3xl lg:text-4xl text-white">
                <i className="fas fa-university"></i>
              </div>
            </div>
            DISCOVER THE OTHER SERVICES
          </h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {serviceCards.map((card, index) => (
            <div
              key={index}
              className="bg-white text-[#3D498A] p-6 rounded-lg shadow-lg flex flex-col justify-between"
            >
              <div>
                <div className="flex justify-center md:justify-start mb-6">
                  {card.icon}
                </div>
                <h3 className="font-bold text-lg my-6 ">{card.title}</h3>
                <ul className="space-y-3 text-sm mb-6">
                  {card.items.map((item, idx) => (
                    <li key={idx} className="text-[#3D498A]">
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
              <a
                href="https://wa.me/+971526919516"
                target="_blank"
                rel="noopener noreferrer"
                className="w-full max-w-[200px] bg-[#3D498A] hover:bg-[#232952] text-white py-2.5 px-4 rounded-lg font-semibold flex items-center justify-center space-x-2 transition-all duration-300 text-sm md:text-base group"
              >
                <span>Contact Us Now</span>
                <ArrowRight className="w-4 h-4 md:w-5 md:h-5 group-hover:translate-x-1 transition-transform" />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DocumentationServices;
