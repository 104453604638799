import React from "react";
import IntroductionCards from "./IntroductionCards";
import Benefits from "./Benefits";
import Introduction from "./Introduction";
import Hero from "./Hero";
import Tax from "./Tax";
import TopBusiness from "./TopBusiness";
import BestBusiness from "./BestBusiness";
import Form from "../../../Components/Form/Form23then135";

function Freezone() {
  return (
    <>
      <Hero />
      <Introduction />
      <IntroductionCards />
      <Benefits />
      <Tax />
      <TopBusiness />
      <BestBusiness />
      <Form />
    </>
  );
}

export default Freezone;
