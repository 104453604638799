import React from 'react';
import { ArrowRight } from 'lucide-react';

const Services = () => {
  const logos = [
    { title: 'COMPANY FORMATION', image: "https://rightone.ae/Assets/CorporateServices/CorporateServices1.jpg" },
    { title: 'PRO SERVICES', image: "https://rightone.ae/Assets/CorporateServices/CorporateServices2.jpg" },
    { title: 'ACCOUNTING & BOOKKEEPING', image: "https://rightone.ae/Assets/CorporateServices/CorporateServices3.jpg" },
    { title: 'TAX SERVICES', image: "https://rightone.ae/Assets/CorporateServices/CorporateServices4.jpg" },
    { title: 'CORPORATE BANK ACCOUNT OPENING', image: "https://rightone.ae/Assets/CorporateServices/CorporateServices5.jpg" },
    { title: 'PRODUCT REGISTRATION', image: "https://rightone.ae/Assets/CorporateServices/CorporateServices6.jpg" },
    { title: 'GOLDEN VISA', image: "https://rightone.ae/Assets/CorporateServices/CorporateServices7.jpg" },
    { title: 'TRADEMARK REGISTRATION', image: "https://rightone.ae/Assets/CorporateServices/CorporateServices8.jpg" },
    { title: 'COMPANY LIQUIDATION', image: "https://rightone.ae/Assets/CorporateServices/CorporateServices9.jpg" },
    { title: 'TRANSLATION SERVICES', image: "https://rightone.ae/Assets/CorporateServices/CorporateServices10.png" },
    { title: 'ATTESTATION SERVICES', image: "https://rightone.ae/Assets/CorporateServices/CorporateServices11.jpg" },
    { title: 'DUBAI CUSTOM REGISTRATION SERVICES', image: "https://rightone.ae/Assets/CorporateServices/CorporateServices12.jpg" },
    { title: 'GOV APPROVALS', image: "https://rightone.ae/Assets/CorporateServices/CorporateServices13.jpg" },
    { title: 'DIGITAL MARKETING & BRANDING', image: "https://rightone.ae/Assets/CorporateServices/CorporateServices14.jpg" },
    { title: 'WEBSITE DEVELOPMENT', image: "https://rightone.ae/Assets/CorporateServices/CorporateServices15.jpg" }
  ];

  return (
    <div className="bg-white py-16 container mx-auto overflow-hidden">
      <div className="text-center mb-12 px-4" data-aos="fade-up">
        <h2 className="text-xl md:text-[clamp(20px,4vw,40px)] lg:text-[clamp(30px,3vw,50px)] font-bold text-[#3D498A] leading-tight">
          CORPORATE SERVICES
        </h2>
        <div className="w-72 h-1 bg-blue-900 mx-auto"></div>
      </div>
      <div className="relative mt-16 overflow-hidden" data-aos="fade-up">
        <div className="flex gap-4 logo-slide">
          {[...logos, ...logos, ...logos].map((logo, index) => (
            <div
              key={`service-${index}`}
              className="w-80 h80 relative group flex-shrink-0"
            >
              <div className="relative w-80 h-80 overflow-hidden rounded-lg">
                <img
                  src={logo.image}
                  alt={logo.title}
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-black/50 flex flex-col items-center justify-center p-6 transition-opacity">
                  <h3 className="text-xl md:text-2xl font-bold text-white text-center mb-3">
                    {logo.title}
                  </h3>
                  <a
                    href="https://wa.me/+971526919516"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-full max-w-[200px] bg-[#3D498A] hover:bg-[#232952] text-white py-2.5 px-4 rounded-lg font-semibold flex items-center justify-center space-x-2 transition-all duration-300 text-sm md:text-base group"
                  >
                    <span>Contact Us Now</span>
                    <ArrowRight className="w-4 h-4 md:w-5 md:h-5 group-hover:translate-x-1 transition-transform" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <style jsx>{`
        .logo-slide {
          animation: slide 60s linear infinite;
          width: fit-content;
        }

        @keyframes slide {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(calc(-100% / 3));
          }
        }

        .logo-slide:hover {
          animation-play-state: paused;
        }

        @media (max-width: 768px) {
          .logo-slide {
            animation-duration: 35s;
          }
        }
      `}</style>
    </div>
  );
};

export default Services;