import React, { useState, useRef } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const Mainland = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [startX, setStartX] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [dragDistance, setDragDistance] = useState(0);
    const containerRef = useRef(null);

    const cards = [
        {
            title: 'Trade Name Services',
            description: 'Booking trade names and securing initial approvals from DED',
        },
        {
            title: 'Company Documentation',
            description: 'Drafting and notarizing MOA documents',
        },
        {
            title: 'Company Modifications',
            description: 'Amending MOAs for company structure/ownership changes and assisting with name changes',
        },
        {
            title: 'Licensing Services',
            description: 'Business license renewals and obtaining/renewing professional, commercial and industrial licenses',
        },
        {
            title: 'Visa & Immigration',
            description: 'Processing visas for employees/dependents and guidance on sponsorship requirements',
        },
        {
            title: 'Labor Documentation',
            description: 'Assistance with labor and immigration documentation',
        },
        {
            title: 'Customs Services',
            description: 'Assistance with customs registration',
        },
        {
            title: 'Special Permits',
            description: 'Guidance for obtaining special activity permits (food safety, health, construction)',
        },
        {
            title: 'Compliance Support',
            description: 'Support for compliance with legal and regulatory frameworks',
        }
    ];

    const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;
    const cardsToShow = isMobile ? 1 : 4;
    const cardWidth = 100 / cardsToShow;

    const handleDragStart = (e) => {
        setIsDragging(true);
        setStartX(e.type === 'mousedown' ? e.pageX : e.touches[0].pageX);
    };

    const handleDragMove = (e) => {
        if (!isDragging) return;

        e.preventDefault();
        const currentX = e.type === 'mousemove' ? e.pageX : e.touches[0].pageX;
        const diff = startX - currentX;
        const containerWidth = containerRef.current.offsetWidth;
        const threshold = containerWidth * 0.2;

        setDragDistance(diff);

        if (Math.abs(diff) > threshold) {
            if (diff > 0 && currentIndex < cards.length - cardsToShow) {
                setCurrentIndex(prev => prev + 1);
            } else if (diff < 0 && currentIndex > 0) {
                setCurrentIndex(prev => prev - 1);
            }
            setIsDragging(false);
            setDragDistance(0);
        }
    };

    const handleDragEnd = () => {
        setIsDragging(false);
        setDragDistance(0);
    };

    const next = () => {
        if (currentIndex < cards.length - cardsToShow) {
            setCurrentIndex(prev => prev + 1);
        } else {
            setCurrentIndex(0);
        }
    };

    const prev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(prev => prev - 1);
        } else {
            setCurrentIndex(Math.max(cards.length - cardsToShow, 0));
        }
    };

    const getTransform = () => {
        const baseTransform = -(currentIndex * cardWidth);
        const dragAdjustment = isDragging ? -(dragDistance / containerRef.current?.offsetWidth * 100) : 0;
        return `translateX(${baseTransform + dragAdjustment}%)`;
    };

    return (
        <div id="mainland-services" className="container mx-auto px-4 py-12" data-aos="fade-up">
            <h2 className="relative text-2xl md:text-4xl lg:text-5xl text-center text-[#3D498A] font-bold">
                Top Rated Mainland Licensing Services
            </h2>
            <p className="mb-2 mt-3 max-w-[70%] mx-auto lg:text-center text-center lg:text-lg">
                Mainland Licensing Services offers comprehensive support for business setup and operations, including trade name booking, DED approvals, MOA drafting, license renewals, visa processing, compliance guidance, and assistance with regulatory documentation.
            </p>


            {/* <div className="md:w-72 w-[120px] h-1 bg-[#3D498A] mx-auto"></div> */}

            <div className="relative overflow-hidden mt-8">
                <button
                    onClick={prev}
                    className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-4 z-10 bg-white/80 rounded-full p-2 shadow-lg"
                    aria-label="Previous slide"
                >
                    <ChevronLeft className="w-6 h-6" />
                </button>

                <button
                    onClick={next}
                    className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-4 z-10 bg-white/80 rounded-full p-2 shadow-lg"
                    aria-label="Next slide"
                >
                    <ChevronRight className="w-6 h-6" />
                </button>

                <div
                    ref={containerRef}
                    className="relative overflow-hidden touch-pan-y"
                >
                    <div
                        className="flex transition-transform duration-300 ease-out"
                        style={{
                            transform: getTransform(),
                            cursor: isDragging ? 'grabbing' : 'grab'
                        }}
                        onMouseDown={handleDragStart}
                        onMouseMove={handleDragMove}
                        onMouseUp={handleDragEnd}
                        onMouseLeave={handleDragEnd}
                        onTouchStart={handleDragStart}
                        onTouchMove={handleDragMove}
                        onTouchEnd={handleDragEnd}
                    >
                        {cards.map((card, index) => (
                            <div
                                key={index}
                                className="flex-none"
                                style={{ width: `${cardWidth}%` }}
                            >
                                <div className="relative overflow-hidden rounded-lg h-[400px] m-2 group transition-all duration-300 bg-[#3D498A]">
                                    <div className="h-full flex flex-col items-center justify-center p-8">
                                        <h3 className="text-white text-center text-3xl font-semibold mb-6">
                                            {card.title}
                                        </h3>

                                        <p className="text-white text-center text-lg font-normal mb-8">
                                            {card.description}
                                        </p>

                                        <a
                                            href="https://wa.me/+971526919516"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="px-6 py-2 bg-white text-[#3D498A] hover:bg-[#3D498A] hover:text-white border-2 border-white rounded transition-all duration-300"
                                        >
                                            Get Quote
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="flex justify-center gap-2 mt-4 md:hidden">
                {cards.map((_, index) => (
                    <div
                        key={index}
                        className={`w-2 h-2 rounded-full ${index === currentIndex ? 'bg-[#3D498A]' : 'bg-gray-400'}`}
                    />
                ))}
            </div>
        </div>
    );
};

export default Mainland;