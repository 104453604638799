import React from 'react';

const img1=`${process.env.PUBLIC_URL}/Assets/offshore/trading.jpg`
const img2=`${process.env.PUBLIC_URL}/Assets/offshore/holding.jpg`
const img3=`${process.env.PUBLIC_URL}/Assets/offshore/license.jpg`

const Introduction = () => {
  return (
    <>
      {/* Introduction Section */}
      <div id = 'offshore' className="flex flex-col max-w-6xl mx-auto mt-20 gap-24 bg-gray-50">
        {/* Text Content */}
        <div className="w-full flex flex-col justify-center items-center">
          <h1 className="text-2xl md:text-3xl text-center font-bold mb-4">
            What is
            <span className="text-[#3D498A]"> OFFSHORE IN UAE</span>?
          </h1>

          <p className="mb-2 lg:text-center text-justify lg:text-lg mx-6">
            An offshore company in the UAE is a legal entity 
            established outside the jurisdiction of its primary operations. These companies are ideal for 
            international trading, asset protection, and holding investments, without the requirement for a 
            physical office in the UAE.
          </p>
        </div>
      </div>

      {/* License Section */}
      <section className="py-16 bg-gray-50">
        <div className="max-w-6xl min-w-2xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="mx-4 sm:mx-6 md:mx-12 lg:mx-52 max-w-2xl px-6 sm:px-10">
            <h2 className="text-3xl font-bold text-center text-white mb-12 rounded-2xl bg-[#3D498A] py-10 mx-0">
              TYPES OF LICENSES IN OFFSHORE
            </h2>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {/* Trading License Card */}
            <div className="bg-white rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-shadow duration-300">
              <div className="h-48 overflow-hidden">
                <img 
                  src={img1}  // Corrected image path
                  alt="Trading License"
                  className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                />
              </div>
              <div className="p-6">
                <h3 className="text-xl font-semibold mb-3 text-[#3D498A]">
                  Trading License
                </h3>
                <p className="text-gray-600 mb-4">
                  Suitable for businesses engaged in import/export and international trade.
                </p>
                <ul className="space-y-2">
                  <li className="text-sm text-gray-500 flex items-center">
                    <svg className="w-4 h-4 mr-2 text-[#3D498A]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Ideal for global trade
                  </li>
                  <li className="text-sm text-gray-500 flex items-center">
                    <svg className="w-4 h-4 mr-2 text-[#3D498A]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Allows import/export activities
                  </li>
                </ul>
              </div>
            </div>

            {/* Holding License Card */}
            <div className="bg-white rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-shadow duration-300">
              <div className="h-48 overflow-hidden">
                <img 
                  src={img2}  // Corrected image path
                  alt="Holding License"
                  className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                />
              </div>
              <div className="p-6">
                <h3 className="text-xl font-semibold mb-3 text-[#3D498A]">
                  Holding License
                </h3>
                <p className="text-gray-600 mb-4">
                  For managing investments, properties, and intellectual property rights.
                </p>
                <ul className="space-y-2">
                  <li className="text-sm text-gray-500 flex items-center">
                    <svg className="w-4 h-4 mr-2 text-[#3D498A]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Investment management
                  </li>
                  <li className="text-sm text-gray-500 flex items-center">
                    <svg className="w-4 h-4 mr-2 text-[#3D498A]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Intellectual property management
                  </li>
                </ul>
              </div>
            </div>

            {/* Service License Card */}
            <div className="bg-white rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-shadow duration-300">
              <div className="h-48 overflow-hidden">
                <img 
                  src={img3}  // Corrected image path
                  alt="Service License"
                  className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                />
              </div>
              <div className="p-6">
                <h3 className="text-xl font-semibold mb-3 text-[#3D498A]">
                  Service License
                </h3>
                <p className="text-gray-600 mb-4">
                  Designed for consultancy and other non-physical service-based businesses.
                </p>
                <ul className="space-y-2">
                  <li className="text-sm text-gray-500 flex items-center">
                    <svg className="w-4 h-4 mr-2 text-[#3D498A]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Perfect for service-based businesses
                  </li>
                  <li className="text-sm text-gray-500 flex items-center">
                    <svg className="w-4 h-4 mr-2 text-[#3D498A]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Ideal for consultancy services
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Introduction;
