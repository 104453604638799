import React from 'react';
import { TrendingUp, Shield, Building } from 'lucide-react';

const BestBusiness = () => {
  const activities = [
    {
      icon: <TrendingUp className="w-16 h-16 text-[#3D498A]" />,
      title: "International Trading",
    },
    {
      icon: <Shield className="w-16 h-16 text-[#3D498A]" />,
      title: "Asset Holding",
    },
    {
      icon: <Building className="w-16 h-16 text-[#3D498A]" />,
      title: "Property Management",
    }
  ];

  return (
    <section className="py-16 px-4 bg-gray-50">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-3xl font-bold text-center text-[#3D498A] mb-12">
          BEST BUSINESS ACTIVITIES TO SETUP IN OFFSHORE
        </h2>
        
        <div className="grid md:grid-cols-3 gap-8">
          {activities.map((activity, index) => (
            <div 
              key={index}
              className="flex flex-col items-center text-center p-6"
            >
              {activity.icon}
              <h3 className="text-xl font-semibold mt-4">{activity.title}</h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BestBusiness;