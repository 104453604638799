import React from "react";

const AboutSection = () => (
  <section
    className="min-h-[400px] md:h-[630px] flex items-center justify-center bg-cover bg-center text-white p-4 md:p-0"
    // style={{ backgroundImage: `url(${img1})` }}
    style={{ backgroundImage: `url('https://rightone.ae/Assets/About/About.jpg')` }}

  >
    <div className="bg-[#3D498A] bg-opacity-80 p-6 md:p-12 rounded-xl md:rounded-2xl shadow-2xl text-center w-full max-w-5xl overflow-y-auto m-4">
      <h1 className="text-4xl md:text-6xl font-extrabold mb-4 md:mb-6 text-white drop-shadow-lg">
        About Us
      </h1>
      <p className="text-lg md:text-xl text-white leading-relaxed tracking-wide mb-6 md:mb-8 drop-shadow-md text-justify overflow-hidden">
        Right One Corporate Services, founded in 2014, is a leading consultancy
        specializing in business setup across Mainland, Freezone, and Offshore
        in Dubai. With a team of experts and over 150+ corporate clients, we
        provide end-to-end services such as PRO assistance, Golden Visa
        facilitation, company formation, residency support, and corporate bank
        account setup. Our mission is to simplify business establishment while
        ensuring professionalism and integrity.
      </p>
      <div className="mt-4 md:mt-6">
        <a href="/companyProfile">
          <button className="px-6 md:px-8 py-2 md:py-3 rounded-full bg-white text-blue-900 font-semibold shadow-xl hover:bg-gray-100 transition-all text-sm md:text-base">
            Learn More
          </button>
        </a>
      </div>
    </div>
  </section>
);

export default AboutSection;

