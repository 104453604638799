import React from "react";

const Benefits = () => {
  return (
    <div className="relative mt-16 md:mt-24 mb-8 md:mb-16 px-4 md:px-8">
      <div className="absolute -top-14 md:-top-20 left-1/2 transform -translate-x-1/2 w-[calc(100%-4rem)] md:w-full max-w-5xl">
        <div className="bg-white rounded-xl shadow-2xl p-3 md:p-8 text-center ">
          <h2 className="text-2xl md:text-4xl font-bold text-[#3D498A]">
            Key Benefits of Setting Up a Company in Dubai Mainland
          </h2>
        </div>
      </div>

      <div className="bg-gradient-to-r from-[#3D498A] to-[#6A7D9F] text-white py-12 md:py-16 px-4 md:px-8 container mx-auto rounded-2xl pt-20 md:pt-32">
        <div className="max-w-7xl mx-auto text-center">
          <div className="grid md:grid-cols-3 gap-6 md:gap-12 mt-4 md:mt-8">
            <div className="bg-white rounded-xl shadow-lg p-4 md:p-8 text-center hover:scale-105 transition-all duration-300 ease-in-out">
              <div className="text-4xl md:text-5xl mb-2 text-[#3D498A]">
                <i className="fas fa-money-bill-wave"></i>
              </div>
              <h3 className="text-xl md:text-2xl text-[#3D498A] font-semibold mb-2 md:mb-4">
                Tax-Free Operations
              </h3>
              <p className="text-gray-600">
                Enjoy tax-free operations, 100% ownership, and a simplified
                company setup process for your business.
              </p>
            </div>

            <div className="bg-white rounded-xl shadow-lg p-4 md:p-8 text-center hover:scale-105 transition-all duration-300 ease-in-out">
              <div className="text-4xl md:text-5xl mb-2 text-[#3D498A]">
                <i className="fas fa-credit-card"></i>
              </div>
              <h3 className="text-xl md:text-2xl text-[#3D498A] font-semibold mb-2 md:mb-4">
                Bank Account Setup
              </h3>
              <p className="text-gray-600">
                Effortlessly open corporate accounts with leading UAE banks,
                ensuring financial flexibility for your business.
              </p>
            </div>

            <div className="bg-white rounded-xl shadow-lg p-4 md:p-8 text-center hover:scale-105 transition-all duration-300 ease-in-out">
              <div className="text-4xl md:text-5xl mb-2 text-[#3D498A]">
                <i className="fas fa-id-card"></i>
              </div>
              <h3 className="text-xl md:text-2xl text-[#3D498A] font-semibold mb-2 md:mb-4">
                Bank Account Setup
              </h3>
              <p className="text-gray-600">
                Hassle-free visa sponsorship for employees and investors,
                enabling talent and stakeholders to work and reside in Dubai.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
