import React from "react";

const VisionMissionSection = () => (
  <section className="h-full px-7 py-[100px] container mx-auto">
    <div className="max-w-5xl mx-auto h-[90%]">
      <div>
        <h2 className="text-4xl md:text-[clamp(20px,4vw,40px)] lg:text-6xl font-bold text-[#3D498A] leading-tight  text-center">
          Future Goals

        </h2>
        <div
          className="h-2 bg-customBlue mx-auto mb-28 mt-4"
          style={{
            width: 'clamp(200px, 50%, 400px)'
          }}
        ></div>

      </div>
      <div className="grid md:grid-cols-2 gap-24 h-auto">
        <div className="bg-[#3D498A] rounded-2xl p-8 text-white transform hover:scale-105  transition-all duration-300 ease-in-out">
          <h3 className="text-[30px] md:text-5xl font-bold mb-6 text-center" >Our Vision</h3>
          <p className="text-lg mb-6 text-justify">
            To be the most trusted and reliable partner for businesses in the
            UAE, recognized for our commitment to integrity, innovation, and
            exceptional service.
          </p>
        </div>
        <div className="bg-[#3D498A] rounded-2xl p-8 text-white transform hover:scale-105  transition-all duration-300 ease-in-out">
          <h3 className="text-[30px] md:text-5xl  font-bold mb-6 text-center">Our Mission</h3>
          <p className="text-lg mb-6 text-justify">
            To empower businesses with seamless and efficient solutions for
            setup, growth, and sustainability in Dubai, while ensuring the
            highest levels of quality, transparency, and customer satisfaction.
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default VisionMissionSection;
