import React, { useState, useEffect } from 'react';
import { Mic, Briefcase, DollarSign, Award, Headphones } from 'lucide-react';

const CircularMenu = () => {
    const [currentText, setCurrentText] = useState(0);
    const texts = [
        { title: 'We Listen', description: 'We listen, we discuss, we advise.' },
        { title: 'Experience', description: 'Years of industry expertise.' },
        { title: 'Best Value', description: 'Competitive and transparent pricing.' },
        { title: 'Quality', description: 'Reliable and trustworthy service.' },
        { title: 'Support', description: '24/7 dedicated assistance.' }
    ];
    const reasons = [
        {
            icon: <Mic className="w-6 h-6 text-white" />,
            title: "We Listen",
            description: "We listen to your business requirements, objectives, and plans, ensuring we recommend the best company formation solution in the UAE."
        },
        {
            icon: <Briefcase className="w-6 h-6 text-white" />,
            title: "Industry Experience",
            description: "Our expert consultants bring years of industry experience in setting up companies in the UAE and navigating legal compliance, ensuring a smooth process."
        },
        {
            icon: <DollarSign className="w-6 h-6 text-white" />,
            title: "Low Prices",
            description: "We offer the best prices for UAE company formation services, with no hidden fees, ensuring transparency and value for your business setup."
        },
        {
            icon: <Award className="w-6 h-6 text-white" />,
            title: "Quality Service",
            description: "Our reliable consultants and professionals ensure high-quality service for your UAE business formation, keeping your best interests in mind."
        },
        {
            icon: <Headphones className="w-6 h-6 text-white" />,
            title: "Customer Satisfaction",
            description: "With our personalized approach and dedicated customer support, your business setup in the UAE will be smooth and hassle-free. We’re always just a call away."
        }
    ];
    

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentText((prev) => (prev + 1) % texts.length);
        }, 1000);
        return () => clearInterval(interval);
    }, [texts.length]);

    return (
        <>
            <div className='container mx-auto'>
                <div>
                    <h2 className="text-4xl font-bold text-[#3D498A] text-center mb-8" style={{ fontSize: "clamp(20px,2.5vw,180px)" }}>
                        Why Choose Us
                    </h2>
                    <div className="md:w-72 w-[120px] h-1 bg-[#3D498A] mx-auto"></div>
                </div>
                <div className="flex flex-wrap items-center justify-center min-h-7 mx-4 bg-[#3D498A] rounded-3xl mt-10">
                    <div className='w-full md:w-1/2 flex items-center justify-center p-4 mt-10 md:mt-0'>
                        <div className="relative w-60 h-60 md:w-[32rem] md:h-[32rem]">
                            <div className="absolute inset-0 border-8 border-gray-200 rounded-full z-0"></div>
                            <div className="absolute inset-0 m-auto w-36 h-36 md:w-[22rem] md:h-[22rem] bg-white rounded-full flex items-center justify-center z-05">
                                <div className="text-center p-4">
                                    <h3 className="font-bold text-base md:text-2xl mb-2">{texts[currentText].title}</h3>
                                    <p className="text-xs md:text-xl text-gray-600">{texts[currentText].description}</p>
                                </div>
                            </div>
                            <div className="absolute inset-0 animate-spin-slow z-10">
                                <div className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2">
                                    <div className="bg-orange-400 p-2 md:p-3 rounded-full">
                                        <Mic className="w-5 h-5 md:w-9 md:h-9 text-white" />
                                    </div>
                                </div>
                                <div className="absolute top-1/2 right-0 translate-x-1/2 -translate-y-1/2">
                                    <div className="bg-orange-400 p-2 md:p-3 rounded-full">
                                        <Briefcase className="w-5 h-5 md:w-9 md:h-9 text-white" />
                                    </div>
                                </div>
                                <div className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2">
                                    <div className="bg-orange-400 p-2 md:p-3 rounded-full">
                                        <DollarSign className="w-5 h-5 md:w-9 md:h-9 text-white" />
                                    </div>
                                </div>
                                <div className="absolute top-1/2 left-0 -translate-x-1/2 -translate-y-1/2">
                                    <div className="bg-orange-400 p-2 md:p-3 rounded-full">
                                        <Award className="w-5 h-5 md:w-9 md:h-9 text-white" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full md:w-1/2 items-center justify-center md:p-4' data-aos="fade-up">
                        <div className=" p-8">
                            <h3 className="text-white text-2xl font-bold mb-8 text-center">5 RIGHT REASONS TO CHOOSE US</h3>

                            <div className="space-y-6 text-justify">
                                {reasons.map((reason, index) => (
                                    <div key={index} className="flex items-start gap-4">
                                        <div className="bg-orange-400 p-2 rounded-full flex-shrink-0">
                                            {reason.icon}
                                        </div>
                                        <div>
                                            <h3 className="text-white font-bold text-xl mb-2">
                                                {reason.title}
                                            </h3>
                                            <p className="text-white text-sm leading-relaxed">
                                                {reason.description}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CircularMenu;