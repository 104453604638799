import React from 'react';

const DubaiGoldenVisa = () => {
  return (
    <div id = 'GoldenVisa' className="flex flex-col md:flex-row  max-w-6xl mx-auto mt-20  gap-24 ">
      {/* Card Stack */}
      <div className="w-full md:w-1/2 h-full flex justify-center">
        <img
          src='https://rightone.ae/Assets/goldenVisa/GoldenVisa.jpg'
          alt="Golden Visa Card"
          className="w-[80%] h-auto rounded-2xl"
        />
      </div>

      {/* Text Content */}
      <div className=" md:w-1/2 flex flex-col  justify-center" data-aos="fade-up">
        <h1 className="text-2xl md:text-3xl text-center font-bold mb-4 ">
          What Is The <span className="text-yellow-600">Golden Visa</span>?
        </h1>

        <p className=" mb-4 text-justify mx-6">
          The Dubai Golden Visa was introduced to encourage and retain "talented individuals and brilliant minds" in the United Arab Emirates. This initiative aims to create opportunities and propel the UAE economy to greater success.
        </p>

        <p className="text-justify mx-6">
          Investors, entrepreneurs, skilled professionals, and promising students are eligible for the Golden Visa in Dubai, subject to approval by government agencies relevant to their fields.
        </p>
      </div>
    </div>
  );
};

export default DubaiGoldenVisa;