import React, { useState, useEffect, useRef, useCallback  } from 'react';
import { MessageCircle, ChevronLeft, ChevronRight } from 'lucide-react';

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [dragDelta, setDragDelta] = useState(0);
  const sliderRef = useRef(null);
  
  const testimonials = [
    
      {
        "id": 1,
        "name": "Ali Khan",
        "position": "Business Hub LLC - Founder",
        "content": "Right One Consultancy has been an invaluable partner in navigating the complexities of business setup in Dubai. Their expertise in UAE company formation, legal compliance, and licensing made the entire process smooth and stress-free. I highly recommend their services for anyone looking to establish a business in the UAE."
      },
      {
        "id": 2,
        "name": "Fatima Al Zahrani",
        "position": "Innovate Solutions - CEO",
        "content": "From day one, Right One Consultancy’s professionalism and deep understanding of UAE business regulations allowed us to launch our company without a hitch. Their proactive team provided us with everything from visa services to trade licenses. I’m grateful for their support and look forward to working with them again."
      },
      {
        "id": 3,
        "name": "John Smith",
        "position": "GreenTech Enterprises - Managing Director",
        "content": "We turned to Right One Consultancy for our company setup in Dubai, and their service was exceptional. They efficiently handled all legal aspects, allowing us to focus on growing our business. Their attention to detail and expertise in the UAE market is unmatched. Highly recommended for any business looking to set up in the UAE."
      },
      {
        "id": 4,
        "name": "Sophie Taylor",
        "position": "Global Trading Ltd - Operations Head",
        "content": "Right One Consultancy provided invaluable support throughout our business formation process in Dubai. Their knowledge of UAE market regulations and legal requirements ensured we were well-prepared for success. We are extremely pleased with their services and would highly recommend them to anyone looking to start a business in the UAE."
      }
    ];
    
  const totalSlides = Math.ceil(testimonials.length / 2);

  const nextSlide = useCallback(() => {
    setCurrentIndex((prev) => (prev + 2) % testimonials.length);
  }, [testimonials.length]);

  const prevSlide = useCallback(() => {
    setCurrentIndex((prev) => (prev - 2 + testimonials.length) % testimonials.length);
  }, [testimonials.length]);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX);
  };

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartX(e.touches[0].pageX);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const currentX = e.pageX;
    const delta = startX - currentX;
    setDragDelta(delta);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const currentX = e.touches[0].pageX;
    const delta = startX - currentX;
    setDragDelta(delta);
  };

  const handleDragEnd = () => {
    if (!isDragging) return;

    if (Math.abs(dragDelta) > 50) {
      if (dragDelta > 0) {
        nextSlide();
      } else {
        prevSlide();
      }
    }

    setIsDragging(false);
    setDragDelta(0);
  };

  useEffect(() => {
    const timer = setInterval(nextSlide, 5000);
    return () => clearInterval(timer);
  }, [nextSlide]); // Include `nextSlide` in the dependency array

  const getVisibleTestimonials = () => {
    const secondIndex = (currentIndex + 1) % testimonials.length;
    return [testimonials[currentIndex], testimonials[secondIndex]];
  };
  const TestimonialCard = ({ testimonial }) => (
    <div className="bg-white/10 backdrop-blur-sm rounded-xl p-8 h-72">
      <div className="flex flex-col h-full" data-aos="fade-up">
        <div className="flex items-start space-x-4 mb-4">
          <div className="flex-shrink-0">
            <div className="w-12 h-12 bg-white/20 rounded-full flex items-center justify-center">
              <MessageCircle className="w-6 h-6 text-white" />
            </div>
          </div>
          <div className="flex-1">
            <h3 className="text-white font-semibold text-xl mb-1">
              {testimonial.name}
            </h3>
            <p className="text-white font-semibold text-sm">
              {testimonial.position}
            </p>
          </div>
        </div>
        <div className="flex-1 overflow-y-auto">
          <p className="text-white/90 leading-relaxed text-justify">
            {testimonial.content}
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <div className="relative py-16 overflow-hidden container mx-auto">
      <div className="absolute inset-0 w-full h-full">
        <img
          src="https://rightone.ae/Assets/Testimonials/Testimonials.jpeg"
          alt="Background"
          className="w-full h-full object-cover opacity-100"
        />
      </div>
      <div className="absolute inset-0 bg-gradient-to-b from-[#3D498A]/90 to-[#3D498A]/90" />
      <div className="relative container mx-auto px-4">
        <div className="flex flex-col items-center mb-12">
          <h2 className="text-2xl md:text-3xl lg:text-6xl font-bold text-center text-white mb-4" style={{ fontSize: "clamp(25px,3vw,200px)" }}>
            Testimonials
          </h2>
          <div className="w-36 h-1 bg-white mx-auto mt-5"></div>
        </div>

        <div className="max-w-6xl mx-auto">
          <div className="flex justify-between mb-4 md:hidden">
            <button
              onClick={prevSlide}
              className="text-white p-2 hover:text-white/80 transition-colors bg-white/10 rounded-full"
              aria-label="Previous slide"
            >
              <ChevronLeft className="w-6 h-6" />
            </button>
            <button
              onClick={nextSlide}
              className="text-white p-2 hover:text-white/80 transition-colors bg-white/10 rounded-full"
              aria-label="Next slide"
            >
              <ChevronRight className="w-6 h-6" />
            </button>
          </div>
          <button
            onClick={prevSlide}
            className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-12 text-white p-2 hover:text-white/80 transition-colors z-10 hidden md:block"
          >
            <ChevronLeft className="w-8 h-8" />
          </button>
          <button
            onClick={nextSlide}
            className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-12 text-white p-2 hover:text-white/80 transition-colors z-10 hidden md:block"
          >
            <ChevronRight className="w-8 h-8" />
          </button>
          <div
            ref={sliderRef}
            className="grid grid-cols-1 md:grid-cols-2 gap-6 cursor-grab active:cursor-grabbing select-none"
            onMouseDown={handleMouseDown}
            onMouseUp={handleDragEnd}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleDragEnd}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleDragEnd}
          >
            {getVisibleTestimonials().map((testimonial, index) => (
              <div
                key={`${testimonial.id}-${index}`}
                className={`${index === 1 ? 'hidden md:block' : ''}`}
              >
                <TestimonialCard testimonial={testimonial} />
              </div>
            ))}
          </div>
          <div className="flex justify-center space-x-2 mt-8">
            {[...Array(totalSlides)].map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentIndex(index * 2)}
                className={`transition-all duration-300 rounded-full ${Math.floor(currentIndex / 2) === index
                  ? 'w-6 bg-white'
                  : 'w-3 bg-white/50 hover:bg-white/70'
                  } h-3`}
                aria-label={`Go to slide ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;