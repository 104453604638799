import React from 'react';
import { CreditCard, Wallet, Globe } from 'lucide-react';

const BenefitCard = ({ icon, title, description }) => {
  return (
    <div 
      className={`
        relative overflow-hidden
        bg-white/95 backdrop-blur-sm rounded-xl
        p-8 shadow-[0_0_20px_rgba(0,0,0,0.1)]
        transform transition-all duration-300
        hover:-translate-y-2 hover:shadow-[0_10px_40px_rgba(59,130,246,0.2)]
        border border-blue-100
        h-[280px]
      `}
    >
      {/* Decorative gradient orb */}
      <div className="absolute -top-10 -right-10 w-40 h-40 bg-blue-500/10 rounded-full blur-2xl" />
      
      <div className="flex flex-col items-center h-full relative z-10">
        <div className="mb-6 p-3 bg-blue-50 rounded-xl transform transition-transform duration-300 hover:scale-110">
          {icon}
        </div>
        <h2 className="text-2xl font-bold text-blue-900 mb-4">
          {title}
        </h2>
        <p className="text-gray-600 text-center leading-relaxed">
          {description}
        </p>
      </div>
    </div>
  );
};

const OffshoreBenefits = () => {
  const benefits = [
    {
      icon: <Wallet size={48} className="text-blue-900" />,
      title: "Benefits",
      description: "Full foreign ownership, zero corporate tax, and high confidentiality."
    },
    {
      icon: <CreditCard size={48} className="text-blue-900" />,
      title: "Bank Account",
      description: "Hassle-free setup of multi-currency accounts with UAE and international banks."
    },
    {
      icon: <Globe size={48} className="text-blue-900" />,
      title: "Visas",
      description: "While offshore companies generally do not allow visa issuance, specific structures may provide flexibility."
    }
  ];

  return (
    <div className="bg-[#3D498A] p-8 -h-[40vh] container mx-auto rounded-2xl">
      <div className="max-w-6xl mx-auto">
        {/* Title Box with Animation */}
        <div className="bg-white/95 backdrop-blur-sm rounded-xl p-8 mb-12 shadow-lg transform hover:shadow-xl transition-all duration-300 border border-blue-100">
          <h1 className="text-3xl font-bold text-center bg-[#3D498A] bg-clip-text text-transparent">
            KEY BENEFITS OF SETTING UP A COMPANY IN OFFSHORE
          </h1>
          <div className="w-24 h-1 bg-[#3D498A] mx-auto mt-4 rounded-full" />
        </div>

        {/* Benefits Grid with Animation */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {benefits.map((benefit, index) => (
            <div 
              key={index}
              className="opacity-0 animate-fade-in"
              style={{ animationDelay: `${index * 200}ms` }}
            >
              <BenefitCard {...benefit} />
            </div>
          ))}
        </div>
      </div>

      <style jsx>{`
        @keyframes fade-in {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
        .animate-fade-in {
          animation: fade-in 0.6s ease-out forwards;
        }
      `}</style>
    </div>
  );
};

export default OffshoreBenefits;