import React from "react";
// import img1 from "../../../../public/Assets/CompanyFormation/Freezone.jpeg"

import { ChevronRight } from "lucide-react";

const Hero = () => {
  const img1 = `${process.env.REACT_APP_DOMAIN}/Assets/CompanyFormation/Freezone.jpeg`;

  const freezone = () => {
    setTimeout(() => {
      const freezoneSection = document.getElementById('freezone')
      if (freezoneSection) {
        window.scrollTo({
          top: freezoneSection.offsetTop - 120,
          behavior: 'smooth'
        })
      }
    })
  }

  return (
    <section className="relative h-[80vh] w-full overflow-hidden">
      {/* Background Image Container */}
      <div
        className="absolute inset-0 w-full h-[80vh]  bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${img1})`,
        }}
      >
        {/* Sophisticated overlay with multiple gradients */}
        <div className="absolute inset-0 bg-gradient-to-r from-black/80 via-black/50 to-transparent" />
        <div className="absolute inset-0 bg-gradient-to-t from-black/30 via-black/10 to-transparent" />
      </div>

      {/* Content Container */}
      <div className="relative h-[80vh] flex items-center justify-center text-center">
        <div className="container mx-auto px-4 md:px-8">
          <div className="max-w-4xl mx-auto">
            {/* Decorative element */}
            <div className="w-24 h-1 bg-[#3D498A] mb-8 transform -skew-x-12 mx-auto" />

            {/* Main heading with gradient text */}
            <h1 className="text-3xl md:text-7xl font-bold mb-6 text-white">
              Freezone Company Formation
            </h1>

            {/* Subtitle */}
            <div className="inline-block bg-[#3D498A]/10 backdrop-blur-sm px-4 py-2 rounded-lg mb-8">
              <span className="text-white font-semibold text-lg md:text-2xl">
                Right One Corporate Services - UAE Freezone Solutions
              </span>
            </div>

            {/* Description with improved typography */}
            <p className=" md:text-lg text-white leading-relaxed mb-6 max-w-2xl mx-auto">
              Setting up a business in a UAE freezone offers benefits like 100%
              foreign ownership, tax exemptions, and streamlined processes.
              Right One Corporate Services provides comprehensive guidance for a
              hassle-free setup tailored to your needs.
            </p>

            {/* CTA Section */}
            <div className="flex flex-col sm:flex-row gap-1 justify-center">
              <button onClick={freezone} className="group flex mb-4 z-10 items-center justify-center gap-2 px-8 py-4 bg-[#3D498A] text-white font-semibold rounded-lg hover:bg-[#3d498a8f] transition-all duration-300 transform hover:-translate-y-1">
                Learn More
                <ChevronRight className="w-5 h-5  group-hover:translate-x-1 transition-transform" />
              </button>

              <a href="/contact">
                <button className="flex items-center z-10 mb-4 justify-center gap-2 px-8 py-4 bg-[#3d498a3d] border-solid border-2 border-[#3d498a] text-white font-semibold rounded-lg hover:bg-[#3d498a]  transition-all duration-300 transform hover:-translate-y-1">
                  Contact Us
                  <ChevronRight className="w-5 h-5  group-hover:translate-x-1 transition-transform" />
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Decorative elements */}
      <div className="absolute bottom-0 left-0 w-full h-32 bg-gradient-to-t from-black/80 to-transparent" />
      <div className="absolute top-0 right-0 w-96 h-96 bg-[#3D498A]/10 rounded-full filter blur-3xl" />
    </section>
  );
};

export default Hero;
