import React from "react";
import {
  ArrowRight,
  ClipboardCheck,
  ShoppingBag,
  Building,
} from "lucide-react";

const IntroductionCards = () => {
  const img1 = `${process.env.REACT_APP_DOMAIN}/Assets/MainCorporateServices/trademark.jpeg`;
  const img2 = `${process.env.REACT_APP_DOMAIN}/Assets/MainCorporateServices/golden_visa.jpeg`;
  const img3 = `${process.env.REACT_APP_DOMAIN}/Assets/MainCorporateServices/translation.jpeg`;

  const cards = [
    {
      title: "Trademark Registration",
      description: [
        "Ensures legal protection for your brand identity.",
        "Grants exclusive rights to use your trademark.",
        "Helps in building trust and credibility.",
        "Simplifies enforcement against unauthorized usage.",
        "Valuable for business expansion and franchising."
      ],
      link: "https://wa.me/+971526919516",
      image: img1,
      icon: <ClipboardCheck className="w-10 h-10 text-white" />,
    },
    {
      title: "Golden Visa Services",
      description: [
        "Eligibility assessment.",
        "Application preparation and submission.",
        "Coordination for medical tests and Emirates ID processing.",
        "Assistance with visa renewal and status updates."
      ],
      link: "https://wa.me/+971526919516",
      image: img2,
      icon: <ShoppingBag className="w-10 h-10 text-white" />,
    },
    {
      title: "Translation Services",
      description: [
        "Legal and corporate document translation.",
        "Attested translations for official use.",
        "Translation of marketing materials and contracts."
      ],
      link: "https://wa.me/+971526919516",
      image: img3,
      icon: <Building className="w-10 h-10 text-white" />,
    },
  ];

  return (
    <div id = "otherServices" className="container mx-auto px-4 mt-9 ">
      <div className="pb-8 md:py-12 px-4">
        <div className="max-w-5xl mx-auto">
          <div className="relative">
            <div className="absolute inset-0 blur-lg bg-gradient-to-r from-blue-100/50 to-purple-100/50"></div>
            <h1 className="relative text-2xl md:text-4xl lg:text-5xl text-center text-[#3D498A] font-bold">
              Top Rated Other Services
            </h1>
            <p className="mb-2 mt-3 mx-auto lg:text-center text-center lg:text-lg">
              From product registration to government approvals and digital marketing, we offer tailored solutions to meet your business needs. Our expertise ensures smooth processes, regulatory compliance, and unwavering support for your growth and success.
            </p>
          </div>
          <div className="w-20 h-1 bg-[#3D498A] mx-auto mt-4 rounded-full"></div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {cards.map((card, index) => (
          <div
            key={index}
            className="group bg-white rounded-2xl shadow-lg hover:shadow-2xl transition-all duration-500 overflow-hidden flex flex-col"
          >
            <div className="relative h-64">
              <img
                src={card.image}
                alt={card.title}
                className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
              />
              <div className="absolute inset-0 group-hover:from-black/70 group-hover:to-black/40 transition-all duration-500" />
              <div className="absolute top-4 left-4 bg-white/10 backdrop-blur-md rounded-lg p-3 group-hover:scale-110 transition-transform duration-500">
                {card.icon}
              </div>
            </div>

            <div className="p-6 flex-grow flex flex-col">
              <h3 className="text-xl font-bold mb-4 text-gray-800 group-hover:text-customBlue transition-colors duration-300">
                {card.title}
              </h3>

              <ul className="space-y-2 mb-6 flex-grow">
                {card.description.map((point, idx) => (
                  <li key={idx} className="flex items-start text-gray-600">
                    <span className="mr-2 mt-2 w-1.5 h-1.5 rounded-full bg-customBlue flex-shrink-0" />
                    <span className="text-sm">{point}</span>
                  </li>
                ))}
              </ul>

              <a
                href={card.link}
                target="_blank"
                rel="noopener noreferrer"
                className="block w-full bg-customBlue hover:bg-indigo-700 text-white py-3 px-6 rounded-xl font-semibold text-center transition-colors duration-300 mt-auto"
              >
                <span className="flex items-center justify-center">
                  Consult Now
                  <ArrowRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" />
                </span>
              </a>
            </div>
          </div>

        ))}
      </div>
    </div>
  );
};

export default IntroductionCards;