const Navbar = {
    links: [
        
        {
            name: "About Us",
            path: "/about",
            submenu: [
                {
                    name: "Company Profile",
                    path: "/companyProfile"
                },
                {
                    name: "Testimonials",
                    path: "/testimonials"
                }

            ]
        },
        {
            name: "Company Formation",
            path: "/mainland",
            submenu: [
                {
                    name: "Mainland Company Formation",
                    path: "/mainland"
                },
                {
                    name: "Freezone Company Formation",
                    path: "/freezone"
                },
                {
                    name: "Offshore Company Formation",
                    path: "/offshore"
                }

            ]
        },
        {
            name: "Golden Visa",
            path: "/goldenVisa"
        },
        {
            name: "Corporate Services",
            path: "/company-formation",
            submenu: [
                {
                    name: "Company Formation ",
                    path: "/company-formation"
                },
                {
                    name: "PRO Services ",
                    path: "/pro-services"
                },
                {
                    name: "Accounting & Bookkeeping ",
                    path: "/accounting-bookkeeping"
                },
                {
                    name: "Corporate Bank Account Opening ",
                    path: "/corporate-bank-account-opening"
                },
                {
                    name:"Other Services",
                    path:"/other-services"
                }

            ]
        },

        {
            name: "Blogs",
            path: "/blogs"
        },

        {
            name: "Contact",
            path: "/contact"
        }
    ]
};

export default Navbar;
