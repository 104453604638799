import React from 'react'
import Benefits from './Benefits'
import BestBusiness from './BestBusiness'
import Form from "../../../Components/Form/Form23then135";
import Hero from './Hero'
import Introduction from './Introduction'
import Tax from './Tax'
import TopAuthorities from './TopAuthorities'
function Offshore() {
  return (
    <>
    <Hero/>
    <Introduction/>
    <Benefits/>
    <Tax/>
    <TopAuthorities/>
    <BestBusiness/>
    <Form/>
    </>
  )
}

export default Offshore