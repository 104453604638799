import React from 'react'

export default function Separator() {
    return (
        <div className='container mx-auto'>
            <div className='bg-gray-50 rounded-xl p-6 transition-all duration-400 hover:shadow-xl'>
            </div>
        </div>
    )
}
