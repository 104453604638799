import "./Navbar.css";
import React from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../Data/Navbar";
import { FaFacebookF, FaInstagram, FaLinkedin, FaTiktok } from 'react-icons/fa';
import { FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Desktop = () => {
  const navigate = useNavigate();

  const handleGetStartedClick = () => {
    window.scrollTo(0, 0);
    navigate('/contact');
  };

  const handleNavClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="hidden lg:flex justify-between border-b-2 border-[#3D498A]">
        <div className="flex-1 py-2 ">
          <ul className="flex space-x-2 font-bold ml-8">
            <li>
              <a
                href="https://www.facebook.com/rightoneconsultancy"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#3D498A] hover:opacity-80 transition-opacity"
              >
                <FaFacebookF className="text-xl" />
              </a>
            </li>
            <li>
              <a
                href="https://www.tiktok.com/@rightoneconsultancy"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#3D498A] hover:opacity-80 transition-opacity"
              >
                <FaTiktok className="text-xl" />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/right-one-corporate-services/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#3D498A] hover:opacity-80 transition-opacity"
              >
                <FaLinkedin className="text-xl" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/rightoneconsultancy/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#3D498A] hover:opacity-80 transition-opacity"
              >
                <FaInstagram className="text-xl" />
              </a>
            </li>
          </ul>
        </div>
        <div className="flex-1 flex justify-center items-center py-1 ml-[-230px]">
          <div className="flex items-center gap-3 text-sm font-bold">
            <span className="mt-0.5">Get Free Visa for Life</span>
            <button
              onClick={handleGetStartedClick}
              className="bg-[#3D498A] text-white px-4 py-1 rounded-lg text-xs
                       hover:bg-white hover:text-black hover:border-2 
                       hover:border-[#3D498A] transition-colors"
            >
              Get Started
            </button>
          </div>
        </div>
        <div className="flex-1 flex justify-end py-2 mr-8">
          <ul className="flex space-x-4 text-sm font-bold">
            <li>
              <a
                href="tel:+97142224797"
                className="flex items-center hover:text-[#3D498A] transition-colors"
              >
                <FaPhone className="text-[#3D498A] mr-2" />
                <span>Landline: +97142224797</span>
              </a>
            </li>
            <li>
              <a
                href="https://wa.me/+971509833635"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center hover:text-[#3D498A] transition-colors"
              >
                <FaPhone className="text-[#3D498A] mr-2" />
                Mobile: +971509833635
              </a>
            </li>
            <li className="flex items-center">
              <FaMapMarkerAlt className="text-[#3D498A] mr-2" />
              Dubai, UAE
            </li>

          </ul>
        </div>
      </div>
      <div className="hidden lg:block sticky top-0  bg-white z-50">
        <div className="flex items-center justify-between border-b px-8">
          {/* Logo */}
          <div>
            <NavLink to="/" onClick={handleNavClick}>
              <img
                src='https://rightone.ae/Assets/Logo/whiteLogo.png'
                alt="Company Logo"
                className="h-20 w-auto"
                loading="lazy"
              />
            </NavLink>
          </div>
          <div className="flex items-center">
            <nav className="flex items-center capppitalize">
              {Navbar.links.map((link, index) => (
                !link.submenu ? (
                  <NavLink
                    key={index}
                    to={link.path}
                    onClick={handleNavClick}
                    className={({ isActive }) =>
                      `px-4 py-2 rounded-md hover:bg-gray-100 transition-colors text-base
                      ${isActive ? 'font-bold text-[#3D498A]' : 'text-gray-600'} 
                      [font-size:clamp(0.5rem,0.4rem_+_0.5vw,1rem)]`
                    }
                  >
                    {link.name}
                  </NavLink>
                ) : (
                  <div key={index} className="relative group">
                    <NavLink
                      to={link.path}
                      onClick={handleNavClick}
                      className={({ isActive }) =>
                        `px-4 py-2 rounded-md hover:bg-gray-100 transition-colors flex items-center
                        ${isActive ? 'font-bold text-[#3D498A]' : 'text-gray-600'}
                        [font-size:clamp(0.5rem,0.4rem_+_0.5vw,1rem)]`
                      }
                    >
                      {link.name}
                      <img className="ml-1" src={`${process.env.PUBLIC_URL}/Assets/icons/dropdown.svg`} alt="dropdown" loading="
                      lazy"/>
                    </NavLink>
                    <div className="absolute invisible group-hover:visible  mt-0 w-64 bg-white shadow-lg rounded-md">
                      {link.submenu.map((sublink, subIndex) => (
                        <NavLink
                          key={subIndex}
                          to={sublink.path}
                          onClick={handleNavClick}
                          className={({ isActive }) =>
                            `block px-4 py-2 hover:bg-gray-100 capitalize transition-colors
                            ${isActive ? 'font-bold text-[#3D498A]' : 'text-gray-600'}`
                          }
                        >
                          {sublink.name}
                        </NavLink>
                      ))}
                    </div>
                  </div>
                )
              ))}
            </nav>
            <img
              src='https://rightone.ae/Assets/Logo/certificateLogo.png'
              alt="experience"
              className="h-28 w-auto "
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Desktop;
