import React, { useState } from 'react';
import { Calculator, Building2, MapPin, Users, Briefcase, User, UserRound, Mail } from 'lucide-react';

const CostCalculator = () => {
    const [formData, setFormData] = useState({
        business: '',
        jurisdiction: '',
        premises: '',
        shareholders: '',
        name: '',
        email: '',
        phone: ''
    });

    const [status, setStatus] = useState({
        submitting: false,
        msg: '',
        type: ''
    });

    const formFields = [
        {
            id: 'business',
            label: 'Choose Business Activity',
            placeholder: '-Choose Business Activity-',
            icon: <Briefcase className="w-5 h-5 text-[#3D498A]" />,
            options: ['Trading', 'Services', 'Manufacturing', 'E-commerce', 'Consulting']
        },
        {
            id: 'jurisdiction',
            label: 'Jurisdiction',
            placeholder: '-Select Jurisdiction-',
            icon: <MapPin className="w-5 h-5 text-[#3D498A]" />,
            options: ['Dubai Mainland', 'Dubai Free Zone', 'Abu Dhabi', 'Sharjah', 'RAK']
        },
        {
            id: 'premises',
            label: 'Premises',
            placeholder: '-Select the Premises-',
            icon: <Building2 className="w-5 h-5 text-[#3D498A]" />,
            options: ['Virtual Office', 'Physical Office', 'Warehouse', 'Retail Space']
        },
        {
            id: 'shareholders',
            label: 'Number of Shareholders',
            placeholder: '-Please Select-',
            icon: <Users className="w-5 h-5 text-[#3D498A]" />,
            options: ['1', '2-3', '4-5', '6+']
        }
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Reset previous status
        setStatus({
            submitting: true,
            msg: 'Sending...',
            type: ''
        });

        try {
            const formDataToSend = new FormData();
            Object.keys(formData).forEach(key => {
                // Only append non-empty values
                if (formData[key]) {
                    formDataToSend.append(key, formData[key]);
                }
            });

            const response = await fetch("/send_email_costcalculator.php", {
                method: 'POST',
                body: formDataToSend
            });

            if (!response.ok) {
                throw new Error("Submission failed");
            }

            const result = await response.json();

            // Update status based on server response
            setStatus({
                submitting: false,
                msg: result.message || "Request sent successfully!",
                type: result.status
            });

            // Clear form if submission was successful
            if (result.status === 'success') {
                setFormData({
                    business: '',
                    jurisdiction: '',
                    premises: '',
                    shareholders: '',
                    name: '',
                    email: '',
                    phone: ''
                });
            }
        } catch (error) {
            setStatus({
                submitting: false,
                msg: "Failed to send request. Please try again.",
                type: 'error'
            });
        }
    };

    return (
        <div className="bg-gradient-to-br from-blue-50 to-indigo-50 py-12 px-4 sm:px-6 lg:px-8 container mx-auto">
            <div className="max-w-4xl mx-auto">
                <div className="text-center mb-12">
                    <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-[#3D498A] mb-6">
                        <Calculator className="w-8 h-8 text-white" />
                    </div>
                    <h2 className="text-4xl font-bold text-gray-900 mb-4">
                        Calculate Your UAE Business Setup Cost Instant Estimate
                    </h2>
                    <p className="text-lg text-gray-600 max-w-2xl mx-auto">
                        Get an accurate and instant estimate for your business setup in the UAE, including company formation, licensing, visa services, and more.
                    </p>
                </div>

                {status.msg && (
                    <div className={`mb-6 p-4 rounded-lg text-center ${status.type === 'success'
                            ? 'bg-green-100 text-green-800'
                            : 'bg-red-100 text-red-800'
                        }`}>
                        {status.msg}
                    </div>
                )}

                <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
                    <div className="w-full h-2 bg-gray-100">
                        <div className="w-1/4 h-full bg-[#3D498A] rounded-r-full"></div>
                    </div>
                    <div className="p-8">
                        <form onSubmit={handleSubmit} className="space-y-3">
                            {formFields.map((field) => (
                                <div key={field.id} className="bg-gray-50 rounded-xl p-6 transition-all duration-400 hover:shadow-xl">
                                    <label className="text-lg font-medium text-gray-900 mb-4 flex items-center gap-2">
                                        {field.icon}
                                        {field.label}
                                    </label>
                                    <select
                                        name={field.id}
                                        value={formData[field.id]}
                                        onChange={handleChange}
                                        className="w-full px-4 py-3 border-2 border-gray-200 rounded-lg focus:ring-2 focus:ring-[#3D498A] focus:border-transparent transition-all duration-200 text-gray-600 text-lg appearance-none bg-white"
                                        required
                                    >
                                        <option value="">{field.placeholder}</option>
                                        {field.options.map((option) => (
                                            <option key={option} value={option.toLowerCase()}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            ))}

                            <div className='bg-gray-50 rounded-xl p-6 transition-all duration-400 hover:shadow-xl '>
                                <div className='flex flex-row text-lg'>
                                    <div><User className="w-5 h-5 mt-1 text-[#3D498A]" /> </div>
                                    <div className='ml-2'>Name</div>
                                </div>
                                <div className='w-full px-4 py-3 border-2 border-gray-200 rounded-lg focus:ring-2 focus:ring-[#3D498A] focus:border-transparent transition-all duration-200 text-gray-600 text-lg appearance-none bg-white'>
                                    <input
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        placeholder='Enter Your Good Name'
                                        className='focus:outline-none w-full'
                                        required
                                    />
                                </div>
                            </div>

                            <div className='bg-gray-50 rounded-xl p-6 transition-all duration-400 hover:shadow-xl '>
                                <div className='flex flex-row text-lg gap-2'>
                                    <div><Mail className="w-5 h-5 mt-1 text-[#3D498A]" /> </div>
                                    <div className=''>Email</div>
                                </div>
                                <div className='w-full px-4 py-3 border-2 border-gray-200 rounded-lg focus:ring-2 focus:ring-[#3D498A] focus:border-transparent transition-all duration-200 text-gray-600 text-lg appearance-none bg-white'>
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        placeholder='Enter Your Email'
                                        className='focus:outline-none w-full'
                                        required
                                    />
                                </div>
                            </div>

                            <div className='bg-gray-50 rounded-xl p-6 transition-all duration-400 hover:shadow-xl '>
                                <div className='flex flex-row text-lg'>
                                    <div><UserRound className="w-5 h-5 mt-1 text-[#3D498A]" /> </div>
                                    <div className='ml-2'>Contact</div>
                                </div>
                                <div className=' px-4 py-3 border-2 border-gray-200 rounded-lg focus:ring-2 focus:ring-[#3D498A] focus:border-transparent transition-all duration-200 text-gray-600 text-lg appearance-none bg-white'>
                                    <input
                                        type="tel"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        placeholder='Enter Your Phone number'
                                        className='focus:outline-none w-full'
                                        required
                                    />
                                </div>
                            </div>

                            <div className="flex items-center justify-end pt-6">
                                <button
                                    type="submit"
                                    disabled={status.submitting}
                                    className="inline-flex items-center px-8 py-4 border border-transparent text-lg font-medium rounded-xl text-white bg-[#3D498A] hover:bg-[#3D498A] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#3D498A] transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                                >
                                    {status.submitting ? 'Submitting...' : 'Continue'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CostCalculator;