import React, { useState } from 'react';
import {
  Award, Building, BookOpen, Users, Briefcase,
  FileText, Heart, Star, Shield,
  ChevronDown, ChevronUp, Activity
} from 'lucide-react';

const SectionTitle = ({ icon: Icon, title }) => (
  <div className="flex items-center mb-6 border-b border-gray-200 pb-4">
    <Icon className="w-8 h-8 text-customBlue mr-4" />
    <h2 className="text-3xl font-bold text-gray-800">{title}</h2>
  </div>
);

const CategoryCard = ({ icon: Icon, title, description, imageUrl }) => (
  <div className="bg-white rounded-lg shadow-lg overflow-hidden transition-transform duration-300 hover:scale-105">
    <div className="relative h-48 bg-blue-100">
      <img
        src={imageUrl || "/api/placeholder/400/320"}
        alt={title}
        className="w-full h-full object-cover"
      />
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent to-black/50" />
      <div className="absolute bottom-4 left-4 text-white">
        <Icon className="w-8 h-8 mb-2" />
        <h3 className="text-xl font-bold">{title}</h3>
      </div>
    </div>
    <div className="p-6">
      <p className="text-black">{description}</p>
    </div>
  </div>
);

const ExpandableSection = ({ title, children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="border-b border-gray-200 last:border-b-0">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full flex justify-between items-center py-4 px-6 hover:bg-gray-50"
      >
        <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
        {isExpanded ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
      </button>
      {isExpanded && (
        <div className="px-6 pb-6">
          {children}
        </div>
      )}
    </div>
  );
};

const Eligibility = () => {
  const categories = [
    {
      icon: Building,
      title: "Investors",
      description: "Public investments of AED 2M+ or real estate investments qualify for 10-year visa.",
      imageUrl: `${process.env.REACT_APP_DOMAIN}/Assets/goldenVisa/investors.jpeg`
    },
    {
      icon: Briefcase,
      title: "Entrepreneurs",
      description: "Technical projects valued at AED 500,000+ with approved business incubator support.",
      imageUrl: 'https://rightone.ae/Assets/goldenVisa/enterpreneur.jpeg'
    },
    {
      icon: Award,
      title: "Specialized Talents",
      description: "For doctors, scientists, artists, and other specialized professionals.",
      imageUrl: 'https://rightone.ae/Assets/goldenVisa/special.jpeg'
    },
    {
      icon: BookOpen,
      title: "Outstanding Students",
      description: "High achievers with 95%+ grades or 3.5+ GPA in accredited institutions.",
      imageUrl: 'https://rightone.ae/Assets/goldenVisa/outstanding.jpeg'
    },
    {
      icon: Heart,
      title: "Humanitarian Pioneers",
      description: "Recognition for significant contributions to humanitarian causes and organizations.",
      imageUrl: 'https://rightone.ae/Assets/goldenVisa/human.jpeg'
    },
    {
      icon: Shield,
      title: "Frontline Heroes",
      description: "Healthcare workers and essential service providers during crisis periods.",
      imageUrl: 'https://rightone.ae/Assets/goldenVisa/frontline.jpeg'
    }
  ];


  return (
    <div className="max-w-7xl mx-auto px-4 py-12 mb-[-80px]">
      {/* Hero Section */}
      <div className="relative h-72 rounded-xl overflow-hidden mb-16">
        <img
          src="/api/placeholder/1200/600"
          alt="Dubai Skyline"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-customBlue flex justify-center items-center">
          <div className="px-8  text-center" data-aos="fade-up">
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">
              UAE Golden Visa Program
            </h1>
            <p className="text-xl text-white/90">
              Your comprehensive guide to long-term residency in the UAE
            </p>
          </div>
        </div>

      </div>

      {/* Who Can Apply Section */}
      <div className="mb-16">
        <SectionTitle icon={Users} title="Who Can Apply for a Golden Visa?" />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {categories.map((category, index) => (
            <CategoryCard key={index} {...category} />
          ))}
        </div>
      </div>

      {/* Detailed Requirements Section */}
      <div className="bg-white rounded-xl shadow-lg  mb-16">
        <SectionTitle icon={Star} title="Golden Visa Requirements by Category" />
        <div className="space-y-4">
          <ExpandableSection title="Investors">
            <ul className="space-y-3 list-disc pl-6">
              <li>Minimum AED 2 million investment in UAE-accredited funds</li>
              <li>Full ownership of invested capital</li>
              <li>Property investment valued at AED 2 million minimum</li>
              <li>50% down payment option for mortgaged properties</li>
            </ul>
          </ExpandableSection>
          <ExpandableSection title="Entrepreneurs">
            <ul className="space-y-3 list-disc pl-6">
              <li>Project value of AED 500,000 minimum</li>
              <li>Approval from authorized business incubator</li>
              <li>Proof of technical or innovative nature of project</li>
            </ul>
          </ExpandableSection>
          <ExpandableSection title="Specialized Talents">
            <ul className="space-y-3 list-disc pl-6">
              <li>Doctors: Ministry of Health approval</li>
              <li>Scientists: Scientific Excellence Medal recommendation</li>
              <li>Executive Directors: Bachelor's degree, 5 years experience</li>
              <li>Creative individuals: Cultural sector approval</li>
            </ul>
          </ExpandableSection>
          <ExpandableSection title="Outstanding Students">
            <ul className="space-y-3 list-disc pl-6">
              <li>High School: 95% minimum grade</li>
              <li>University: 3.5 GPA for A-class universities</li>
              <li>Foreign University: Top 100 globally ranked</li>
            </ul>
          </ExpandableSection>
          <ExpandableSection title="Humanitarian Pioneers">
            <ul className="space-y-3 list-disc pl-6">
              <li>5+ years in international organizations</li>
              <li>Significant humanitarian contributions</li>
              <li>Recognition from approved organizations</li>
            </ul>
          </ExpandableSection>
          <ExpandableSection title="Frontline Heroes">
            <ul className="space-y-3 list-disc pl-6">
              <li>Healthcare workers during crisis periods</li>
              <li>Essential service providers</li>
              <li>Recommendation from Frontline Heroes Office</li>
            </ul>
          </ExpandableSection>
        </div>
      </div>

      {/* Documents Section */}
      <div className="bg-white rounded-xl shadow-lg p-8 mb-16" data-aos="fade-up">
        <SectionTitle icon={FileText} title="Required Documents" />
        <div className="grid md:grid-cols-2 gap-8">
          <div className="space-y-4">
            <h3 className="text-xl font-semibold text-gray-700">Basic Requirements</h3>
            <ul className="space-y-3 pl-6">
              <li className="flex items-start text-gray-600">
                <div className="text-customBlue mr-3">✔</div>
                When a title deed is issued for property in Dubai, the property value stated in the title deed must be at least 2 million AED (other emirates & DIFCA are not accepted).
              </li>
              <li className="flex items-start text-gray-600">
                <div className="text-customBlue mr-3">✔</div>
                A clear passport copy (Passport valid more than 6 months).
              </li>
              <li className="flex items-start text-gray-600">
                <div className="text-customBlue mr-3">✔</div>
                Old Emirates ID (If applicable).
              </li>
              <li className="flex items-start text-gray-600">
                <div className="text-customBlue mr-3">✔</div>
                A high-quality applicant digital photo that matches the ICP specifications (Note: Please find the personal photo guidelines to be followed set by Federal Authority for Identity Citizenship, Customs & Port Security here.)
              </li>
              <li className="flex items-start text-gray-600">
                <div className="text-customBlue mr-3">✔</div>
                Applicants from the following countries must bring their National ID (IRAN – PAKISTAN – IRAQ – LIBYA – AFGHANISTAN).
              </li>
              <li className="flex items-start text-gray-600">
                <div className="text-customBlue mr-3">✔</div>
                Health insurance (from any insurance company in UAE).
              </li>
            </ul>
          </div>
          <div>
            <img
              src='https://rightone.ae/Assets/goldenVisa/documents.jpeg'
              alt="Document Requirements"
              className="rounded-lg shadow-md w-full h-full object-cover"
            />
          </div>
        </div>

      </div>


      {/* Health Insurance Section */}
      <div className="bg-white rounded-xl shadow-lg p-8 mb-16" data-aos="fade-up">
        <SectionTitle icon={Activity} title="Health Insurance Requirements" />
        <div className="grid md:grid-cols-2 gap-8">
          <div className="space-y-4">
            <h3 className="text-xl font-semibold text-gray-700">Medical Requirements</h3>
            <ul className="space-y-3">
              <li className="flex items-center text-gray-600">
                <div className="w-2 h-2 bg-customBlue rounded-full mr-3"></div>
                Mandatory medical examination
              </li>
              <li className="flex items-center text-gray-600">
                <div className="w-2 h-2 bg-customBlue rounded-full mr-3"></div>
                Valid UAE health insurance coverage
              </li>
              <li className="flex items-center text-gray-600">
                <div className="w-2 h-2 bg-customBlue rounded-full mr-3"></div>
                Choice between DHA Basic or Comprehensive Insurance
              </li>
            </ul>
          </div>
          <div>
            <div className="bg-blue-50 rounded-lg p-6">
              <h4 className="text-lg font-semibold text-blue-800 mb-4">Insurance Options</h4>
              <ul className="space-y-3">
                <li className="flex items-center text-customBlue">
                  <div className="w-2 h-2 bg-customBlue rounded-full mr-3"></div>
                  DHA Basic Insurance
                </li>
                <li className="flex items-center text-customBlue">
                  <div className="w-2 h-2 bg-customBlue rounded-full mr-3"></div>
                  Comprehensive Insurance
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Section */}
    </div>
  );
};

export default Eligibility;