import React from "react";

const Introduction = () => {
  return (
    <div id = "mainland" className="flex flex-col max-w-6xl mx-auto mt-20 gap-24">
      {/* Text Content */}
      <div className="w-full flex flex-col justify-center items-center">
        <h2 className="text-2xl md:text-3xl text-center font-bold mb-4">
          What is<span className="text-[#3D498A]"> Mainland Dubai</span>?
        </h2>

        <p className="mb-2 lg:text-center text-justify lg:text-lg mx-6">
        Dubai Mainland, overseen by the Dubai Department of Economic Development (DED), allows businesses to trade freely across the UAE and internationally. A key difference from free zones is that mainland companies in Dubai can operate in all Emirates, offering greater market access compared to the often geographically restricted free zones. This makes mainland Dubai company formation a strategic choice for businesses targeting the entire UAE market.
        </p>
      </div>
    </div>
  );
};

export default Introduction;
