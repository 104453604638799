import React from 'react'
import Hero from './Hero'
import Services from './Services'
import Form from '../../../Components/Form/Form23then135'

function ProServices() {
  return (
    <div>
        <Hero />
        <Services />
        <Form />        
    </div>
  )
}

export default ProServices