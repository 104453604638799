import React from 'react';
import companyProfilePdf from '../../Assests/img/CompanyProfile2025.pdf';
import { FileText, Download, Eye } from 'lucide-react';

const CompanyProfile = () => {
  const handlePreview = () => {
    const link = document.createElement('a');
    link.href = companyProfilePdf;
    link.target = '_blank';
    link.click();
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = companyProfilePdf;
    link.download = 'CompanyProfile2025.pdf';
    link.click();
  };

  return (
    <div className="min-h-[45vh] bg-gray-50">

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 py-8">
        <div className="bg-white rounded-lg shadow p-6">
          {/* PDF Information Section */}
          <div className="flex items-start space-x-4 mb-8">
            <div className="p-3 bg-blue-100 rounded-lg">
              <FileText className="w-8 h-8 text-customBlue" />
            </div>
            <div className="flex-1">
              <h2 className="text-xl font-semibold text-gray-900 mb-2">
                Company Profile Document
              </h2>
              <p className="text-gray-600 mb-4">
                Access our comprehensive company profile document containing detailed information
                about our organization, mission, values, and achievements.
              </p>
              <div className="flex flex-wrap gap-4">
                <button
                  onClick={handlePreview}
                  className="inline-flex items-center px-4 py-2 bg-customBlue text-white rounded-md hover:bg-customBlue focus:outline-none focus:ring-2 focus:ring-customBlue focus:ring-offset-2 transition-colors"
                >
                  <Eye className="w-5 h-5 mr-2" />
                  Preview PDF
                </button>
                <button
                  onClick={handleDownload}
                  className="inline-flex items-center px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition-colors"
                >
                  <Download className="w-5 h-5 mr-2" />
                  Download PDF
                </button>
              </div>
            </div>
          </div>

          {/* Additional Information Cards */}
          <div className="grid md:grid-cols-2 gap-6 mt-8">
            <div className="bg-gray-50 p-6 rounded-lg border border-gray-200">
              <h3 className="text-lg font-semibold text-gray-900 mb-2">
                Document Details
              </h3>
              <ul className="space-y-2 text-gray-600">
                <li>• Format: PDF Document</li>
                <li>• Pages: 10</li>
                <li>• Access: Immediate</li>
              </ul>
            </div>
            <div className="bg-gray-50 p-6 rounded-lg border border-gray-200">
              <h3 className="text-lg font-semibold text-gray-900 mb-2">
                How to Use
              </h3>
              <ul className="space-y-2 text-gray-600">
                <li>• Click "Preview" to view in browser</li>
                <li>• Use "Download" to save locally</li>
                <li>• Print if needed</li>
              </ul>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CompanyProfile;