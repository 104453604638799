import React from 'react'
import Hero from './Hero'
import Services from './Services'
import ExclusivePackages from './ExclusivePackages'
import Testimonials from './Testimonials'
import ValueableClients from './ValueableClients'
import RequestCallback from './RequestCallback'
import BusinessLicense from './BusinessLicense'
import AnimatedCircle from './Animation'
import Separator from './Separator'
import HomeCostCalculator from './HomeCostCalculator'
import FlipCards from './FlipCards';

function Home() {
  return (
    <>
      <Hero />
      <FlipCards />
      <Services />
      <ExclusivePackages />
      <BusinessLicense />
      <HomeCostCalculator />
      <Testimonials />
      <ValueableClients />
      <AnimatedCircle />
      <Separator />
      <RequestCallback />      
    </>
  )
}

export default Home