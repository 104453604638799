import React from "react";
import Hero from "./Hero";
import Introduction from "./Introduction";
import IntroductionCards from "./IntroductionCards";
import Benefits from "./Benefits";
import TopBusiness from "./TopBusiness";
import Form from '../../../Components/Form/Form23then135'

function Mainland() {
  return (
    <>
      <Hero />
      <Introduction />
      <IntroductionCards />
      <Benefits />
      <TopBusiness />
      <Form />
    </>
  );
}

export default Mainland;
