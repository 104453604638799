import React from 'react';

const PackageCard = ({ title, price, image, subtitle }) => (
  <div className="relative bg-white rounded-lg overflow-hidden shadow-xl mt-8" data-aos="fade-up">
    <div className="absolute top-0 left-0 bg-[#3D498A] px-4 py-2 z-10">
      <h3 className="text-white font-bold text-sm md:text-base">
        {title}
      </h3>
    </div>
    <div className="relative h-48">
      <img
        src={image}
        alt={title}
        className="w-full h-full object-cover"
        loading="lazy"
      />
      <div className="absolute left-1/2 bottom-0 transform -translate-x-1/2 translate-y-1/2">
        <div className="relative">
          <div className="absolute inset-0 rounded-full bg-[#5B68B4] blur-lg opacity-50"></div>
          <div className="relative bg-[#3D498A] rounded-full w-24 h-24 flex flex-col items-center justify-center border-4 border-white">
            <span className="text-2xl font-bold text-white">{price}</span>
            <span className="text-sm text-white">AED</span>
          </div>
        </div>
      </div>
    </div>
    <div className="pt-16 pb-8 px-6 text-center">
      <h4 className="text-[#3D498A] font-bold text-xl mb-2">{subtitle}</h4>
      <p className="text-[#3D498A] font-bold mb-6">
        in AED {price.toLocaleString()}
      </p>
      <a
        href="https://wa.me/+971526919516"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-[#3D498A] text-white px-8 py-2 rounded-full hover:bg-[#5B68B4] transition-colors duration-300"
      >
        <span>Enquire Now</span>
      </a>
    </div>
  </div>
);

const PackagesGrid = () => {
  const packages = [
    {
      title: "UAE Free Zone License 0 visa",
      subtitle: "UAE Free Zone License",
      price: 5500,
      image: "https://rightone.ae/Assets/ExclusivePackages/ExclusivePackages1.jpeg"
    },
    {
      title: "UAE Free Zone License 1 visa",
      subtitle: "UAE Free Zone License",
      price: 7900,
      image: "https://rightone.ae/Assets/ExclusivePackages/ExclusivePackages2.jpeg"
    },
    {
      title: "Dubai Free Zone License 0 Visa",
      subtitle: "Dubai Free Zone License",
      price: 12900,
      image: "https://rightone.ae/Assets/ExclusivePackages/ExclusivePackages3.jpeg"
    },
    {
      title: "Dubai Mainland License 1 Visa Package",
      subtitle: "Dubai Mainland License",
      price: 14999,
      image: "https://rightone.ae/Assets/ExclusivePackages/ExclusivePackages4.jpeg"
    },
    {
      title: "Dubai Mainland 1 Investor Visa & 3 Employees Visa Quota",
      subtitle: "Dubai Mainland",
      price: 19999,
      image: "https://rightone.ae/Assets/ExclusivePackages/ExclusivePackages5.jpeg"
    },
    {
      title: "Dubai Mainland 2 Partner Visas & 3 Employees Visa Quotas",
      subtitle: "Dubai Mainland",
      price: 24999,
      image: "https://rightone.ae/Assets/ExclusivePackages/ExclusivePackages1.jpeg"
    }
  ];


  return (
    <>
      <div className='container mx-auto'>
        <div className=" px-6 py-12 bg-[#3D498A] overflow-hidden">
          <div className="text-center mb-12" data-aos="fade-up">
            <h2 className="text-2xl md:text-3xl lg:text-6xl font-bold text-center text-white mb-8 " style={{ fontSize: "clamp(20px,2.5vw,180px)" }}>
              Exclusive Packages
            </h2>
            <div className="w-[120px] md:w-72   h-1 bg-white mx-auto mb-10 "></div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {packages.map((pkg, index) => (
              <PackageCard key={index} {...pkg} />
            ))}
          </div>
        </div></div>
    </>

  );
};

export default PackagesGrid;