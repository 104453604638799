import React from 'react'
import Hero from './Hero'
import CardServices from './CardServices'
import ToggleServices from './ToggleServices'
import Form from '../../../Components/Form/Form23then135'


function Other_Services() {
  return (
    <div>
        <Hero />
        <CardServices />
        <ToggleServices />
        <Form />
    </div>
  )
}

export default Other_Services